<template>
  <div v-if="allocationPoints.length">
    <form @submit.prevent="saveChanges">
      <span>Editing allocation points for {{ dialogStore.selectedItem[0].year }}</span>
      <div v-for="(point, index) in dialogStore.selectedItem" :key="index" class="mt-4">
        <label :for="'value-' + index" class="block text-sm font-medium leading-6 text-gray-900">
          {{ planStore.getCurrentPlan.savingsAllocation[point.seriesIndex].name }}
        </label>
        <div v-if="!userProfile.isAllocationAmountSelected" class="relative w-32">
          <input  type="number" :id="'value-' + index" v-model.number="point.percent" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" /><div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 text-sm">%</div>
        </div>

        <div v-if="userProfile.isAllocationAmountSelected">
          <input  type="number" :id="'value-' + index" v-model.number="point.absolute" class="block w-32 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
        </div>

      </div>
    </form>
  </div>
  <div v-else>
    <p>No allocation points found for the selected data index.</p>
  </div>
</template>


<script setup>
import { computed } from 'vue';
import { usePlanStore } from '@/stores/PlanStore'; // Adjust the import based on your project structure
import { useDialogStore } from '@/stores/DialogStore';
import { useUserProfileStore } from '@/stores/UserProfile';

// Access the plan store
const planStore = usePlanStore();
const dialogStore = useDialogStore();
const userProfile = useUserProfileStore();

// Computed property to get the allocation points for the specified data index across all series
const allocationPoints = computed(() => {
  const plan = planStore.getCurrentPlan;
  if (!plan || !plan.savingsAllocation) {
    return [];
  }

  const points = [];

  // Iterate over all series in savingsAllocation
  plan.savingsAllocation.forEach((series, seriesIndex) => {
    // Find the allocation point at the specified dataIndex (year) for this series
    const point = series.allocation.find(
      (p) => p.year === plan.projection.labels[dialogStore.selectedItemId.dataIndex]      
    );
    
    if (point) {
      // Add the seriesIndex to the point for identification
      points.push({ ...point, seriesIndex });
    }
  });

  return points;
});

// Initialize dialogStore.selectedItem when the component mounts
dialogStore.selectedItem = [...allocationPoints.value]; // Ensure deep copy to avoid reference issues

dialogStore.selectedItem.forEach(point => {
  point.dataIndex = dialogStore.selectedItemId.dataIndex;
})

</script>
