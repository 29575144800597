export function validateField(value, field, store, planStore) {
  store.setDirtyField(field, true);

  const isNumberInRange = (value, min, max) => typeof value === 'number' && value >= min && value <= max;
  const isStringMatch = (value, matches) => typeof value === 'string' && matches.includes(value);
  const findMatchingTarget = (value) => planStore && planStore.getCurrentPlan.targets ? planStore.getCurrentPlan.targets.find(target => target.name === value) : null;

  switch (field) {
    case 'name':
      if (value === null || value === undefined || typeof value !== 'string' || value.trim().length === 0) {
        store.setErrorField(field, true);
        return false;
      }
      store.setErrorField(field, false);
      return true;

    case 'value':
      if (isNumberInRange(value, 0, Infinity)) {
        store.setErrorField(field, false);
        return true;
      }
      store.setErrorField(field, true);
      return false;

    case 'startYear':
      if (isNumberInRange(value, 1900, 2150) || isStringMatch(value, ['Plan start'])) {
        store.setErrorField(field, false);
        return true;
      }

      if (typeof value === 'string' && findMatchingTarget(value)) {
        store.setErrorField(field, false);
        return true;
      }

      store.setErrorField(field, true);
      return false;

    case 'endYear':      
      if (isNumberInRange(value, 1900, 2150) || isStringMatch(value, ['Plan end'])) {
        store.setErrorField(field, false);        
        return true;
      }

      if (typeof value === 'string' && findMatchingTarget(value)) {
        store.setErrorField(field, false);        
        return true;
      }
      
      store.setErrorField(field, true);
      return false;

    case 'year':
      if (isNumberInRange(value, 1900, 2150)) {
        store.setErrorField(field, false);
        return true;
      }
      store.setErrorField(field, true);
      return false;

      case 'duration':
      if (isNumberInRange(value, 1, 100)) {
        store.setErrorField(field, false);
        return true;
      }
      store.setErrorField(field, true);
      return false;

      case 'age':
        if (isNumberInRange(value, 1, 150)) {
          store.setErrorField(field, false);
          return true;
        }
        store.setErrorField(field, true);
        return false;

    case 'financial goal':
      if (isNumberInRange(value, 0, 999999999999999)) {
        store.setErrorField(field, false);
        return true;
      }
      store.setErrorField(field, true);
      return false;
  

    case 'growthRate':
      if (isNumberInRange(value, -20, 100) || isStringMatch(value, ['Match inflation', 'Investment growth rate'])) {
        store.setErrorField(field, false);
        return true;
      }
      store.setErrorField(field, true);
      return false;

    case 'contributionAmount':
      if (isNumberInRange(value, 0, 1000000)) {
        store.setErrorField(field, false);
        return true;
      }
      store.setErrorField(field, true);
      return false;

      case 'currency':
        if (isStringMatch(value, ['£', '$'])) {
          store.setErrorField(field, false);
          return true;
        }
        store.setErrorField(field, true);
        return false;

      case 'definedBenefitPensionAmount':
        if (isNumberInRange(value, 0, Infinity)) {
          store.setErrorField(field, false);
          return true;
        }
        store.setErrorField(field, true);
        return false;

    default:
      console.error(`Unknown field: ${field}`);
      return false;
  }
}
