<template>
    <div ref="cardOuter" class="card-outer bg-white max-h-full overflow-hidden flex-1 overflow-x-auto shadow ring-1 ring-black ring-opacity-5 rounded-lg min-w-60">
      <div @click="toggleTable" class="relative z-10 cursor-pointer" :class="cardDetails.headerColor">
        <div class="inline-flex justify-between w-full items-center py-2  pl-3 pr-3 text-left text-sm font-semibold text-gray-900 border-b border-gray-200">
                <div class="flex items-center" >
                    <component :is="cardDetails.component" :class="cardDetails.iconColor" class="h-6 w-6 mr-2"></component>
                    <span>{{ cardTitle }}</span>
                </div>
                <span v-if="cardDetails.totalValue !== undefined">{{ $formatNumber(cardDetails.totalValue) }}</span>
        </div>
      </div>
      <Transition name="fade">
        <div v-if="isTableVisible" class="relative z-0 w-full divide-y divide-gray-300 transition-all duration-300 ease-in-out bg-white">
          <!-- Flex container for each item -->
          <div v-for="item in cardDetails.group" :key="item.id" @click="dialogStore.openDialog('edit', cardDetails.type, item.id)" class="hover:bg-gray-50 cursor-pointer card-inner-element" >
              <div  class="flex items-center ">
                  <!-- LEFT Flex item for name and year range -->
                  <div class="flex-grow truncate py-2 pl-3 pr-1.5 text-sm font-medium text-gray-900">
                      {{ item.name }}
                      <div v-if="!compact" class="font-normal text-gray-500 text-xs truncate">
                          {{ item.startYear }} → {{ item.endYear }}
                      </div>
                  </div>
                  <!-- RIGHT Flex item container for value and additional details -->
                  <div class="flex-grow truncate flex flex-col justify-end py-2 pr-3 pl-1.5 text-sm text-gray-500">
                    <!-- Top row for the value -->
                    <div class="flex items-center flex-row text-gray-900 self-end">
                          <div class="flex-1 ">{{ cardDetails.currency || item.type === 'financial goal' ? $formatNumber(item.value) : item.value }}</div>
                            <!-- Flex item for modifier, if present -->
                        <div v-if="item.type === 'age'" class="text-gray-500 text-xs text-right align-bottom">
                            {{ item.modifier }}
                        </div>
                    </div>          

                    <!-- Bottom row for arrows icon and growth rate, conditionally displayed -->
                    <div v-if="!compact" class="flex w-full items-center justify-end text-gray-500 text-xs">
                      <div v-if="item.contribution && !item.studentLoan && item.contribution !=='None'" class="flex">                      
                        <PlusCircleIcon class="h-4 w-4 text-gray-400 mr-1" aria-hidden="true" />
                        {{ $formatNumber(item.contributionAmount)}}
                      </div>
                      <ArrowsUpDownIcon class="h-4 w-4 text-gray-400 ml-1" aria-hidden="true" />
                        <span class="truncate">{{ item.growthRate }}</span>
                        <span v-if="typeof item.growthRate === 'number'">%</span>
                    </div> 

                  </div>
              </div>
              <div v-if="item.definedBenefitPension?.active">
                <div class="text-xs px-3 text-gray-400 pb-2">Pension: {{ formatNumber(item.definedBenefitPension.actualAmount) }} from age {{ item.definedBenefitPension.age || taxStore.statePensionAge }}</div>
              </div>
              <div v-if="item.employerPension?.active">
                <div class="text-xs px-3 text-gray-400 pb-2">Pension contributions: Employer: {{ item.employerPension.amount }}% You: {{ item.employerPension.yourContribution }}% </div>
              </div>
            </div>  <!-- End for loop -->

          <!-- Adjusted section for Add button -->
          <div class="flex justify-between items-center py-1 px-2 text-sm font-medium text-gray-600">
              <div class="flex justify-end w-full">                        
                  <button @click="dialogStore.openDialog('add', cardDetails.type, null)" class="inline-flex items-center gap-x-1.5 rounded bg-indigo-50 px-2 py-1 text-xs font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100">
                    <PlusIcon class="h-4 w-4" aria-hidden="true" />
                      <span>Add</span>
                  </button>
              </div>
          </div>
      </div>
    </Transition>
    </div>
  </template>

<script setup>
import { PlusIcon, ArrowsUpDownIcon, PlusCircleIcon } from '@heroicons/vue/24/solid';
import { defineProps, ref, watch, nextTick } from 'vue';
import { useDialogStore } from '@/stores/DialogStore';
import { formatNumber } from '@/utils/formatNumber';
import { useTaxStore } from '@/stores/TaxStore'

const taxStore = useTaxStore();
const dialogStore = useDialogStore();
const props = defineProps({
    cardTitle: String,
    compact: {
        type: Boolean,
        default: false
    },
    cardDetails: {
        type: Object,
        default: () => ({
            component: null, // Assuming 'null' is acceptable as a default. Adjust if needed.
            headerColor: 'bg-gray-50',
            iconColor: 'text-gray-500',
            currency: true
        }),
    },
});

const isTableVisible = ref(true);
const cardOuter = ref(null); // Reference to the card outer wrapper

const toggleTable = () => {
    isTableVisible.value = !isTableVisible.value;
    nextTick(() => {
        adjustCardHeight();
    });
};

// Watch for changes in cardDetails.group array
watch(() => props.cardDetails.group, () => {
  // Ensure changes are rendered before adjusting the height
  nextTick(() => {
    adjustCardHeight();
  });
}, { deep: true }); // Use deep watch to detect changes within the array

const adjustCardHeight = () => {
  if (!cardOuter.value || !props.cardDetails.group) return;

  nextTick(() => {
    let fullHeight = 0;

    if (isTableVisible.value) {
      // Calculate the height based on the actual rendered height of the inner elements
      const cardInnerElements = cardOuter.value.querySelectorAll('.card-inner-element'); // Replace with the appropriate class or selector

      cardInnerElements.forEach(element => {
        fullHeight += element.offsetHeight; // Accumulate the height of each inner element        
      });

      // Add any additional padding, margin, or fixed height parts
      fullHeight += 41 + 33; // Additional height for non-compact mode

      // Transition to the calculated full height
      cardOuter.value.style.height = fullHeight + 'px';
    } else {
      // Set height to collapsed state when table is not visible
      cardOuter.value.style.height = '41px';
    }
  });
};



// Initial adjustment in case the group array is not empty at mount
nextTick(() => {
  adjustCardHeight();
});

</script>

<style scoped>
.card-outer {
    /* transition: max-height 0.5s linear; */
    transition: height 0.2s ease;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;  
  transform: translateY(-30%);
}

/* 3. ensure leaving items are taken out of layout flow so that moving
      animations can be calculated correctly. 
      .fade-leave-active {
  position: absolute;
}
      
      */

</style>