import { defineStore } from 'pinia';
import { nextTick } from 'vue';
import router from '../router/index.js';
import useFinancialProjections from '@/composables/useFinancialProjections';  
import { useUserProfileStore } from '@/stores/UserProfile';
import { v4 as uuidv4 } from 'uuid'; // Import UUID v4 generator function
// import { useSavingsStore } from '@/stores/SavingsStore.js';
import {useDialogStore } from '@/stores/DialogStore';
import { initializeAllocations, useAllocations } from '@/composables/useAllocations';
import { useSavingsStore } from '@/stores/SavingsStore.js';
import { useTaxStore } from '@/stores/TaxStore.js';

export const usePlanStore = defineStore('plans', {
    state: () => ({
      plans: [
        {
          id:1,
          name: 'My first plan',          
          country: 'UK',
          nationalInsurance: true,
          startYear: 2024,
          duration: 50,
          endYear: null,
          projection: null,
          endOfPlan: null, 
          savings: [],
          activeSavingsCount: 0,
          debts: [],
          expenses: [],
          income: [],
          physicalAssets: [],
          savingsAllocation: [],
          currency: '£',
          rates: [{
              name: 'Investment growth rate',
              value: 5,
              modifier: "%",
            },
            {
              name: 'Inflation',
              value: 3,
              modifier: "%",
            },          
          ],
          settings: [],
          targets: [{
            name: "Life expectancy",
            type: "age",
            value: 85,
            modifier: "years old",
            id: 0,
          },
            {
              name: "Retirement",
              type: "age",
              value: 67,
              modifier: "years old",
              id: 1,
          }
          ],
        },
      ],
      currentPlanId: null,
      selectedYear: null,
    }),
    getters: {
      getActiveSavingsCount: (state) => {
        const dialogStore = useDialogStore();
        const savingsStore = useSavingsStore();
  
        const plan = state.plans.find(plan => plan.id === state.currentPlanId);
        if (!plan) return 0;
  
        // Initialize copy with items from store.items
        let savingsCopy = savingsStore.items.map(item => ({ ...item }));
  
        // Add or overwrite items from currentPlan
        plan?.savings?.forEach(planSavingsItem => {
          const existingItemIndex = savingsCopy.findIndex(copyItem => copyItem.id === planSavingsItem.id);
          if (existingItemIndex !== -1) {
            savingsCopy[existingItemIndex] = { ...planSavingsItem };
          } else {
            savingsCopy.push({ ...planSavingsItem });
          }
        });
  
        // Remove items with the excluded flag
        savingsCopy = savingsCopy.filter(item => !item.exclude);
  
        // Filter to get active savings accounts
        const activeSavingsAccounts = savingsCopy.filter(item => item.includeInAllocation && !item.exclude);
  
        // Check if selectedItem should be included in the count
        const isItemInPlan = activeSavingsAccounts.some(item => item.id === dialogStore.selectedItemId);
  
        // Calculate the count
        let count = activeSavingsAccounts.length;
        if (dialogStore.selectedItem?.includeInAllocation && !isItemInPlan) {
          count++;
        }
  
        return count;
      },
      isLastActiveSavingsAccount: (state) => {
        return state.getActiveSavingsCount === 1;
      },
      getPlanById: (state) => (id) => state.plans.find(plan => plan.id === id),
      getCurrentPlan(state) {
        const plan = state.plans.find(plan => plan.id === state.currentPlanId);
        if (!plan) {
          console.warn(`Plan with ID ${state.currentPlanId} not found.`);
          // Handle the error, perhaps by resetting currentPlanId or choosing a default plan
        }
        return plan;
      },
      getCurrentPlanEndYear(state) { 
        const plan = state.plans.find(plan => plan.id === state.currentPlanId);
        return plan.startYear+plan.duration;
      },
      // Getter to retrieve details for a specific year
      getDetailsForSelectedYear(state) {
        const projectionData = state.getCurrentPlan?.projection;
      
        if (!projectionData || typeof projectionData.data !== 'object' || projectionData.data === null) {
          console.error('Invalid or missing projection data');
          return null;
        }
      
        const detailsForYear = {};
      
        // Find the index for the selected year without using String or indexOf
        let yearIndex = -1;
        for (let i = 0; i < projectionData.labels.length; i++) {
          if (projectionData.labels[i] === this.selectedYear || Number(projectionData.labels[i]) === this.selectedYear) {
            yearIndex = i;
            break;
          }
        }
      
        if (yearIndex === -1) {
          this.selectedYear = state.getCurrentPlan?.startYear;    
          for (let i = 0; i < projectionData.labels.length; i++) {
            if (projectionData.labels[i] === this.selectedYear || Number(projectionData.labels[i]) === this.selectedYear) {
              yearIndex = i;
              break;
            }
          }      
        }

        if (yearIndex === -1) {
          console.error(`Year ${this.selectedYear} not found in labels`);
          return null;
        }
      
        // Iterate over projection data keys to gather details for the selected year
        Object.keys(projectionData.data).forEach(key => {
          const dataSeries = projectionData.data[key];
      
          // Check if the data series is an array and has a value for the selected year
          if (Array.isArray(dataSeries) && dataSeries[yearIndex] !== undefined) {
            detailsForYear[key] = dataSeries[yearIndex];
          }
        });
      
        return { year: this.selectedYear, ...detailsForYear };
      },
        
      getGrowthRate: (state) => (id, item) => {
        const plan = state.plans.find(plan => plan.id === id);
        if (!plan) return null; // Handle if plan is not found
            
        const growthRate = item.growthRate;
        // console.log('type: ', type, 'item id: ', itemId, 'item: ', item);
      
        // Check if growth rate is a number or needs calculation
        if (typeof growthRate !== 'number' && growthRate === 'Match inflation') {
          // Calculate growth rate based on inflation
          const inflationRate = plan.rates.find(rate => rate.name === 'Inflation');
          return inflationRate ? inflationRate.value : null;
        } 
        if (typeof growthRate !== 'number' && growthRate === 'Investment growth rate') {
          // Calculate growth rate based on inflation
          const investmentRate = plan.rates.find(rate => rate.name === 'Investment growth rate');
          return investmentRate ? investmentRate.value : null;
        } 
        
        else {
          // Return the selected growth rate if it's a number
          return growthRate;
        }
      },
      getYear: (state) => (item, startEnd) => {
        const userStore = useUserProfileStore();
        const plan = state.plans.find(plan => plan.id === state.currentPlanId);
        
        if (!plan) {
          console.error("Plan not found.");
          return null;
        }
      
        let year;
      
        // 1. Direct Year Assignment
        if (startEnd === 'start') {
          year = item.startYear;
        } else if (startEnd === 'end') {
          year = item.endYear;
        }
      
        // 2. Validate if year is a proper number
        if (typeof year === 'number' && year > 1900 && year < 3000) {
          return year;
        }
      
        // 3. Handle 'Plan start' and 'Plan end' keywords
        if (year === 'Plan start') {
          return plan.startYear;
        } else if (year === 'Plan end') {
          return plan.startYear + plan.duration;
        }
      
        // 4. Handle cases where year is a string (possibly referencing a target)
        if (typeof year === 'string') {
          // Ensure the plan has targets defined
          if (!plan.targets || plan.targets.length === 0) {
            console.error("Targets not defined in the plan.");
            return null;
          }
      
          // Find the target by name
          const target = plan.targets.find(target => target.name === year);
      
          if (!target) {
            console.error(`Target with name "${year}" not found.`);
            return null;
          }
          let targetYear;
          if (target.type === 'financial goal') {
            // Convert the target value to a year using the user profile store's method
            targetYear = userStore.getYearAtValue(target.value);
          //  targetYear = userStore.getYearAtAge(age);
          }
          else if (target.type === "age") {
             targetYear = userStore.getYearAtAge(target.value);
          }

          else {
            targetYear = target.value;
          }
      
          return targetYear;
        }
      
        // 5. Handle scenarios where no valid year could be derived
        console.error("Year could not be determined from the provided item.");
        return null;
      },
      
      getMaxYear(state) {
        const currentPlan = state.plans.find(plan => plan.id === state.currentPlanId);
        return currentPlan ? currentPlan.startYear + currentPlan.duration + 1 : null;
      }  
    },
    actions: {      
      createPlan() {
          const taxStore = useTaxStore();
        
          // Create a new plan object
          const newPlan = {
            id: Date.now(),
            name: 'New Plan',
            country: 'UK',
            nationalInsurance: true,
            startYear: new Date().getFullYear(),
            endYear: null,
            duration: 50,
            projection: null,
            endOfPlan: null, 
            savings: [],          
            debts: [],
            expenses: [],
            income: [],
            physicalAssets: [],
            savingsAllocation: [],
            currency: '£',
            rates: [{
              name: 'Investment growth rate',
              value: 5,
              modifier: "%",
            },
            {
              name: 'Inflation',
              value: 3,
              modifier: "%",
            }],
            settings: [],
            targets: [
              {
                name: "Retirement",
                type: "age",
                value: 65,
                modifier: "years old",
                id: 0,
              },
              {
                name: "State pension age",
                type: "age",
                value: taxStore.statePensionAge,
                modifier: "years old",
                id: 1,
                tooltip: 'You may want to defer your state pension'
              },
              {
                name: "Life expectancy",
                type: "age",
                value: 85,
                modifier: "years old",
                id: 2,
              },
            ],
          };
          
          // Generate projection data labels
          const labels = Array.from({ length: newPlan.duration + 1 }, (_, i) => (newPlan.startYear + i).toString());
      
          newPlan.projection = {
            data: [],
            labels: labels,
          };
      
          // Add the new plan to the plans array
          this.plans.push(newPlan);
      
          // Set the newly created plan as the current plan
          this.setCurrentPlan(newPlan.id);
          
          // Perform expensive operations after the state changes
          nextTick(() => {
              this.initializeAllocations();
              this.updateProjectionData();
              
              // Navigate to the new plan's page
              router.push(`/plan/${newPlan.id}`).catch(err => {
                console.error('Router navigation error:', err);
              });
              
              // Set the selected year to the last year of the new plan
              this.selectedYear = newPlan.startYear + newPlan.duration - 1;
          });
      
          // Log the creation of the new plan
          //console.log('New plan created:', newPlan);
      },
      addItem(item, itemType) {
        // Find the current plan being edited
        const planIndex = this.plans.findIndex(plan => plan.id === this.currentPlanId);
        
        if (planIndex === -1) {
          console.error('Current plan not found');
          return;
        }
        
        if (!item.id || item.id === '' || item.id === null || item.id === undefined) {
          // Validate item and assign a unique ID
          const uniqueId = uuidv4();
          item.id = uniqueId;
        }
        
  
        // Determine the type of item and push it into the corresponding section
        if (this.plans[planIndex][itemType]) {
          item.inPlan = true;
          this.plans[planIndex][itemType].push(item);
          //console.log(`Added ${itemType} item:`, item);
        } else {
          console.error('Unknown item type');
        }
        
        //update allocations
        if (itemType === 'savings') {
          this.updateAllocations;
        }
      },
      copyItemToCurrentPlan(item, itemType) {
        const planIndex = this.plans.findIndex(plan => plan.id === this.currentPlanId);
        if (planIndex === -1) {
          console.error('Current plan not found');
          return;
        }
        // Determine the type of item and push it into the corresponding section
        if (this.plans[planIndex][itemType]) {
          item.copied = true;
          this.plans[planIndex][itemType].push(item);
          //console.log(`Added ${itemType} item:`, item);
        } else {
          console.error('Unknown item type');
        }
      },

      deleteThisPlan() { 
        const dialogStore = useDialogStore();
        dialogStore.isOpen = false;
        router.push('/');
        const planToDeleteId = this.currentPlanId;

        // Use setTimeout to delay the deletion
        setTimeout(() => {
          this.plans = this.plans.filter(plan => plan.id !== planToDeleteId);
          // console.log(planToDeleteId);
          this.currentPlanId = null; // Reset currentPlanId after deletion
          
          
        }, 50); // Delay 
      }, 
      
      excludeItem(item, itemType) {
        // A user has deleted a global store item from a plan. so we need to record that, and then exclude it from plan
        const copiedItem = { ...item };
      
        // Add an exclude marker to the copied item
        copiedItem.exclude = true;

        // Add the copied item to the appropriate array in currentPlan
        this.copyItemToCurrentPlan(copiedItem, itemType)
        
      },

      initializeAllocations() {
        this.getCurrentPlan.savingsAllocation = {};
        const savingsAllocation = initializeAllocations(this.plans, this.currentPlanId);
        this.getCurrentPlan.savingsAllocation = savingsAllocation;       
        this.updateProjectionData()
      },

      initializeSelectedYear() {
        const currentPlan = this.getCurrentPlan;
        
        if (currentPlan && currentPlan.projection && currentPlan.projection.labels.length > 0) {
          // Set selectedYear to the last year in the labels array        
          this.selectedYear = currentPlan.startYear;
        }
      },
      
      isItemIdInCurrentPlan(itemId, itemType) {
        // Check if the provided ID exists in currentPlan for the specified itemType
        const itemsOfType = this.getCurrentPlan[itemType] || [];
        return itemsOfType.some(item => item.id === itemId);
      },    
      
              
      saveProjection(projectionData) {
          const planIndex = this.plans.findIndex(plan => plan.id === this.currentPlanId);
        if (planIndex !== -1) {
          // Create a new plan object with updated projection data to ensure reactivity
          this.plans[planIndex] = { ...this.plans[planIndex], projection: projectionData };
        }
      },
      setSelectedYear(year) {
          this.selectedYear = year;            
        },

      setCurrentPlan(planId) {
          this.currentPlanId = planId;
          //console.log('currentPlan set successfully to: ' + this.currentPlanId + ' which is a ' + typeof this.currentPlanId);
      },
  
      removeItemFromPlan(itemId, itemType) { 
        // Find the index of the item with the provided itemId
        const index = this.getCurrentPlan[itemType].findIndex(item => item.id === itemId);
  
        // If the item is found, remove it from the items array
        if (index !== -1) {
          this.getCurrentPlan[itemType].splice(index, 1);
        }

        //update allocations
        if (itemType === 'savings') {
          this.updateAllocations();
        }
      },
      reset() {
        // Reset state to initial values
        const dialogStore = useDialogStore();
        dialogStore.isOpen = false;
        router.push('/'); // Redirect to the homepage
        this.$reset();
      },

      updateSavingsAllocation(points) {
        const plan = this.getCurrentPlan;
        if (!plan || !plan.savingsAllocation) {
          console.error("No valid plan or savings allocation found.");
          return;
        }
    
        points.forEach(point => {
          const series = plan.savingsAllocation[point.seriesIndex];
          if (series && series.allocation && series.allocation[point.dataIndex]) {
            // Update the value and any other relevant fields            
            series.allocation[point.dataIndex].value = point.value;
            series.allocation[point.dataIndex].percent = point.percent; // Example if you have a percent field            
            // Add any additional fields that need to be updated
          
          } else {
            console.error(`No allocation point found at seriesIndex ${point.seriesIndex} and dataIndex ${point.dataIndex}`);
          }
        });
        // Optionally trigger a save or refresh if your planStore requires persistence
      },

      updateCurrentPlanCountry(country) {
        // Find the current plan being edited
        const planIndex = this.plans.findIndex(plan => plan.id === this.currentPlanId);
        if (planIndex === -1) {
          console.error('Current plan not found');
          return;
        }
        this.plans[planIndex].country = country;
      },
         
      updateItem(item, itemType) {
        // Find the current plan being edited
        const planIndex = this.plans.findIndex(plan => plan.id === this.currentPlanId);
        if (planIndex === -1) {
          console.error('Current plan not found');
          return;
        }        
      
        // Check if the item already exists in the plan's data
        const existingItemIndex = this.plans[planIndex][itemType].findIndex(i => i.id === item.id);
      
        if (existingItemIndex !== -1) {
          // Update the existing item if found
          this.plans[planIndex][itemType].splice(existingItemIndex, 1, item);
        } else {
          // Add the item if not found
          item.copied = true;
          this.plans[planIndex][itemType].push(item);
          
        }
        //update allocations
        if (itemType === 'savings') {
          this.updateAllocations();
        }
      },
      updateAllocations() {
        const { updateAllocations } = useAllocations();        
        updateAllocations(this.plans, this.currentPlanId);
      },
      updateProjectionData() {
        // Accessing financial projections composable
        const { projectFinancialPosition } = useFinancialProjections();
              
        if (this.currentPlanId !== null) {
          try {
            const data = projectFinancialPosition();
            this.saveProjection(data);
          
          } catch (error) {
            console.error("Failed to update projection data:", error);
            // Handle the error appropriately, maybe set an error state in the store
          }
        }
        this.updateAllocations();
      },
    },
    persist:true,
  });
  