<template>
    <div class="fixed z-20 inset-0 overflow-y-auto">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                  Edit Monthly Data
                </h3>
                <div class="mt-2">
                  <form @submit.prevent="saveChanges">
                    <div>
                      <label for="income" class="block text-sm font-medium text-gray-700">Income</label>
                      <input type="number" v-model="localData.income" class="mt-1 block w-full" />
                    </div>
                    <div>
                      <label for="expenditure" class="block text-sm font-medium text-gray-700">Expenditure</label>
                      <input type="number" v-model="localData.expenditure" class="mt-1 block w-full" />
                    </div>
                    <div>
                      <label for="disposableIncome" class="block text-sm font-medium text-gray-700">Disposable Income</label>
                      <input type="number" v-model="localData.disposableIncome" class="mt-1 block w-full" />
                    </div>
                    <div class="mt-4 sm:flex sm:items-center">
                      <button type="submit" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
                        Save
                      </button>
                      <button type="button" @click="$emit('close')" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, watchEffect } from 'vue';
  import { useSnapshotsStore } from '../stores/SnapshotsStore.js'
  
  const snapshotsStore = useSnapshotsStore();
  
  const localData = ref({ ...snapshotsStore.selectedMonth });
  
  watchEffect(() => {
    localData.value = { ...snapshotsStore.selectedMonth };
  });
  
  const saveChanges = () => {
    snapshotsStore.updateMonthData(localData.value);
    snapshotsStore.closeDialog();

  };
  </script>
  
  <style scoped>
  /* Add any styles needed for your dialog component */
  </style>
  