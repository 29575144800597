<template>
    <div class="flex items-center gap-2">
    <Menu as="div" class="relative inline-block text-left">
      <Float portal enter="transition duration-200 ease-out" enter-from="scale-95 opacity-0" enter-to="scale-100 opacity-100" leave="transition duration-150 ease-in" leave-from="scale-100 opacity-100" leave-to="scale-95 opacity-0" tailwindcss-origin-class>
        <MenuButton class="flex w-full justify-center gap-x-1.5 align-middle rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          <component :is="selectedFrequency.icon" class="mt-0.5 h-4 w-4"></component>{{ selectedFrequency.label }}
          <ChevronDownIcon class=" h-5 w-5 text-gray-400" aria-hidden="true" />
        </MenuButton>
  
      <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
        <MenuItems class=" z-10 mt-2  w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div class="py-1">
            <MenuItem v-for="option in options" :key="option.value" v-slot="{ active }">
              <a href="#" @click="selectOption(option.value, option.label, option.icon)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm']">
                <component :is="option.icon" class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                {{ option.label }}
              </a>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Float>
    </Menu>
    <div v-if="selectedFrequency.label !== 'None'" class="relative w-32">
      <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span class="text-gray-500 sm:text-sm">{{dialogStore.selectedItem.currency}}</span>
      </div>
      <input type="number" min="0" max="1000000" v-model="dialogStore.selectedItem.contributionAmount" placeholder="Enter value" :class="{ 'text-red-900 ring-1 ring-inset ring-red-300 focus-within:ring-2 focus-within:ring-red-500': !dialogStore.isFieldValid('contributionAmount'), 'block w-full rounded-md border-0 pl-6 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6': true }" @blur="validateField(dialogStore.selectedItem.contributionAmount, 'contributionAmount', dialogStore)" />
    </div>
    </div>
  </template>
    
  <script setup>
  import { ref } from 'vue';
  import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
  import { NoSymbolIcon, ChevronDownIcon, CalendarIcon } from '@heroicons/vue/20/solid';
  import { useDialogStore } from '@/stores/DialogStore';
  import { validateField } from '@/utils/validationUtils';
  import { Float } from '@headlessui-float/vue'

   
  const dialogStore = useDialogStore();
  
  // Define the options for the dropdown
  const options = ref([
    { label: 'None', value: 0, icon: NoSymbolIcon },   
    { label: 'Monthly', value: 0, icon: CalendarIcon },
    { label: 'Yearly', value: 0, icon: CalendarIcon },
     
  ]);
  
  // Define the selected option
  const selectedFrequency = ref(options.value.find(option => option.label === dialogStore.selectedItem.contribution) || options.value[0]); // Initialize with the first option as fallback
  
  // Function to select an option
  const selectOption = (value, label, icon) => {
    dialogStore.setDirtyField('frequencyContribution', true);
    if (value === 0) {
        dialogStore.setErrorField('frequencyContribution',false);
    }
    
    selectedFrequency.value = { value, label, icon };
    dialogStore.selectedItem.contribution = label;
    if (!dialogStore.selectedItem.contributionAmount) { 
        dialogStore.selectedItem.contributionAmount = value;
    }    
  };
  </script>
  
  <style>
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0; 
    }
  </style>
    