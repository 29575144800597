import { defineStore } from 'pinia'

import { useSavingsStore } from './SavingsStore.js'; 
import { useDebtStore } from './DebtStore.js';
import { usePhysicalAssetsStore } from './PhysicalAssetsStore.js'; 

export const useSnapshotsStore = defineStore('snapshots', {
    state: () => ({
        snapshots: [
            { date: '2023-01-01', savingsTotal: 0, assetsTotal: 0, debtsTotal: 0, netWorth: 1, id: '1' },
            { date: '2023-01-03', savingsTotal: 0, assetsTotal: 0, debtsTotal: 0, netWorth: 2, id: '2' },
            { date: '2023-01-08', savingsTotal: 0, assetsTotal: 0, debtsTotal: 0, netWorth: 3, id: '3'}
          ],
          monthlyData: [
            { month: 'January', income: 1000, expenditure: 800, disposableIncome: 200 },
            { month: 'February', income: 1100, expenditure: 850, disposableIncome: 250 },
            { month: 'March', income: 1200, expenditure: 900, disposableIncome: 300 },
            { month: 'April', income: 1300, expenditure: 950, disposableIncome: 350 },
            { month: 'May', income: 1400, expenditure: 1000, disposableIncome: 400 },
            { month: 'June', income: 1500, expenditure: 1050, disposableIncome: 450 },
            { month: 'July', income: 1600, expenditure: 1100, disposableIncome: 500 },
            { month: 'August', income: 1700, expenditure: 1150, disposableIncome: 550 },
            { month: 'September', income: 1800, expenditure: 1200, disposableIncome: 600 },
            { month: 'October', income: 1900, expenditure: 1250, disposableIncome: 650 },
            { month: 'November', income: 2000, expenditure: 1300, disposableIncome: 700 },
            { month: 'December', income: 2100, expenditure: 1350, disposableIncome: 750 },
          ],        
          currency:'£',
          selectedSnapshot: {},
          isOpen: false,
          savingsOpen: false,
          dialogMode: '',
          selectedItemType: '',
          selectedItemId: '',
          selectedMonth: null,
          fields: {},
          fieldErrors: {},
          dirtyFields: {
            name: false,
          },        
    }),

    getters: {
      latestNetWorth: (state) => {
        if (state.snapshots.length === 0) return 0; // Return 0 if no snapshots exist
        return state.snapshots[state.snapshots.length - 1].netWorth; // Return netWorth of the latest snapshot
      },
      latestAssets:(state) => {
        if (state.snapshots.length === 0) return 0; // Return 0 if no snapshots exist
        const assets = state.snapshots[state.snapshots.length - 1].assetsTotal + state.snapshots[state.snapshots.length - 1].savingsTotal;
        return assets; // Return assets of the latest snapshot
      },
      latestDebts:(state) => {
        if (state.snapshots.length === 0) return 0; // Return 0 if no snapshots exist
        return state.snapshots[state.snapshots.length - 1].debtsTotal; // Return debt of the latest snapshot
      },
      latestEntry:(state)=> {
        if (state.snapshots.length === 0) return 0; // Return 0 if no snapshots exist
        return state.snapshots[state.snapshots.length - 1];
      },
    },

    actions: {
        openDialog(mode, type, selectedItemId) {
          if (type === 'savings') {
            this.savingsOpen = true;
          }
          else if (type === "snapshot") {
            this.isOpen = true;
          }          
          this.dialogMode = mode;
          this.selectedItemType = type;
          this.selectedItemId = selectedItemId;
        },
        closeDialog() {      
          this.isOpen = false;
          this.savingsOpen = false;
          setTimeout(() => { //timeout to allow transition to happen
            this.dialogMode = '';
            this.selectedItemType = '';
            this.selectedItem = '';
            this.selectedItemId = -1;     
            this.selectedSnapshot = {};
            this.clearFields();            
        }, 200); // Adjust the delay time to match your transition duration  
        },   
        selectSnapshot(snapshot) {
          this.selectedSnapshot = snapshot;
        },
        deleteSnapshot(itemId) {
          const index = this.snapshots.findIndex(snapshot => snapshot.id === itemId);
          if (index !== -1) {
            this.snapshots.splice(index, 1);
          }
        },
        addSnapshot(snapshot) {
          // Assuming `snapshot` is an object with { name, value }
          // Generate a simple ID (for example purposes; consider a more robust approach for production)
          const newId = this.snapshots.length > 0 ? Math.max(...this.snapshots.map(a => a.id)) + 1 : 1;
          this.snapshots.push({ ...snapshot, id: newId });
        },
  
        sortSnapshotsByDate() {
          //this.toggleSortOrder();
          this.snapshots.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return this.sortOrder = dateA - dateB ;//: dateB - dateA
          });
          // console.log('Sorted:', this.snapshots); // Log sorted array
        },
        
        updateSnapshot() {
          const savingsStore = useSavingsStore();
          const debtStore = useDebtStore();
          const physicalAssetsStore = usePhysicalAssetsStore();
          // Create a new snapshot object
          // Assume 'snapshots' is an array of snapshot objects in your store's state

          // Get today's date in 'YYYY-MM-DD' format
          const today = new Date().toISOString().split('T')[0];

          // Try to find an existing snapshot for today, i.e. you have edited already today so just update
          let existingSnapshot = this.snapshots.find(snapshot => snapshot.date === today);

          if (this.dialogMode === 'edit') {
                      
            const index = this.snapshots.findIndex(snapshot => snapshot.id === this.selectedItemId);
          
            if (index !== -1) {
              // Update the found snapshot object's properties directly
              this.snapshots[index] = {
                ...this.snapshots[index],
                ...this.selectedSnapshot,
                netWorth: this.selectedSnapshot.savingsTotal + this.selectedSnapshot.assetsTotal - this.selectedSnapshot.debtsTotal
              };
            }
          
            return;
          }
          

          else if (this.dialogMode === 'add') {
            console.log("adding")
            const newSnapshot = this.selectedSnapshot;
            newSnapshot.id = (this.snapshots.length + 1).toString() // Simple ID generation (incremental)
            this.snapshots.push(newSnapshot);
            return;
          }

          if (existingSnapshot) {
            console.log("updating today's snapshot")
              // If a snapshot for today exists, update its values
              existingSnapshot.savingsTotal = savingsStore.totalValue;
              existingSnapshot.debtsTotal = debtStore.totalValue;
              existingSnapshot.assetsTotal = physicalAssetsStore.totalValue;
              // Update net worth calculation if needed
              existingSnapshot.netWorth = existingSnapshot.savingsTotal + existingSnapshot.assetsTotal - existingSnapshot.debtsTotal ;
          }
          else {
            console.log("else make a new one")
              // If no snapshot for today exists, create a new one
              const newSnapshot = {
                  date: today,
                  savingsTotal: savingsStore.totalValue,
                  debtsTotal: debtStore.totalValue,
                  assetsTotal: physicalAssetsStore.totalValue,

                  netWorth: savingsStore.totalValue + physicalAssetsStore.totalValue - debtStore.totalValue,
                  id: (this.snapshots.length + 1).toString() // Simple ID generation (incremental)
              };
              // Add the new snapshot to the snapshots array
              this.snapshots.push(newSnapshot);
          }
        },
        clearFields() {
          // Clear dirtyFields
          for (const field of Object.keys(this.dirtyFields)) {
            this.dirtyFields[field] = false;
          }
        
          // Clear fields
          for (const field of Object.keys(this.fields)) {
            this.fields[field] = false;
          }
        
          // Clear fieldErrors
          for (const field of Object.keys(this.fieldErrors)) {
            this.fieldErrors[field] = false;
          }
        },
        updateMonthData(updatedData) {
          const index = this.monthlyData.findIndex(month => month.month === updatedData.month);
          if (index !== -1) {
            this.monthlyData[index] = updatedData;
          }
        },
      },
      persist:true,

}); 
