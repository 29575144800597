<template>
    <TransitionRoot appear :show="dialogStore.isOpen" as="template">
      <Dialog as="div" class="relative z-30">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
        </TransitionChild>
  
        <div class="fixed inset-0 z-30 w-screen overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
  
                <div class="text-left">
                    <div class="flex justify-between mb-3 text-left">
                        <div>
                            <DialogTitle >
                                <div class="text-base font-normal leading-6 text-gray-500">{{ dialogStore.dialogMode === 'add' ? 'Add' : 'Edit' }}</div>
                                <h1>{{ dialogStore.selectedItemType.charAt(0).toUpperCase() + dialogStore.selectedItemType.slice(1) }}</h1>                  
                            </DialogTitle>
                        </div>
                        <div class="flex items-center gap-x-4">
                            <button v-if="dialogStore.dialogMode === 'edit' && dialogStore.selectedItemType !== 'settings'" @click="deleteItem" class="inline-flex items-center gap-x-1.5 h-8 rounded bg-white px-2 py-1 text-xs font-semibold text-red-700  hover:bg-red-50"> <!-- Use inline-flex here -->
                                    <TrashIcon class="h-4 w-4" aria-hidden="true" />
                                    <span>Delete</span>
                            </button>
                            <button @click="closeDialog" class="inline-flex items-center gap-x-1.5 h-8 rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"> <!-- Use inline-flex here -->
                                    <XMarkIcon class="h-4 w-4" aria-hidden="true" />
                                    <span>Close</span>
                            </button>    
                        </div>
                    </div>         

                    <form @submit.prevent="saveChanges">                         
                            <div class="flex items-center gap-2 py-3">
                                <div class="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                                    <label class="block text-xs font-medium text-gray-500">Name</label>
                                    <input type="text" name="name" v-model="formData.name" class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" />
                                </div>
                                <div class="relative rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                                    <label class="block text-xs font-medium text-gray-500">Value</label>
                                    <div class="pointer-events-none absolute top-7 left-0 flex items-center pl-3">
                                        <span class="text-gray-500 sm:text-sm">{{ userProfileStore.preferredCurrency }}</span>
                                    </div>
                                    <input type="number" name="value" v-model="formData.value" class="block w-full border-0 p-0 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" />                                    
                                </div>
                            </div>                    
        
                            <div class="mt-5 sm:mt-6 flex flex-row gap-3">
                            <button type="button" class="flex-1 justify-center rounded-md bg-white p-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="closeDialog" ref="cancelButtonRef">Cancel</button>
                            <button type="submit" class="flex-1 justify-center rounded-md bg-indigo-600 p-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-300">Save</button>
                        </div>
                    </form>
                </div>
  
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </template>

<script setup>
    import { computed } from 'vue';
    import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import { usePhysicalAssetsStore } from '../stores/PhysicalAssetsStore.js';
    import { useSavingsStore } from '../stores/SavingsStore.js';
    import { useDebtStore } from '../stores/DebtStore.js';
    import { useDialogStore } from '../stores/DialogStore';
    import { useSnapshotsStore } from '../stores/SnapshotsStore';
    import { XMarkIcon, TrashIcon } from '@heroicons/vue/24/solid';
    import { useUserProfileStore } from '@/stores/UserProfile';
    const userProfileStore = useUserProfileStore();
    const dialogStore  = useDialogStore();
    const snapshotsStore = useSnapshotsStore();

    // Computed property to determine the correct store based on itemType
    const yourStore = computed(() => {
        switch (dialogStore.selectedItemType) {
            case 'physical assets': return usePhysicalAssetsStore();
            case 'savings': return useSavingsStore();
            case 'debt': return useDebtStore();
            default: return null;
        }        
    });

    const formData = computed(() => {
        if (yourStore.value && yourStore.value.items) {
            const selectedItem = yourStore.value.items.find(item => item.id === dialogStore.selectedItemId);
            return {
                name: selectedItem ? selectedItem.name : '',
                value: selectedItem ? selectedItem.value : '',
                id: dialogStore.selectedItemId,
            };
        } else {
            return {
                name: '',
                value: '',
                id: '',
            };
        }
    });

    // Methods to handle form submission and closing the dialog
    function saveChanges() {
        const item = formData.value;
        if (dialogStore.dialogMode === 'edit') {
            // Assuming each item has an 'id' and other fields you want to update
            Object.keys(item).forEach(field => {
                if (field !== 'id') { // Skip the 'id' field when updating
                    yourStore.value.updateItem(item.id, field, item[field]);                
                }
            });
        } 
        else if (dialogStore.toDelete) {
            console.log('deleting');

            yourStore.value.deleteItem(item.id); // Assuming your store has a deleteItem method
        }        
        else if (dialogStore.dialogMode === 'add') {
            // Logic for adding a new item
            yourStore.value.addItem(item);
            console.log('adding');
        }
            
        // After saving changes, you might want to close the dialog or provide feedback
        snapshotsStore.updateSnapshot();  
        dialogStore.toDelete = false;      
        dialogStore.closeDialog();
    }

    function closeDialog() {
        dialogStore.closeDialog();
    }

    const deleteItem = () => {
      yourStore.value.deleteItem(dialogStore.selectedItemId);
      snapshotsStore.updateSnapshot();
      closeDialog();
    };
    
</script>