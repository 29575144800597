<template>
    <TransitionRoot as="template" :show="dialogStore.allocationOpen">
        <Dialog class="relative z-[60]" @close="closeDialog()">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
    
        <div class="fixed inset-0 z-[100] w-screen overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">                
                <DialogPanel class="relative  overflow-hidden transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-2 sm:w-full 2xl:m-2 sm:p-6 h-[90dvh]">
                <div ref="dialogPanel" class="h-full">
                    <div id="allocation-dialog-header" ref="dialogHeader" class="flex justify-between mb-3 text-left">
                        <DialogTitle>                    
                            <h1>{{ dialogStore.selectedItemType.charAt(0).toUpperCase() + dialogStore.selectedItemType.slice(1) }}</h1>                  
                        </DialogTitle>
        
                        <button @click="closeDialog" class="inline-flex items-center gap-x-1.5 h-8 rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"> <!-- Use inline-flex here -->
                            <XMarkIcon class="h-4 w-4" aria-hidden="true" />
                            <span>Close</span>
                        </button>  
                    </div>  
                    <div class="h-full overflow-y-auto overflow-x-hidden">
                        <PlanSavingsAllocationChart :chartHeight=chartHeight.value id="dialog-chart" :style="{ height: chartHeight.value + 'px' }"/>
                    </div>              
                </div>
                </DialogPanel>
            </TransitionChild>
            </div>
        </div>
        </Dialog>
    </TransitionRoot>
    </template>
    
    <script setup>
    import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import { XMarkIcon } from '@heroicons/vue/24/solid';
    import { useDialogStore } from '@/stores/DialogStore';
    import PlanSavingsAllocationChart  from '@/components/PlanSavingsAllocationChart'
    import { ref, onMounted, onBeforeUnmount, } from 'vue';
    
    const dialogStore = useDialogStore();
    const dialogPanel = ref(null);
    const dialogHeader = ref(null);
    const chartHeight = ref(0);
    
    const closeDialog = () => {       
        dialogStore.allocationOpen = false;
        dialogStore.closeDialog();
    };
    
    const updateChartHeight = () => {
        if (dialogPanel.value && dialogHeader.value) {
            const panelHeight = dialogPanel.value.clientHeight;
            const headerHeight = dialogHeader.value.clientHeight;
            chartHeight.value = panelHeight - headerHeight - 48;     
            // console.log(panelHeight, headerHeight, chartHeight.value)                   
        }
    };
    
    onMounted(() => {
        updateChartHeight();
        window.addEventListener('resize', updateChartHeight);
    });
    
    onBeforeUnmount(() => {
        window.removeEventListener('resize', updateChartHeight);
    });
    </script>
    