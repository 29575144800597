<template>
    <main class="flex flex-col md:flex-row py-2 md:py-4 lg:pl-72">
        <div class="flex flex-col px-2 md:px-4 w-full md:w-9/12">
            <!-- Section: Today and Date -->
            <div class="flex flex-col md:flex-row justify-between bg-white rounded-lg shadow p-4 ring-1 ring-black ring-opacity-5">
                <div>
                    <h1 class="text-2xl font-bold tracking-tight text-violet-600 sm:text-3xl">
                        {{ isLatestToday ? 'Today' : 'Your latest overview' }}
                    </h1>
                    <div class="text-sm text-gray-500">{{ displayDate }}</div>
                </div>
                <div class="flex flex-row gap-6 align-bottom">
                    <div>
                        <div class="text-sm font-normal text-gray-500">Net worth</div>
                        <div class="text-2xl font-bold text-gray-800">{{ $formatNumber(latestNetWorth) }}</div>
                    </div>
                    <div class="flex flex-row gap-4">
                        <div>
                            <div class="text-sm font-normal text-gray-500">Assets</div>
                            <div class="text-xl font-bold text-gray-900">{{ $formatNumber(latestAssets) }}</div>
                        </div>
                        <div>
                            <div class="text-sm font-normal text-gray-500">Debt</div>
                            <div class="text-xl font-bold text-gray-900">{{ $formatNumber(latestDebts) }}</div>
                        </div>
                    </div>
                </div>
            </div>
    
            <!-- Row with Two Columns -->
            <div class="flex flex-col md:flex-row md:gap-4 md:flex-row-reverse" >
                
                    <!-- Main chart area Navigation -->
                    <div class="flex flex-col w-full md:w-3/5 mt-2 md:mt-4 rounded-lg bg-white shadow ring-1 ring-black ring-opacity-5 h-min">
                        <div class="block">
                            <div class="border-b border-gray-200 pl-4 ">
                            <nav class="flex space-x-4" aria-label="Tabs">
                                <button v-for="tab in tabs" :key="tab.name" @click="setActiveTab(tab.name)" :class="[tab.current ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium']" :aria-current="tab.current ? 'page' : undefined">{{ tab.name }}</button>
                            </nav>
                        </div>
                    </div>
                                    
                    <!-- Column 2: Tab Contents -->
                    <div class="flex flex-col w-full mt-2 md:mt-4 h-72 md:h-[60svh] min-h-72">

                        <!--desktop-->
                        <div class="flex flex-row justify-between flex gap-2 ">
                            <div v-if="activeTab === 'Discretionary income'" class="pl-4 w-1/2">
                                <h1 class="text-base font-semibold leading-6 text-gray-900">Monthly discretionary income</h1>
                                <p class="text-sm text-gray-700">See how much you are saving</p>
                            </div>
                            <div v-if="activeTab === 'Net worth'" class="pl-4 w-1/2">
                                <h1 class="text-base font-semibold leading-6 text-gray-900">Net worth</h1>
                                <p class="text-sm text-gray-700">Track your net worth</p>
                            </div>
                            <nav class="flex space-x-4 px-4 w-1/2 justify-end items-center" aria-label="Toggles">
                                <a v-for="toggle in toggles" :key="toggle.name" @click="setActiveToggle(toggle.name)" :href="toggle.href" :class="[toggle.current ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700', 'rounded-md px-3 py-2 text-sm font-medium cursor-pointer']" :aria-current="toggle.current ? 'page' : undefined">{{ toggle.name }}</a>
                            </nav>
                        </div>

                        <div v-if="activeTab === 'Net worth'" class="h-full mt-2 md:mt-4">                            
                            <div v-if="activeToggle === 'Chart'" class=" h-full">
                                <NetWorthLineChart />
                            </div>
                        
                            <div v-if="activeToggle === 'Table'" class="">
                                <SnapshotTable />
                            </div>
                        </div>
                        <div v-if="activeTab === 'Discretionary income'" class="h-full">
                            <div v-if="activeToggle === 'Chart'" class=" h-full">
                                <!-- Add content for Disposable Income tab here -->
                                <DisposableIncomeChart />
                            </div>
                            <div v-if="activeTab === 'Discretionary income'" class="mt-2 md:mt-4">
                                <div v-if="activeToggle === 'Table'" class="">
                                    <!-- Add table or other content for Disposable Income tab here -->
                                    <DisposableIncomeTable />
                                </div>
                            </div>
                        </div>
                </div>
            </div>
    
                <!-- Column 1: HomePage Cards -->
                <div class="w-full md:w-2/5 mb-4">
                    <HomePageCard card-title="Savings" />
                    <HomePageCard card-title="Physical assets" />
                    <HomePageCard card-title="Debt" />
                </div>
            </div>
        </div>
    
        <!-- Right Section: Plans -->
        <div class="flex flex-col md:w-3/12 p-3 m-2 md:m-4 md:ml-0 md:mt-0 h-fit border border-gray-300 rounded-lg">
            <div class="flex justify-between">
                <h2>Plans</h2>
                <button type="button" @click="planStore.createPlan" class="inline-flex items-center gap-x-1.5 rounded-md bg-violet-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600">
                    <PlusCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />New plan
                </button>
            </div>
            <div role="list" class="mt-2 space-y-2">
                <div v-if="planStore.plans.length === 0" @click="planStore.createPlan" class="text-gray-400 flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">
                    No plans. Make one!
                </div>
                <div v-for="plan in planStore.plans" :key="plan.id">
                    <router-link :to="`/plan/${plan.id}`" class="flex justify-between w-full gap-x-3 rounded-md bg-white shadow p-4 text-sm font-semibold text-violet-700 hover:bg-violet-200 ring-1 ring-black ring-opacity-5">
                        {{ plan.name }}<ChevronRightIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    </router-link>
                </div>
            </div>
        </div>
        
        <HomeEditDialog @close="closeDialog" />
    </main>
    </template>
    
    <script setup>
    import { ref, computed } from 'vue';
    import { format, isSameDay } from 'date-fns';
    import { useSnapshotsStore } from '@/stores/SnapshotsStore';
    import { useDialogStore } from '../stores/DialogStore';
    import { usePlanStore } from '@/stores/PlanStore';
    import HomeEditDialog from './HomeEditDialog.vue';
    import NetWorthLineChart from './HomePageLineChart.vue';
    import HomePageCard from './HomePageCard.vue';
    import { PlusCircleIcon, ChevronRightIcon } from '@heroicons/vue/24/solid';
    import SnapshotTable from '@/components/HomeSnapshotTable';
    import DisposableIncomeChart from '@/components/HomePageDisposableIncomeChart';
    import DisposableIncomeTable from '@/components/HomePageDisposableIncomeTable';
    
    const dialogStore = useDialogStore();
    const planStore = usePlanStore();
    const snapshotStore = useSnapshotsStore();
    
    const tabs = ref([
        { name: 'Net worth', current: true },
        { name: 'Discretionary income', current: false },
    ]);
       
    const activeTab = ref(tabs.value.find(tab => tab.current).name);
    
    const setActiveTab = (tabName) => {
        tabs.value.forEach(tab => tab.current = tab.name === tabName);
        activeTab.value = tabName;
    };
 
    const toggles = ref([
        { name: 'Chart', current: true },
        { name: 'Table', current: false },
    ]);
    const activeToggle = ref(toggles.value.find(toggle => toggle.current).name);
    const setActiveToggle = (toggleName) => {
        toggles.value.forEach(toggle => toggle.current = toggle.name === toggleName);
        activeToggle.value = toggleName;
    };

    const latestSnapshotDate = computed(() => {
        if (snapshotStore.snapshots.length === 0) return null;
    
        const latest = snapshotStore.snapshots.reduce((latest, item) => {
            const itemDate = new Date(item.date);
            return itemDate > latest ? itemDate : latest;
        }, new Date(0));
    
        return latest;
    });
    
    const isLatestToday = computed(() => {
        if (!latestSnapshotDate.value) return false;
        return isSameDay(latestSnapshotDate.value, new Date());
    });
    
    const displayDate = computed(() => {
        if (!latestSnapshotDate.value) return format(new Date(), 'do MMMM yyyy');
        return format(latestSnapshotDate.value, 'do MMMM yyyy');
    });
    
    const latestNetWorth = computed(() => snapshotStore.latestNetWorth);
    const latestAssets = computed(() => snapshotStore.latestAssets);
    const latestDebts = computed(() => snapshotStore.latestDebts);
    
    const closeDialog = () => {
        dialogStore.closeDialog();
    };
    </script>
    