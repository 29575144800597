import { usePlanStore } from '@/stores/PlanStore';

// Mapping of currency symbols to ISO currency codes
const currencySymbolToCode = {
  '£': 'GBP',
  '$': 'USD',
  '€': 'EUR',
  // Add more mappings as needed
};

// Global method for number formatting. Default is GBP, and 0 decimals
export function formatNumber(value, decimalPlaces = 0, shorthand = false, currency = 'GBP') {
  const planStore = usePlanStore();

  // Get the currency symbol from the planStore and map it to its corresponding code
  const currencySymbol = planStore.getCurrentPlan?.currency;
  currency = currencySymbolToCode[currencySymbol] || 'GBP';  // Default to 'GBP' if no match is found

  if (shorthand) {
    // Ensure value is a number and not NaN
    const numValue = Number(value);
    if (isNaN(numValue)) {
      return 'Invalid number';
    }

    const suffixes = ["", "K", "M", "B", "T"];
    
    // Get the absolute value to calculate suffixNum correctly, even for negative values
    const absValue = Math.abs(numValue);

    // Find the suffix index based on the number's magnitude
    let suffixNum = Math.floor(Math.log10(absValue) / 3);
    suffixNum = Math.max(0, Math.min(suffixNum, suffixes.length - 1)); // Ensure suffixNum is within bounds

    let shortValue = numValue / Math.pow(1000, suffixNum);

    // Adjust decimal places: if the value is less than 1000 or zero, use fewer decimals
    const decimalPlacesForValue = (absValue < 1000) ? 1 : decimalPlaces;
    shortValue = shortValue.toFixed(decimalPlacesForValue);

    const options = {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: decimalPlacesForValue,
      maximumFractionDigits: decimalPlacesForValue,
    };

    const formattedValue = new Intl.NumberFormat('en-US', options).format(Math.abs(shortValue)) + suffixes[suffixNum];

    return numValue < 0 ? '-' + formattedValue : formattedValue;
  }
  else {
    // Handle standard number formatting without shorthand
    const decimalPlacesForValue = (value === 0 || (value >= 0 && value < 1000)) ? 0 : decimalPlaces;

    const options = {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: decimalPlacesForValue,
      maximumFractionDigits: decimalPlacesForValue,
    };

    const formattedValue = new Intl.NumberFormat('en-US', options).format(Math.abs(value));

    return value < 0 ? '-' + formattedValue : formattedValue
  }}