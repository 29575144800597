import { createRouter, createWebHistory } from 'vue-router';

// Import your components
import HomePage from '../components/HomePage.vue';
// import NetWorth from '../components/NetWorth.vue';
import PlanSingle from '../components/PlanSingle.vue'; 
// import TestPage from '../components/TestPage.vue'; 


// Define routes
const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  // {
  //   path: '/net-worth',
  //   name: 'NetWorth',
  //   component: NetWorth
  // },
  {
    path: '/plan/:id',
    name: 'Plan',
    component: PlanSingle
  },
  // {
  //   path: '/testpage',
  //   name: 'Test Page',
  //   component: TestPage
  // }
];

// Create router instance
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;