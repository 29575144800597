<template>   
<TransitionRoot appear :show="dialogStore.isOpen" as="template" >
      <Dialog :initialFocus="saveButtonRef" as="div" class="relative z-30" @close="closeDialog">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div class="fixed inset-0 z-20 w-screen overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-0 text-center items-center md:p-4 ">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel class="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:w-full sm:max-w-lg sm:p-6">                  
                <div>                                        
                    <div class="flex justify-between mb-3 text-left">
                        <div>
                            <DialogTitle >
                                <div class="text-base font-normal leading-6 text-gray-500">{{ dialogStore.dialogMode === 'add' ? 'Add' : 'Edit' }}</div>
                                <h1>{{ dialogStore.selectedItemType.charAt(0).toUpperCase() + dialogStore.selectedItemType.slice(1) }}</h1>                  
                            </DialogTitle>
                        </div>
                        <div class="flex items-center gap-x-4">
                            <button v-if="dialogStore.dialogMode === 'edit' && dialogStore.selectedItemType !=='savings' && dialogStore.selectedItemType !=='savingsallocationpoint' || dialogStore.dialogMode === 'settings'" @click="deleteItem" class="inline-flex items-center gap-x-1.5 h-8 rounded bg-white px-2 py-1 text-xs font-semibold text-red-700  hover:bg-red-50"> <!-- Use inline-flex here -->
                                    <TrashIcon class="h-4 w-4" aria-hidden="true" />
                                    <span v-if="dialogStore.dialogMode === 'edit'">Delete item</span>
                                    <span v-if="dialogStore.dialogMode === 'settings'">Delete plan</span>
                            </button>

                            <button v-if="shouldShowDeleteButton" @click="deleteItem" :class="buttonClass" :disabled="dialogStore.isDeleteButtonDisabled" v-tooltip="deleteButtonTooltip"  >
                              <TrashIcon class="h-4 w-4" aria-hidden="true" />
                              <span>Delete item</span>
                            </button>

                            <button @click="closeDialog" class="inline-flex items-center gap-x-1.5 h-8 rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"> <!-- Use inline-flex here -->
                                    <XMarkIcon class="h-4 w-4" aria-hidden="true" />
                                    <span>Close</span>
                            </button>    
                        </div>
                    </div>

                    <form @submit.prevent="saveChanges">
                        <div class="overflow-y-auto max-h-[50dvh] border-y py-2 border-gray-200">
                              <!-- Transition for the dynamic content -->
                              <Transition :name="transitionName" mode="out-in">
                                <component :is="editComponent" :key="delayedEditComponentKey" />
                              </Transition>                      
                        </div>
                        <div class="mt-5 sm:mt-6 flex flex-row gap-3">
                            <button type="button" class="flex-1 justify-center rounded-md bg-white p-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="closeDialog" ref="cancelButtonRef">Cancel</button>
                            <button ref="saveButtonRef" type="submit" class="flex-1 justify-center rounded-md bg-indigo-600 p-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-300">Save</button>
                        </div>
                    </form>
                </div>
  
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot> 
  </template>

<script setup>
  import { computed, ref, watch, nextTick } from 'vue';
  import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
  import { useDialogStore } from '../stores/DialogStore';  
  import { v4 as uuidv4 } from 'uuid'; // Import UUID v4 generator function

  import EditIncome from './PlanEditIncome.vue';
  import EditExpenses from './PlanEditExpenses.vue';
  import EditSettings from '@/components/PlanEditSettings.vue'
  import EditSavings from '@/components/PlanEditSavings.vue'
  import EditDebt from '@/components/PlanEditDebt.vue'
  import EditPhysicalAssetsComponent from '@/components/PlanEditPhysicalAssets.vue'
  import EditTarget from '@/components/PlanEditTarget.vue'
  import PlanEditSettingsTax from '@/components/PlanEditSettingsTax.vue'
  import PlanEditSavingsAllocationPoint from '@/components/PlanEditSavingsAllocationPoint.vue'
  
  import { XMarkIcon, TrashIcon } from '@heroicons/vue/24/solid';
  import { usePhysicalAssetsStore } from '@/stores/PhysicalAssetsStore.js';
  import { useSavingsStore } from '@/stores/SavingsStore.js';
  import { useDebtStore } from '@/stores/DebtStore.js';
  import { useIncomeStore } from '@/stores/IncomeStore';
  import { useExpensesStore } from '@/stores/ExpensesStore';
  import { usePlanStore } from '@/stores/PlanStore';
  import { useUserProfileStore } from '@/stores/UserProfile';
  import { useTaxStore } from '@/stores/TaxStore';

  import { validateField } from '@/utils/validationUtils';
  // import PlanChart from './PlanChart.vue';
  
  const dialogStore  = useDialogStore();
  const planStore = usePlanStore();
  const userProfile = useUserProfileStore();
  const taxStore = useTaxStore();

  const saveButtonRef = ref(null);
  
  // Computed property to determine the correct store based on itemType
  const storeAndComponent = computed(() => {
    switch (dialogStore.selectedItemType) {
        case 'physicalAssets':
        return { store: usePhysicalAssetsStore(), component: EditPhysicalAssetsComponent}; // , component: PhysicalAssetsComponent 
        case 'savings':
        return { store: useSavingsStore(),component: EditSavings }; 
        case 'debts':
        return { store: useDebtStore(), component: EditDebt }; // , component: DebtComponent
        case 'income':
        return { store: useIncomeStore(), component: EditIncome };
        case 'expenses':
        return { store: useExpensesStore(), component: EditExpenses }; 
        case 'settings' :
          return { store: usePlanStore(), component: EditSettings };
        case 'tax' :
          return { store: usePlanStore(), component: PlanEditSettingsTax };
        case 'targets' :
          return { store: usePlanStore(), component: EditTarget };
        case 'savingsallocationpoint' :
          return { store: usePlanStore(), component: PlanEditSavingsAllocationPoint };
        default:
        return { store: null, component: null };
    }
  });

  // Then, when you need to access the store or component, you can do so like this:
  //const yourStore = computed(() => storeAndComponent.value?.store);
  const editComponent = computed(() => storeAndComponent.value?.component);    
  
  const saveChanges = () => {        
    //need to set all fields from fields to error true
    Object.keys(dialogStore.fields).forEach(field => {   
      if (dialogStore.selectedItemType === 'targets') {
        if (!dialogStore.selectedItem[field]) {
          validateField(dialogStore.selectedItem.value, field, dialogStore, planStore);
        }
        else {
          validateField(dialogStore.selectedItem[field], field, dialogStore, planStore);
        }
      }
      else {
        validateField(dialogStore.selectedItem[field], field, dialogStore, planStore);
      }                    
    });         

    if (dialogStore.selectedItemType === 'income') {       
      if (dialogStore.selectedItem.employerPension?.active && dialogStore.selectedItem.employerPension?.account.id === 'create') {
        const incomeItem = dialogStore.selectedItem;
          // Validate item and assign a unique ID
        const uniqueId = uuidv4();      
        const name = dialogStore.selectedItem.name + ' Pension';
        const pensionAccount = {name: name, id: uniqueId, value: 0, startYear: incomeItem.startYear, endYear: incomeItem.endYear, growthRate: 'Investment growth rate', taxable: false, isPension: true, taxRelief: true, taxableInterest: false, includeInAllocation: true, linkedTo: incomeItem.id, pensionTake25: { active: true, account: {name: 'Current account', id: "1"}}}
        dialogStore.selectedItem.employerPension.account.id = uniqueId;
        dialogStore.selectedItem.employerPension.account.name = name;
        planStore.addItem(pensionAccount, 'savings');
      }

      
    }

    if (dialogStore.selectedItemType === 'savings') {       
      if (dialogStore.selectedItem.isPension && dialogStore.selectedItem.pensionTake25?.account?.id === 'create') {
        const savingsItem = dialogStore.selectedItem;
          // Validate item and assign a unique ID
        const uniqueId = uuidv4();      
        const name = dialogStore.selectedItem.name + ' 25% account';
        const savingsAccount = {name: name, id: uniqueId, value: 0, startYear: userProfile.getYearAtAge(planStore.getCurrentPlan.settings.normalMinimumPensionAge || taxStore.normalMinimumPensionAge), endYear: savingsItem.endYear, growthRate: 'Investment growth rate', isPension: false, taxRelief: false, taxableInterest: false, includeInAllocation: false, linkedTo: savingsItem.id, fundFee: 0.1};
        dialogStore.selectedItem.pensionTake25.account.id = uniqueId;
        dialogStore.selectedItem.pensionTake25.account.name = name;
        planStore.addItem(savingsAccount, 'savings');
      }
    }

    if (dialogStore.isValid) {
      if (dialogStore.dialogMode === 'edit') {
        if (dialogStore.selectedItemType === 'savingsallocationpoint') {
            dialogStore.selectedItem.forEach((series) => {
              series.value = userProfile.isAllocationAmountSelected ? series.absolute : series.percent;
            })
            planStore.updateSavingsAllocation(dialogStore.selectedItem);
        }              
        else {
          planStore.updateItem(dialogStore.selectedItem, dialogStore.selectedItemType);
        }        
          
      }
      else if (dialogStore.dialogMode === 'add') {
        planStore.addItem(dialogStore.selectedItem, dialogStore.selectedItemType);            
      }

      else if (dialogStore.dialogMode === 'settings') {            
          planStore.getCurrentPlan.duration = dialogStore.selectedItem.duration;              
          planStore.getCurrentPlan.startYear = dialogStore.selectedItem.startYear;
          planStore.getCurrentPlan.currency = dialogStore.selectedItem.currency;
          planStore.getCurrentPlan.nationalInsurance = dialogStore.selectedItem.nationalInsurance;
          planStore.getCurrentPlan.includeStudentLoanInNetWorth = dialogStore.selectedItem.includeStudentLoanInNetWorth;
      }   

      if (dialogStore.selectedYear < planStore.getCurrentPlan.startYear || dialogStore.selectedYear > planStore.getCurrentPlanEndYear) {
        dialogStore.selectedYear = planStore.getCurrentPlan.startYear;
      }

      closeDialog();
      
      planStore.updateAllocations();
      planStore.updateProjectionData();
      
    }
  };

  //savings cannot delete last item included in allocation logic
  
  const shouldShowDeleteButton = computed(() => {
    return dialogStore.dialogMode === 'edit' && dialogStore.selectedItemType === 'savings';
  });

  const buttonClass = computed(() => {
    return dialogStore.isDeleteButtonDisabled
      ? 'cursor-default inline-flex items-center gap-x-1.5 h-8 rounded bg-white px-2 py-1 text-xs font-semibold text-gray-300'
      : 'inline-flex items-center gap-x-1.5 h-8 rounded bg-white px-2 py-1 text-xs font-semibold text-red-700 hover:bg-red-50';
  });

  const deleteButtonTooltip = computed(() => {
    return dialogStore.isDeleteButtonDisabled
      ? { content: 'Plans need at least one savings account', theme: 'floating-vue-theme' }
      : null;
  });

  const deleteItem = () => {
    const item = dialogStore.selectedItem;

    if (dialogStore.dialogMode == "settings") {
      planStore.deleteThisPlan();
      return;
    }

    if (!planStore.isItemIdInCurrentPlan(item.id, dialogStore.selectedItemType) || item.copied === true) {
      // Item is not in currentPlan. Record the ID in currentPlan to exclude it
      //todo this should check the main store really, then do this
      planStore.excludeItem(dialogStore.selectedItem,dialogStore.selectedItemType);        
    }

    else {        
      // Delete the item from the currentPlan
      planStore.removeItemFromPlan(item.id, dialogStore.selectedItemType);
    }
    planStore.updateProjectionData();
    // planStore.updateAllocations();      
    dialogStore.closeDialog();
  };

  const closeDialog = () => {       
    dialogStore.closeDialog();
  };

const dialogJustOpened = ref(false);

 
// Add a delayed key update only if the dialog is already open
const delayedEditComponentKey = ref(dialogStore.selectedItemType);

// Watch for dialog open state and reset the flag
watch(() => dialogStore.isOpen, (isOpen) => {
  if (isOpen) {
    dialogJustOpened.value = true;
    nextTick(() => {
      dialogJustOpened.value = false;
    });
  }
});

// Add a delayed key update only if the dialog was not just opened

watch(() => dialogStore.selectedItemType, (newType) => {
  if (!dialogJustOpened.value) {
    nextTick(() => {
      delayedEditComponentKey.value = newType;
    });
  } else {
    delayedEditComponentKey.value = newType;
  }
});

// Determine the correct transition classes based on the component
const transitionName = computed(() => {
  if (delayedEditComponentKey.value === 'tax') {
    return 'slide-fade-horizontal';
  } else if (delayedEditComponentKey.value === 'settings') {
    return 'static-slide';
  } else {
    return 'fade-up';
  }
});
</script>

<style scoped>
/* Define the fade-up transition */
.fade-up-enter-active, .fade-up-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.fade-up-enter-from, .fade-up-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
.fade-up-enter-to, .fade-up-leave-from {
  opacity: 1;
  transform: translateY(0);
}

/* Define the slide-fade-horizontal transition */
.slide-fade-horizontal-enter-active, .slide-fade-horizontal-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.slide-fade-horizontal-enter-from {
  opacity: 0;
  transform: translateX(100%);
}
.slide-fade-horizontal-enter-to {
  opacity: 1;
  transform: translateX(0);
}
.slide-fade-horizontal-leave-from {
  opacity: 1;
  transform: translateX(0);
}
.slide-fade-horizontal-leave-to {
  opacity: 0;
  transform: translateX(100%);
}

/* Define the static-slide transition */
.static-slide-enter-active, .static-slide-leave-active {
  transition: opacity 0.3s ease, transform 0.2s ease;
}
.static-slide-enter-from, .static-slide-leave-to {
  opacity: 0;
  transform: translateX(0);
  transform: translateY(-10px); 
}
.static-slide-enter-to, .static-slide-leave-from {
  opacity: 1;
  transform: translateX(0);
  transform: translateY(0);
}

</style>