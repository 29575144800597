<template>
    <div ref="cardOuter" class="card-outer bg-white mt-2 md:mt-4 max-h-full flex-1 overflow-x-auto shadow ring-1 ring-black ring-opacity-5 rounded-lg w-full min-w-48 xl:max-w-64 2xl:max-w-96">
      <div @click="toggleTable" class="relative z-10 cursor-pointer" :class="targetDetails.headerColor">
        <div class="inline-flex justify-between w-full items-center py-3 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 border-b border-gray-200">
                <div class="flex items-center" >
                    <component :is="targetDetails.component" :class="targetDetails.iconColor" class="h-6 w-6 mr-2"></component>
                    <span>Targets</span>
                </div>
                <span v-if="targetDetails.totalValue !== undefined">{{ $formatNumber(targetDetails.totalValue) }}</span>
        </div>
      </div>
      <Transition name="fade">
        <div v-if="isTableVisible" class="relative z-0 w-full divide-y divide-gray-300 transition-all duration-300 ease-in-out bg-white card-inner-element">
          <!-- Flex container for each item -->
          <div v-for="item in targetDetails.group" :key="item.id" @click="dialogStore.openDialog('edit', targetDetails.type, item.id)"  >
              <div  class="flex items-center hover:bg-gray-50 cursor-pointer ">
                  <!-- LEFT Flex item for name and year range -->
                  <div class="flex flex-grow w-1/2 truncate py-4 pl-3 pr-1.5 text-sm font-medium text-gray-900">
                      {{ item.name }}
                      <InformationCircleIcon v-if="item.tooltip" class="h-5 w-5 ml-2 text-gray-400" v-tooltip="{content: item.tooltip,  theme: 'floating-vue-theme'}"/>

                  </div>
                  <!-- RIGHT Flex item container for value and additional details -->
                  <div class="flex flex-col justify-end py-2 pr-3 pl-1.5 text-sm text-gray-500">
                    <!-- Top row for the value -->
                    <div class="flex items-center flex-row text-gray-900 self-end">
                          <div class="flex-1 pr-[4px]">{{ targetDetails.currency || item.type === 'financial goal' ? $formatNumber(item.value) : item.value }}</div>
                            <!-- Flex item for modifier, if present -->
                        <div v-if="item.type === 'age'" class="text-gray-500 text-xs text-right align-bottom">
                            {{ item.modifier }}
                        </div>
                    </div>          

                    <!-- Bottom row for arrows icon and growth rate, conditionally displayed -->
                                                          
                  </div>
              </div>
           </div>

          <!-- Adjusted section for Add button -->
          <div class="flex justify-between items-center py-1 px-2 text-sm font-medium text-gray-600">
              <div class="flex justify-end w-full">                        
                  <button @click="dialogStore.openDialog('add', targetDetails.type, null)" class="inline-flex items-center gap-x-1.5 rounded bg-indigo-50 px-2 py-1 text-xs font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100">
                    <PlusIcon class="h-4 w-4" aria-hidden="true" />
                      <span>Add</span>
                  </button>
              </div>
          </div>
      </div>
    </Transition>
    </div>
  </template>

<script setup>
import { PlusIcon, FlagIcon } from '@heroicons/vue/24/solid';
import { ref, watch, nextTick, computed } from 'vue';
import { useDialogStore } from '@/stores/DialogStore';
import { usePlanStore } from '@/stores/PlanStore';

import { InformationCircleIcon } from '@heroicons/vue/24/outline'

const dialogStore = useDialogStore();
const planStore = usePlanStore();

const targetDetails = computed(() => {
    return { type: 'targets', component: FlagIcon, iconColor: 'text-indigo-600', headerColor:'bg-slate-100 hover:bg-slate-50', group: planStore.getCurrentPlan.targets, currency: false, }; 
});

const isTableVisible = ref(true);
const cardOuter = ref(null); // Reference to the card outer wrapper

const toggleTable = () => {
    isTableVisible.value = !isTableVisible.value;
    nextTick(() => {
        adjustCardHeight();
    });
};

// Watch for changes in targetDetails.group array
watch(() => targetDetails.value.group, () => {
  // Ensure changes are rendered before adjusting the height
  nextTick(() => {
    adjustCardHeight();
  });
}, { deep: true }); // Use deep watch to detect changes within the array

const adjustCardHeight = () => {
  if (!cardOuter.value || !targetDetails.value.group) return;

  nextTick(() => {
    let fullHeight = 0;

    if (isTableVisible.value) {
      // Calculate the height based on the actual rendered height of the inner elements
      const cardInnerElements = cardOuter.value.querySelectorAll('.card-inner-element'); // Replace with the appropriate class or selector

      cardInnerElements.forEach(element => {
        fullHeight += element.offsetHeight; // Accumulate the height of each inner element        
      });

      fullHeight += 50; // Additional height for non-compact mode

      // Transition to the calculated full height
      cardOuter.value.style.height = fullHeight + 'px';
    } else {
      // Set height to collapsed state when table is not visible
      cardOuter.value.style.height = '49px';
    }
  });
};


// Initial adjustment in case the group array is not empty at mount
nextTick(() => {
  adjustCardHeight();
});

</script>

<style scoped>
.card-outer {
    /* transition: max-height 0.5s linear; */
    transition: height 0.2s ease;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;  
  transform: translateY(-30%);
}

</style>