<template>
  <div ref="cardOuter" class="card-outer bg-white mt-2 md:mt-4 overflow-hidden flex-1 overflow-x-auto shadow ring-1 ring-black ring-opacity-5 rounded-lg md:min-h-[32dvh] max-h-full w-full min-w-full md:min-w-96 2xl:min-w-[600px]">
       <div class="relative z-10 cursor-pointer bg-slate-100 hover:bg-slate-50">
          <div class="inline-flex justify-between w-full gap-4 items-center py-3 2xl:py-3 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 border-b border-gray-200">
                <div class="flex items-center text-sm leading-tight" >     
                  <ChartPieIcon class="inline h-5 w-5 md:h-6 md:w-6 mr-1 md:mr-2 text-indigo-600"/>
                    <div class="items-center" style="flex: 1 0 0;" ><span>Discretionary income               allocation</span><span><InformationCircleIcon class="inline text-gray-400 h-5 w-5 mx-1" v-tooltip="{content: 'Where you put your discretionary income or lazy money, which is income after expenses and debt repayments',  theme: 'floating-vue-theme'}"/></span>                      
                    </div>
     
                </div>

                <div class="flex flex-row gap-4">

                  <span class="text-sm font-medium text-gray-900">%</span>
                    <SwitchGroup as="div" class="flex items-center">
                      <Switch v-model="userProfile.isAllocationAmountSelected" class="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2">                        
                        <span aria-hidden="true" class="pointer-events-none absolute h-full w-full rounded-md bg-white" />
                        <span aria-hidden="true" :class="[userProfile.isAllocationAmountSelected ? 'bg-indigo-600' : 'bg-gray-200', 'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out']" />
                        <span aria-hidden="true" :class="[userProfile.isAllocationAmountSelected ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out']" />
                    </Switch>
                    </SwitchGroup>
                  <span class="text-sm font-medium text-gray-900">{{ planStore.getCurrentPlan.currency }}</span>

                  <!-- <ArrowPathIcon @click="planStore.updateAllocations()" v-tooltip="{content: 'Refresh allocations',  theme: 'floating-vue-theme'}" class="h-4 w-4 text-gray-600 hover:text-indigo-600"></ArrowPathIcon> -->
                  <TrashIcon @click="planStore.initializeAllocations()" v-tooltip="{content: 'Reset allocations equally',  theme: 'floating-vue-theme'}" class="h-4 w-4 text-gray-600 hover:text-indigo-600"></TrashIcon>
                  <!-- <ArrowsPointingOutIcon @click="dialogStore.openDialog('edit','allocation','null')" v-tooltip="{content: 'Open in window',  theme: 'floating-vue-theme'}" class="h-4 w-4 text-gray-600 hover:text-indigo-600"></ArrowsPointingOutIcon>  -->
                  
                </div>
            </div>
        </div>

      <div class="px-2 h-full">
        <PlanSavingsAllocationChart id="card-chart"/>
      </div>      
  </div>
</template>
  
<script setup>
import { usePlanStore } from '@/stores/PlanStore';
// import { useDialogStore } from '@/stores/DialogStore';
import { ChartPieIcon, TrashIcon } from '@heroicons/vue/24/solid'  
import { InformationCircleIcon } from '@heroicons/vue/24/outline'
import  PlanSavingsAllocationChart  from '@/components/PlanSavingsAllocationChart'
import { useUserProfileStore } from '@/stores/UserProfile';
import { Switch, SwitchGroup } from '@headlessui/vue'

// const dialogStore = useDialogStore();
const planStore = usePlanStore();
const userProfile = useUserProfileStore();

</script>
  
<style scoped>
  .card-outer {
    position: relative;
  }
</style>
  