<template>
    <div>
        <button type="button" class="mb-2 inline-flex items-center gap-x-2 rounded-md bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" @click="dialogStore.selectedItemType='settings'">
            <ChevronLeftIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
            Back 
        </button>
        <div class="bg-white p-2 rounded-lg shadow">
            <div v-for="(section, sectionName) in sections" :key="sectionName" class="mb-6">
                <h2 class="font-semibold text-lg mb-2">{{ formatTitle(sectionName) }}</h2>
                <div v-if="Array.isArray(section)" class="flex flex-col space-y-2">
                <!-- Handle array sections like National Insurance -->
                <div v-for="(item, index) in section" :key="index" class="flex justify-between border-b pb-2">
                    <span>{{ formatTitle(`Band ${item.band}`) }}:</span>
                    <span>{{ formatItemContent(item) }}</span>
                </div>
                </div>
                <div v-else class="flex flex-col space-y-2">
                <!-- Handle object sections like Income Tax Rates and Student Loan Repayment Plans -->
                <div v-for="(value, key) in section" :key="key" class="flex justify-between border-b pb-2 text-sm">
                    <span>{{ formatTitle(key) }}:</span>
                    <span>{{ formatValue(value) }}</span>
                </div>
                </div>
            </div>
            </div>
    </div>
</template>
  
  <script setup>
  import { useTaxStore } from '@/stores/TaxStore'; // replace with your store import
  import { ChevronLeftIcon } from '@heroicons/vue/20/solid'
  import { useDialogStore } from '@/stores/DialogStore'

  const dialogStore = useDialogStore();
  const taxStore = useTaxStore();
  
  // Dynamically pick sections from the store
  const sections = {
    incomeTaxRates: taxStore.getIncomeTaxRates,
    incomeTaxThresholds: taxStore.getIncomeTaxThresholds,
    capitalGainsTaxRates: taxStore.getCapitalGainsTaxRates,
    nationalInsurance: taxStore.getNationalInsuranceRates,
    studentLoanRepaymentPlans: taxStore.getStudentLoanRepaymentPlans,
  };
  
  // Function to format titles
  const formatTitle = (title) => {
    return title
      .replace(/([A-Z])/g, ' $1')  // Insert space before capital letters
      .replace(/^./, str => str.toUpperCase());  // Capitalize the first letter
  };
  
  // Function to format values (like appending '%' or '£')
  const formatValue = (value) => {
    return typeof value === 'number' ? `${value}%` : value;
  };
  
  // Function to format array item content
  const formatItemContent = (item) => {
    if (item.thresholdBottom !== undefined && item.threshold !== undefined) {
      return `${item.rate}% (Threshold: £${item.thresholdBottom} - ${item.threshold ? '£' + item.threshold : 'above'})`;
    }
    return `${item.rate}%`;
  };
  </script>
  
  <style scoped>
  /* Optional: Add any additional styling if needed */
  </style>
  