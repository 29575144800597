import { defineStore } from 'pinia'

export const useSavingsStore = defineStore('savings', {
  state: () => ({
    items: [
      {  value: 500, id: '1', name: 'Current account', growthRate: 1, startYear: 'Plan start', endYear:'Plan end', taxable:true, currency: '£', date: '2023-01-01', isPension: false, includeInAllocation: true, fundFee: 0, pensionTake25: {active:false, account: {}}},
      {  value: 500, id: '2', name: 'Savings account', growthRate: 'Match inflation', startYear: 'Plan start', endYear:'Plan end', taxable:true, currency: '£', date: '2023-01-01', isPension: false, includeInAllocation: true, fundFee: 0, pensionTake25: {active:false, account: {}}},
    ],
    selectedItem: null, // Single item object for editing
  }),
  getters: {
    // Add getters here if needed, for example, to get total value of assets
    totalValue: (state) => state.items.reduce((acc, item) => acc + (+item.value), 0),
  },
  actions: {
    selectItem(item) {
      this.selectedItem = item;
    },
    clearSelectedItem() {
      this.selectedItem = null;
    },
    addItem(item) {
      // Assuming `item` is an object with { name, value }
      // Generate a simple ID (for example purposes; consider a more robust approach for production)
      const newId = this.items.length > 0 ? Math.max(...this.items.map(a => a.id)) + 1 : 1;
      this.items.push({ ...item, id: newId });
    },
    deleteItem(itemId) {
      const index = this.items.findIndex(item => item.id === itemId);
      if (index !== -1) {
          this.items.splice(index, 1);
      }
    },
    updateItem(itemId, field, newValue) {
      const itemIndex = this.items.findIndex(item => item.id === itemId);      
      if (itemIndex !== -1) {
        this.items[itemIndex][field] = newValue;
      }
    },
    getSchema2() {
      return {
        // Define your schema properties here
        // For example:
        name: '',
        value: 0,
        startYear: 'Plan start', // Set default value if needed
        endYear: 'Plan end', // or null for indefinite
        growthRate: 0, // Annual increase in percentage
        taxable: true,      
        contribution: 'None',
        contributionAmount: 0,      
        id: null,
        currency: '£',
        fundFee: 0,
        isPension: false,
        pensionTake25: {active: false, account: {}},
        includeInAllocation: true,
        // Add more properties as needed
      };
    },    
  },
  persist: true,
});
