// src/utils/colors.js

// export const chartColors = [
//     '#0ea5e9',
//     '#facc15',
//     '#2dd4bf',    
//     '#C084FC',
//     '#F59E0B',
//     '#67E8F9',
//     '#FB7185',
//   ];
  
export const chartColors = [
  "#2563eb",
  "#9c54dd",
  "#d940c3",
  "#ff34a0",
  "#ff427b",
  "#ff6256",
  "#ff8532",
  "#ffa600"
];