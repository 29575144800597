<template>
    <TransitionRoot as="template" :show="dialogStore.profileOpen">
      <Dialog class="relative z-20" @close="closeDialog()">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
        
                    <DialogTitle as="h1" class="mb-3">Profile settings</DialogTitle>
                        <form @submit.prevent="saveChanges">

                          <div>
                            <label for="name" class="block text-sm font-medium leading-6 text-gray-900">Name</label>
                            <div class="mt-2">
                                <input type="text" name="name" id="name" class="block w-48 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="dialogStore.selectedItem.name" />
                            </div>
                          </div>
                          <div class="mt-4">
                            <label for="year" class="block text-sm font-medium leading-6 text-gray-900">Birth year</label>
                            <div class="mt-2">
                                <input type="number" name="birthYear" id="birthYear" min="1900" max="new Date().getFullYear()" class="block w-32 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="1998" v-model="dialogStore.selectedItem.birthYear" />
                            </div>
                          </div>
            
                            <div class="mt-5 sm:mt-6 flex flex-row gap-3">
                                <button type="button" class="flex-1 justify-center rounded-md bg-white p-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="closeDialog()" ref="cancelButtonRef">Cancel</button>
                                <button type="submit" class="flex-1 justify-center rounded-md bg-indigo-600 p-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-300">Save</button>
                            </div>
                        </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>

      </Dialog>
    </TransitionRoot>
  </template>
  
  <script setup>
  // import { onMounted } from 'vue'
  import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
//   import { CheckIcon } from '@heroicons/vue/24/outline'
  import { useDialogStore } from '../stores/DialogStore';  
  import { usePlanStore } from '@/stores/PlanStore';
  import { useUserProfileStore } from '@/stores/UserProfile';
  const userProfileStore = useUserProfileStore();
  const planStore = usePlanStore();
  const dialogStore  = useDialogStore();

  const closeDialog = () => {       
      dialogStore.profileOpen = false;
      dialogStore.closeDialog();
    };

  const saveChanges = () => {     
    userProfileStore.birthYear = dialogStore.selectedItem.birthYear;
    userProfileStore.name = dialogStore.selectedItem.name;
    closeDialog();
    planStore.updateProjectionData();    
    
  }
  
  </script>