<template>
  <div class="flex flex-wrap gap-2">
    <div class="flex flex-row items-end gap-2 w-2/3">
      <div class="flex flex-col">
        <div class="flex items-center">
                <ArrowsUpDownIcon class="h-4 w-4 mr-1 text-slate-600" aria-hidden="true"/>
                <label for="growthRate" class="block text-sm font-medium leading-6 text-gray-900">Growth Rate</label>
        </div>    
      
        <Menu as="div" class="relative inline-block text-left truncate">
          <Float  portal enter="transition duration-200 ease-out" enter-from="scale-95 opacity-0" enter-to="scale-100 opacity-100" leave="transition duration-150 ease-in" leave-from="scale-100 opacity-100" leave-to="scale-95 opacity-0" tailwindcss-origin-class>
            <MenuButton class="flex w-full justify-center gap-x-1.5 align-middle rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              <component :is="selectedOption.icon" class="mt-0.5 h-4 w-4"></component>{{ selectedOption.label }}
              <ChevronDownIcon class=" h-5 w-5 text-gray-400" aria-hidden="true" />
            </MenuButton>
            <MenuItems class=" z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div class="py-1">
                <MenuItem v-for="option in options" :key="option.value">
                  <a href="#" class='group flex items-center px-4 py-2 text-sm hover:bg-gray-100' @click="selectOption(option.value, option.label, option.icon)" :class="[growthActive(option.label) ? 'text-indigo-600' : 'text-gray-700']">
                    <component :is="option.icon" class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" :class="[growthActive(option.label) ? 'text-indigo-600 group-hover:text-indigo-600' : 'text-gray-400']" aria-hidden="true" />
                    {{ option.label }}
                    <CheckIcon v-if="growthActive(option.label)" class="ml-auto h-5 w-5 text-indigo-500 group-hover:text-indigo-500" aria-hidden="true"/>
                  </a>
                </MenuItem>
              </div>
            </MenuItems>
        </Float>
        </Menu>
      </div>

      <div v-if="selectedOption.label === '% Change'" class="relative w-24">
        <input type="number" min="-100" max="100"  step=".01" v-model="dialogStore.selectedItem.growthRate" placeholder="Enter value" :class="{ 'text-red-900 ring-1 ring-inset ring-red-300 focus-within:ring-2 focus-within:ring-red-500': !dialogStore.isFieldValid('growthRate'), 'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6': true, 'pl-6': selectedOption.label === 'Decrease' }" @blur="validateField(dialogStore.selectedItem.growthRate, 'growthRate', dialogStore)" />
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span class="text-gray-500 sm:text-sm">%</span>
        </div>
      </div>
    </div>
  

    <div class="flex flex-col" v-if="dialogStore.selectedItemType==='savings'">
      <label for="fundFee" class="block text-sm font-medium leading-6 text-gray-900">Account or fund fee</label>
      <div class="relative w-24">
        <input type="number" min="0" max="100" step="0.01"  v-model="dialogStore.selectedItem.fundFee" name="fundFee" id="fundFee" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"  />
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span class="text-gray-500 sm:text-sm">%</span>
          </div>
      </div>
    </div>

</div>
</template>
  
<script setup>
import { ref } from 'vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ArrowsUpDownIcon, ScaleIcon, NoSymbolIcon, ChevronDownIcon, ReceiptPercentIcon, CheckIcon } from '@heroicons/vue/20/solid';
import { useDialogStore } from '@/stores/DialogStore';
import { validateField } from '@/utils/validationUtils';

import { Float } from '@headlessui-float/vue';

const dialogStore = useDialogStore();

// Define the options for the dropdown
const options = ref([
  { label: '% Change', value: 3, icon: ReceiptPercentIcon },
  { label: 'Match inflation', value: 'Match inflation', icon: ScaleIcon },
  { label: 'Investment growth rate', value: 'Investment growth rate', icon: ScaleIcon },
  { label: 'None', value: 0, icon: NoSymbolIcon },  
]);

const growthActive = (menuItem) => {
    if (typeof dialogStore.selectedItem.growthRate === "number" && menuItem === "None" && dialogStore.selectedItem.growthRate === 0) {
        return true;
    }
    if (typeof dialogStore.selectedItem.growthRate === "number" && menuItem === "% Change" && dialogStore.selectedItem.growthRate >0) {
        return true;
    }
    else return dialogStore.selectedItem.growthRate === menuItem;
};

// Define the selected option
const selectedOption = ref(options.value.find(option => option.value === dialogStore.selectedItem.growthRate) || options.value[0]); // Initialize with the first option as fallback

// Function to select an option
const selectOption = (value, label, icon) => {
  dialogStore.setDirtyField('growthRate', true);
  if (value === 'Match inflation' || value === 0) {
      dialogStore.setErrorField('growthRate',false);
  }
  selectedOption.value = { value, label, icon };
  dialogStore.selectedItem.growthRate = value;
};
</script>

<style>
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0; 
  }
</style>
  