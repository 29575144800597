import { defineStore } from 'pinia';

export const useExpensesStore = defineStore('expenses',{
  id: 'expenses',
  state: () => ({
    items: [{
        id: '1',
        name: 'Living expenses',
        value: 2000,
        startYear: 'Plan start',
        endYear: 'Plan end', // or null for indefinite
        growthRate: 'Match inflation', // Annual increase in percentage
        currency: '£',
        frequency: 'monthly',
        fundingAccount: 'Disposable income',
      }
      ],
  }),
  getters: {
    // Add getters here if needed, for example, to get total value of assets
    totalValue: (state) => state.items.reduce((acc, item) => acc + (+item.value), 0),
  },
  actions: {
    addItem(item) {
      this.items.push(item);
    },
    removeItem(ItemId) {
      this.Items = this.Items.filter(Item => Item.id !== ItemId);
    },
    updateItem(updatedItem) {
      const index = this.Items.findIndex(Item => Item.id === updatedItem.id);
      if (index !== -1) {
        this.Items.splice(index, 1, updatedItem);
      }
    },
    getSchema2() {
      return {
        // Define your schema properties here
        // For example:
        name: '',
        value: 0,
        startYear: 'Plan start', // Set default value if needed
        endYear: 'Plan end', // or null for indefinite
        growthRate: 0, // Annual increase in percentage
        id: null,
        currency: '£',
        frequency: 'monthly',
        fundingAccount: 'Disposable income',
        // Add more properties as needed
      };
    },    
    
  },
});
