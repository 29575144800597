import { defineStore } from 'pinia';

export const usePhysicalAssetsStore = defineStore('physicalAssets', {
  state: () => ({
    items: [
      { id: 1, name: 'Car', initialValue: 5000, value: 5000, startYear:2025, endYear: 2031, growthRate: -3, currency: '£', date: '2023-01-01'},
  
      // Add more predefined items here
    ],
  }),
  getters: {
    // Add getters here if needed, for example, to get total value of items
    totalValue: (state) => state.items.reduce((acc, item) => acc + (+item.value), 0),
  },
  actions: {
    addItem(item) {
      // Assuming `item` is an object with { name, value }
      // Generate a simple ID (for example purposes; consider a more robust approach for production)
      const newId = this.items.length > 0 ? Math.max(...this.items.map(a => a.id)) + 1 : 1;
      this.items.push({ ...item, id: newId });
    },
    updateItem(itemId, field, newValue) {
      const itemIndex = this.items.findIndex(item => item.id === itemId);
      if (itemIndex !== -1) {
        this.items[itemIndex][field] = newValue;
      }
    },
    deleteItem(itemId) {
      const index = this.items.findIndex(item => item.id === itemId);
      if (index !== -1) {
          this.items.splice(index, 1);
      }
    },
    getSchema2() {
      return {
        // Define your schema properties here
        // For example:
        name: '',
        value: 0,
        initialValue: 0,
        startYear: null, // Set default value if needed        
        endYear: null,
        growthRate: 0, // Annual increase in percentage        
        id: null,
        currency: '£',
        // Add more properties as needed
      };
    },    
  },
  persist: true,
});
