<template>
    <div ref="cardOuter" class="card-outer bg-white mt-2 md:mt-4 max-h-full overflow-hidden flex-1 overflow-x-auto shadow ring-1 ring-black ring-opacity-5 rounded-lg">
      <div @click="toggleTable"  class="relative z-10 cursor-pointer" :class="cardDetails.headerColor">
        <div class="inline-flex justify-between w-full items-center py-3 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 border-b border-gray-200">
                <div class="flex items-center" >
                    <component :is="cardDetails.component" :class="cardDetails.iconColor" class="h-6 mr-2"></component>
                    <span class="truncate">{{ cardTitle }}</span>
                </div>
                <span v-if="cardDetails.totalValue !== undefined">{{ $formatNumber(cardDetails.totalValue) }}</span>
        </div>
      </div>
      <Transition name="fade">
      <div v-if="isTableVisible" class="relative w-full z-0 divide-y divide-gray-300 transition-all duration-300 ease-in-out bg-white">
        <!-- Table content -->
                <div v-for="item in cardDetails.group" :key="item.id" @click="dialogStore.openDialog('edit', cardDetails.type, item.id)">
                    <div  class="flex justify-between hover:bg-gray-50 cursor-pointer ">
                        <div class="truncate py-3 pl-3 pr-3 text-sm font-medium text-gray-900 ">{{ item.name }}</div>
                        <div class="px-3 py-3 text-sm text-gray-500 text-right">{{ cardDetails.currency ? $formatNumber(item.value) : item.value }}</div>
                    </div>
                </div>

                <div class="flex justify-between items-center py-2 px-2 text-sm font-medium text-gray-600">
                    <div class="flex justify-end w-full">                        
                        <button @click="dialogStore.openDialog('add', cardDetails.type, null)" class="inline-flex items-center gap-x-1.5 rounded bg-indigo-50 px-2 py-1 text-xs font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100">
                            <PlusIcon class="h-4 w-4" aria-hidden="true" />
                            <span>Add</span>
                        </button>
                    </div>
                </div>
        </div>
    </Transition>
    </div>
  </template>

<script setup>
import { CreditCardIcon, CurrencyDollarIcon, KeyIcon, InboxArrowDownIcon, ShoppingCartIcon, PlusIcon } from '@heroicons/vue/24/solid'   
import { defineProps, computed, ref, nextTick, watch } from 'vue';

import { usePhysicalAssetsStore } from '@/stores/PhysicalAssetsStore.js';
import { useSavingsStore } from '@/stores/SavingsStore.js';
import { useDebtStore } from '@/stores/DebtStore.js';
import { useIncomeStore } from '@/stores/IncomeStore';
import { useExpensesStore } from '@/stores/ExpensesStore';
import { useDialogStore } from '@/stores/DialogStore';

const physicalAssetsStore = usePhysicalAssetsStore();
const savingsStore = useSavingsStore();
const debtStore = useDebtStore();

const incomeStore = useIncomeStore();
const expensesStore = useExpensesStore();

const dialogStore = useDialogStore();

const props = defineProps({
    cardTitle: String,
});

const isTableVisible = ref(true);
const cardOuter = ref(null); // Reference to the card outer wrapper

const cardDetails = computed(() => {       
    switch (props.cardTitle) {
        case 'Savings': {
            const totalValue = savingsStore.totalValue;
            return { type: 'savings', component: CreditCardIcon, iconColor: 'text-violet-700', headerColor:'bg-slate-100 hover:bg-slate-50', totalValue: totalValue, group: savingsStore.items, currency: true, }; 
        }
        case 'Debt': {
        const totalValue = debtStore.totalValue;
            return { type: 'debt', component: CurrencyDollarIcon, iconColor: 'text-blue-600', headerColor:'bg-slate-100 hover:bg-slate-50', totalValue: totalValue, group: debtStore.items, currency: true, }; 
        }

        case 'Physical assets': {
            const totalValue = physicalAssetsStore.totalValue;
            return { type: 'physical assets', component: KeyIcon, iconColor: 'text-yellow-500', headerColor:'bg-slate-100 hover:bg-slate-50', totalValue: totalValue, group: physicalAssetsStore.items, currency: true, }; 
        }

        case 'Income':
            return { type: 'income', component: InboxArrowDownIcon, iconColor: 'text-green-600', headerColor:'bg-green-50', group: incomeStore.items, currency: true, };                 
        
        case 'Expenses':
            return { type: 'expenses', component: ShoppingCartIcon, iconColor: 'text-red-600', headerColor:'bg-red-50', group: expensesStore.items, currency: true, }; 

        default:
            return { component: null, headerColor:'bg-gray-50', iconColor: 'text-gray-500', currency: true, }; // Default or fallback
    }
});

const toggleTable = () => {
    isTableVisible.value = !isTableVisible.value;
    nextTick(() => {
        adjustCardHeight();
    });
};

// Watch for changes in cardDetails.group array
watch(() => cardDetails.value.group, () => {
  // Ensure changes are rendered before adjusting the height
  nextTick(() => {
    adjustCardHeight();
  });
}, { deep: true }); // Use deep watch to detect changes within the array

const adjustCardHeight = () => {
  if (!cardOuter.value || !cardDetails.value.group) return;
  if (isTableVisible.value) {
    // Dynamically calculate full height based on the number of items
    const fullHeight = 49 + cardDetails.value.group.length * 45 + 41; // Adjust the calculation as needed
    // Transition to the calculated full height
    nextTick(() => {
      cardOuter.value.style.height = fullHeight + 'px';
    });
  } else {
    // Set height to collapsed state when table is not visible
    cardOuter.value.style.height = '49px';
  }
};

// Initial adjustment in case the group array is not empty at mount
nextTick(() => {
  adjustCardHeight();
});
</script>

<style scoped>
.card-outer {
    /* transition: max-height 0.5s linear; */
    transition: height 0.2s ease;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;  
  transform: translateY(-30%);
}
</style>