<template>
    <div>
      <div class="flex items-top gap-2">
          <div class="flex-grow">
            <div :class="{ 'text-red-900 ring-1 ring-inset ring-red-300 focus-within:ring-2 focus-within:ring-red-500': !dialogStore.isFieldValid('name')}" class="flex-grow rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                <label  :class="{ 'text-red-900': !dialogStore.isFieldValid('name')}" class="block text-xs font-medium text-gray-500">Name</label>
                <input type="text" name="name" id="name-{{ index }}" v-model="dialogStore.selectedItem.name" class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 invalid:text-red-900 invalid:ring-1 invalid:ring-inset invalid:ring-red-300" @blur="validateField(dialogStore.selectedItem.name, 'name', dialogStore)" />
            </div>
            <div class="h-4 mt-1"><p v-if="!dialogStore.isFieldValid('name')" class="text-xs text-red-600" id="email-error">Not a valid name</p></div>
          </div>
          <div class="flex-grow">
            <div :class="{ 'text-red-900 ring-1 ring-inset ring-red-300 focus-within:ring-2 focus-within:ring-red-500': !dialogStore.isFieldValid('value')}" class="relative flex-grow rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                <label :class="{ 'text-red-900': !dialogStore.isFieldValid('value')}" class="block text-xs font-medium text-gray-500">Value</label>
                <div class="pointer-events-none absolute top-7 left-0 flex items-center pl-3">
                  <span class="text-gray-500 sm:text-sm">{{planStore.getCurrentPlan.currency}}</span>
                </div>
                <input type="number" step=".01" name="value" id="value-{{ index }}" v-model.number="dialogStore.selectedItem.value" class="block w-full border-0 p-0 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" @blur="validateField(dialogStore.selectedItem.value, 'value', dialogStore)"/>
            </div>
            <div class="h-4 mt-1"><p v-if="!dialogStore.isFieldValid('value')" class="text-xs text-red-600" id="email-error">Not a valid value</p></div>
          </div>
      </div>  
      <div class="flex-col items-center gap-2 p-2 bg-gray-100 rounded-md">
        <PlanDropdownDate />
      </div>
       
      <div class="mt-3">  
        <div><PlanDropdownGrowth /></div>
      </div>

      <div class="flex flex-row gap-4 pl-1 border-t border-slate-200 mt-4">

        <div class="flex flex-col w-1/2">
          <div  class="relative flex flex-1 items-start mt-4  ">
            <div class="flex h-6 items-center">
              <input id="pension" aria-describedby="pension-description" name="pension" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" v-model="dialogStore.selectedItem.isPension"/>
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="pension" class="font-medium text-gray-900">Pension</label>
              <p id="pension-description" class="text-gray-500 text-xs">This account is a pension account.</p>
            </div>
          </div>
          <div class="relative flex items-start mt-4 pl-4" v-if="dialogStore.selectedItem.isPension">
              <div class="flex h-6 items-center">
                <input id="take25" aria-describedby="take25-description" name="take25" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" v-model="dialogStore.selectedItem.pensionTake25.active"/>
              </div>
              <div class="ml-2 text-xs leading-4">
                <label for="take25" class="font-medium text-gray-900">Take 25% tax free at normal pension age</label>
                <!-- <p id="taxable-description" class="text-gray-500 tex-xs"></p> -->
              </div>
          </div>
          
          <div v-if="dialogStore.selectedItem.isPension && dialogStore.selectedItem.pensionTake25?.active">
            <div class="w-full flex flex-col ml-10 mt-2">
              <label class="text-xs font-normal text-gray-500">Which account does this pay into?</label>
              <Menu as="div" class="relative inline-block text-left">
                    <Float portal enter="transition duration-200 ease-out" enter-from="scale-95 opacity-0" enter-to="scale-100 opacity-100" leave="transition duration-150 ease-in" leave-from="scale-100 opacity-100" leave-to="scale-95 opacity-0" tailwindcss-origin-class>
                      <div>
                        <MenuButton class="inline-flex max-w-72 justify-between text-left gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                          {{ dialogStore.selectedItem?.pensionTake25?.account?.name || "Select account" }}
                          <ChevronDownIcon class="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                        </MenuButton>
                      </div>

                      <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                        <MenuItems class="absolute left-0 z-10 mt-2 w-72 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div class="py-1">
                            <MenuItem as="div" v-for="option in nonPensionAccounts" :key="option.id" v-slot="{ active }">
                              <a href="#" @click="selectPensionTake25Option(option)" :class="['group flex items-center px-4 py-2', active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm', dialogStore.selectedItem.pensionTake25?.account?.id === option.id ? 'text-indigo-600 group-hover:text-indigo-600' : 'text-gray-400']">
                                <PlusCircleIcon v-if="option.id ==='create'" class="h-5 w-5 text-indigo-500 group-hover:text-indigo-500 mr-2"/>
                                {{ option.name }}
                                <CheckIcon v-if="dialogStore.selectedItem.pensionTake25?.account?.id === option.id" class="ml-auto h-5 w-5 text-indigo-500 group-hover:text-indigo-500" aria-hidden="true"/>
                              </a>
                            </MenuItem>
                          </div>
                        </MenuItems>
                      </transition>
                    </Float>
                </Menu>
              </div>

          </div>
        </div>

          <div class="relative flex items-start mt-4 w-1/2 pl-1">
            <div class="flex h-6 items-center">
              <input id="includeInAllocation" aria-describedby="includeInAllocation-description" name="includeInAllocation" type="checkbox" class="h-4 w-4 rounded border-gray-300 focus:ring-indigo-600" :class="{'text-gray-300': dialogStore.isDeleteButtonDisabled, 'text-indigo-600': !dialogStore.isDeleteButtonDisabled}"  v-model="dialogStore.selectedItem.includeInAllocation" :disabled="isAllocationDisabled && dialogStore.selectedItem.includeInAllocation" v-tooltip="allocationTooltip"    />
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="includeInAllocation" class="font-medium text-gray-900">Include in allocation</label>
              <p id="includeInAllocation-description" class="text-gray-500 text-xs">Add this to the allocation model</p>
            </div>
          </div>

      </div>
      <div class="flex flex-row gap-4 pl-1 border-t border-slate-200 mt-4 pb-4">

          <div class="relative flex items-start mt-4 w-1/2 pl-1">
            <div class="flex h-6 items-center">
              <input id="tax-relief" aria-describedby="tax-relief-description" name="tax-relief" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" v-model="dialogStore.selectedItem.taxRelief"/>
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="tax-relief" class="font-medium text-gray-900">Tax-relief</label>
              <p id="tax-relief-description" class="text-gray-500 text-xs">You can claim back tax on contributions. For example, this is a SIPP (UK) or 401(k)/IRA (US)</p>
            </div>
          </div>

        <div class="relative flex flex-1  items-start mt-4  ">
          <div class="flex h-6 items-center">
            <input id="tax" aria-describedby="taxable-description" name="taxable" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" v-model="dialogStore.selectedItem.taxableInterest"/>
          </div>
          <div class="ml-3 text-sm leading-6">
            <label for="tax" class="font-medium text-gray-900">Taxable interest</label>
            <p id="tax-description" class="text-gray-500 text-xs">Apply tax to the interest earned (e.g. not an ISA).</p>
          </div>
        </div>

      </div>

      <!-- <div class="mt-3"><div class="flex items-center self-end align-middle"><PlusCircleIcon class="h-4 w-4 mr-1 text-slate-600 " aria-hidden="true"/><label for="contribution" class="block text-sm font-medium leading-6 text-gray-900">Contributions</label></div><div><div class="flex gap-2"><PlanDropdownContributionFrequency /></div></div></div> -->
  
    </div>
  </template>
  
  <script setup>
  import { ref, computed, watch, onMounted } from 'vue';
  import { useDialogStore } from '@/stores/DialogStore';
  import { useSavingsStore } from '@/stores/SavingsStore';
  import { usePlanStore } from '@/stores/PlanStore';
  import { validateField } from '@/utils/validationUtils';
  import PlanDropdownDate from '@/components/PlanDropdownDate.vue'
  import PlanDropdownGrowth from '@/components/PlanDropdownGrowth.vue';
  // import PlanDropdownContributionFrequency from '@/components/PlanDropdownContributionFrequency.vue';

  import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
  import { Float } from '@headlessui-float/vue'

  import { ChevronDownIcon, CheckIcon, PlusCircleIcon  } from '@heroicons/vue/20/solid';
  
  const savingsStore = useSavingsStore();
  const dialogStore = useDialogStore();
  const planStore = usePlanStore();

  //register fields - not sure this is needed
  // Register fields on component mount
  onMounted(() => {
    registerFields();
    getNonPensionAccounts();
    
  });
  
  const registerFields = () => {
    // Call an action or mutation to register fields
    // For example, if you have a registerFields action in your store:
    dialogStore.registerFields(['name', 'value', 'startYear', 'endYear', 'growthRate']);
  }
  
  let nonPensionAccounts;

function getNonPensionAccounts() {
    const currentAccountId = dialogStore.selectedItem?.id; // Get the current account ID from dialogStore

    // Initialize the list with items from savingsStore
    let savingsCopy = savingsStore.items.map((item, index) => ({
      id: item.id,
      name: item.name,
      isPension: item.isPension,
      originalIndex: index // Step 1: Add the original index to each item
    }));

  // Step 2: Overwrite or add items from planStore.getCurrentPlan.savings
  const currentPlan = planStore.getCurrentPlan;

  currentPlan?.savings?.forEach(planSavingsItem => {
    const existingItemIndex = savingsCopy.findIndex(copyItem => copyItem.id === planSavingsItem.id);
    if (existingItemIndex !== -1) {
      // Overwrite the existing item if found, keeping the original index
      savingsCopy[existingItemIndex] = {
        id: planSavingsItem.id,
        name: planSavingsItem.name,
        isPension: planSavingsItem.isPension,
        originalIndex: savingsCopy[existingItemIndex].originalIndex
      };
    } else {
      // Add the new item if not found
      savingsCopy.push({
        id: planSavingsItem.id,
        name: planSavingsItem.name,
        isPension: planSavingsItem.isPension,
        originalIndex: savingsCopy.length
      });
    }
  });

  // Step 3: Filter out non-pension items and the currently viewed account
  nonPensionAccounts = savingsCopy
    .filter(item => !item.isPension && item.id !== currentAccountId)
    .map(({ id, name, isPension }) => ({ id, name, isPension }));

  // Step 4: Add the "Create a new account" option at the end
  nonPensionAccounts.push({
    id: 'create',
    name: "Create a new account",
    isPension: false,
    originalIndex: -1
  });

  return nonPensionAccounts;
}

// Function to handle option selection
const selectPensionTake25Option = (option) => {
  dialogStore.selectedItem.pensionTake25.account = option;

};
  
  if (dialogStore.dialogMode === 'add') {
    // Assuming getSchema2 returns a reactive object
    const reactiveEmptyItem = savingsStore.getSchema2();
    // Convert the reactive object to a plain object
    const emptyItem = JSON.parse(JSON.stringify(reactiveEmptyItem));
    dialogStore.selectedItem = emptyItem;
  }
  
  if (dialogStore.dialogMode === 'edit') {
    // Watch for changes to the selected item ID
    watch(() => dialogStore.selectedItemId, (newId) => {
      
      // Check if the item ID exists in the Store
      const selectedItemInStore = savingsStore.items.find(item => item.id === newId);
  
      // Check if the item ID exists in the individual plan's data
      const currentPlan = planStore.plans.find(plan => plan.id === planStore.currentPlanId);
      const selectedItemInPlan = currentPlan && currentPlan.savings ? currentPlan.savings.find(item => item.id === newId) : null;
  
      // Use the correct store to edit the item
      if (selectedItemInPlan) {
        dialogStore.selectedItem = { ...selectedItemInPlan };
      }
      else if (selectedItemInStore) {
        dialogStore.selectedItem = { ...selectedItemInStore };
      } 
    }, { immediate: true });
  }
  // Watch the isPension property to enable taxRelief when isPension is selected
  watch(
    () => dialogStore.selectedItem.isPension,
    (newVal) => {
      if (newVal && !dialogStore.selectedItem.taxRelief) {
        dialogStore.selectedItem.taxRelief = true;
      }
    }
  );

const isAllocationDisabled = planStore.getActiveSavingsCount === 1 && dialogStore.dialogMode !== 'add';

// Ensure the checkbox remains checked if it is disabled and the count is 1

const includeInAllocationModel = ref(dialogStore.selectedItem.includeInAllocation);

watch(includeInAllocationModel, (newValue) => {
  if (isAllocationDisabled.value && !newValue) {
    includeInAllocationModel.value = true;
  } else {
    dialogStore.selectedItem.includeInAllocation = newValue;
  }
});

const allocationTooltip = computed(() => {
      return isAllocationDisabled
        ? { content: 'Plans need at least one savings account included in allocation', theme: 'floating-vue-theme' }
        : null;
    });
  
  </script>
