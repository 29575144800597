import { defineStore } from 'pinia';

export const useIncomeStore = defineStore('income',{
  id: 'income',
  state: () => ({
    items: [{
        id: '1',
        name: 'Software Engineer Salary',
        value: 50000,
        startYear: 'Plan start',
        endYear: 'Retirement', // or null for indefinite
        growthRate: 3, // Annual increase in percentage
        taxable: true,
        nationalInsurance: true,
        definedBenefitPension: { active: false, label: 'none', pensionTake25: {active: false, account: null}} ,
        currency: '£',
        frequency: { schedule : 'monthly', gross : 'yearly'},
        employerPension: { active: false, amount: 0, yourContribution: 0, account: null },
      }
      ],
  }),
  getters: {
    // Add getters here if needed, for example, to get total value of assets
    totalValue: (state) => state.items.reduce((acc, item) => acc + (+item.value), 0),
  },
  actions: {
    addItem(item) {
      this.items.push(item);
    },
    removeItem(itemId) {
      this.items = this.items.filter(item => item.id !== itemId);
    },
    updateItem(updatedItem) {
      const index = this.items.findIndex(item => item.id === updatedItem.id);
      if (index !== -1) {
        this.items.splice(index, 1, updatedItem);
      }
    },
    getSchema2() {
      return {
        // Define your schema properties here
        // For example:
        name: '',
        value: 0,
        startYear: 'Plan start', // Set default value if needed
        endYear: 'Plan end', // or null for indefinite
        growthRate: 0, // Annual increase in percentage
        taxable: true,
        nationalInsurance: true,
        definedBenefitPension:  { active: false, label: 'none', type: {label: 'Career average', value: 'careerAverage'}, pensionTake25: {active: false, account: null}},
        id: null,
        currency: '£',
        frequency: { schedule : 'monthly', gross : 'yearly'},
        employerPension: { active: false, amount: 0, yourContribution: 0, account: null },
        // Add more properties as needed
      };
    },    
  },
});
