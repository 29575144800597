<template>
  <div class="">
    <div class="flex flex-col md:flex-row items-top gap-2 ">
        <div class="flex-grow">
          <div :class="{ 'text-red-900 ring-1 ring-inset ring-red-300 focus-within:ring-2 focus-within:ring-red-500': !dialogStore.isFieldValid('name')}" class="flex-grow rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
              <label  :class="{ 'text-red-900': !dialogStore.isFieldValid('name')}" class="block text-xs font-medium text-gray-500 leading-3">Name</label>
              <input type="text" name="name" id="name-{{ index }}" v-model="dialogStore.selectedItem.name" class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 invalid:text-red-900 invalid:ring-1 invalid:ring-inset invalid:ring-red-300" @blur="validateField(dialogStore.selectedItem.name, 'name', dialogStore)" />
          </div>
          <div class="h-4 my-1"><p v-if="!dialogStore.isFieldValid('name')" class="text-xs text-red-600" id="email-error">Not a valid name</p></div>
        </div>
        
        <div class="flex-grow">
          <div :class="{ 'text-red-900 ring-1 ring-inset ring-red-300 focus-within:ring-2 focus-within:ring-red-500': !dialogStore.isFieldValid('value')}" class="relative flex-grow rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
              <label :class="{ 'text-red-900': !dialogStore.isFieldValid('value')}" class="block text-xs font-medium text-gray-500 leading-3">Amount (i.e. gross salary, before tax)</label>
              <div class="pointer-events-none absolute top-6 left-0 flex items-center pl-3">
                <span class="text-gray-500 sm:text-sm">{{dialogStore.selectedItem.currency}}</span>
              </div>
              <input type="number" step=".01" name="value" id="value-{{ index }}" v-model.number="dialogStore.selectedItem.value" class="block w-full border-0 p-0 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" @blur="validateField(dialogStore.selectedItem.value, 'value', dialogStore)"/>

              <div class="absolute bottom-1 right-1 flex items-end">
                <label for="frequency" class="sr-only">Frequency</label>
                <select id="frequency" name="frequency" class="cursor-pointer h-6 w-20 rounded-md border-0 bg-transparent hover:bg-gray-100 py-0 px-1 text-gray-500 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-xs" v-model="dialogStore.selectedItem.frequency.gross">
                  <option v-for="option in frequencyOptions" :key="option.value" :value="option.value">
                    {{ option.label }}
                  </option>
                </select>
              </div>


          </div>
          <div class="h-4 my-1"><p v-if="!dialogStore.isFieldValid('value')" class="text-xs text-red-600" id="email-error">Not a valid value</p></div>
        </div>

    </div>  

    <div class="flex-col items-center gap-2 p-2 bg-gray-100 rounded-md">
      <div class="flex flex-col items-start">
        <div class="flex items-center align-middle">
          <ArrowPathIcon class="h-4 w-4 mr-1 text-slate-600 cursor-pointer hover:text-indigo-500" aria-hidden="true" />
          <label for="growthRate" class="block text-sm font-medium leading-6 text-gray-900">Income/pay frequency</label>
        </div>
        <Menu as="div" class="relative inline-block text-left w-40 mb-2">
          <Float portal :shift="8" enter="transition duration-200 ease-out" enter-from="scale-95 opacity-0" enter-to="scale-100 opacity-100" leave="transition duration-150 ease-in" leave-from="scale-100 opacity-100" leave-to="scale-95 opacity-0" tailwindcss-origin-class>
          <div>
            <MenuButton class="inline-flex justify-between w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              {{ getFrequencyLabel(dialogStore.selectedItem.frequency.schedule) || 'Frequency' }}
              <ChevronDownIcon class="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
            </MenuButton>
          </div>
            <MenuItems
              class="z-10 mt-2 w-64 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div class="py-1">
                <MenuItem v-for="option in frequencyOptions" :key="option.value">
                  <a href="#"  @click="updateFrequencySchedule(option.value)" :class="['group flex items-center px-4 py-2 text-sm hover:bg-gray-50 hover:text-gray-900', dialogStore.selectedItem.frequency.schedule === option.value ? 'text-indigo-600' : 'text-gray-700' ]">
                    {{ option.label }}
                    <CheckIcon
                      v-if="dialogStore.selectedItem.frequency.schedule === option.value"
                      class="ml-auto h-5 w-5 text-indigo-500 group-hover:text-indigo-500"
                      aria-hidden="true"
                    />
                  </a>
                </MenuItem>
              </div>
            </MenuItems>

        </Float>
        </Menu>
      </div>  

      <PlanDropdownDate />
    </div>

    <div class="mt-4">
      <div>
        <PlanDropdownGrowth />
      </div>
    </div>

    <div class="relative flex items-start mt-4 pl-1">
        <div class="flex h-6 items-center">
          <input id="taxable" aria-describedby="taxable-description" name="taxable" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" v-model="dialogStore.selectedItem.taxable"/>
        </div>
        <div class="ml-3 text-sm leading-6">
          <label for="taxable" class="font-medium text-gray-900">Taxable</label>
          <p id="taxable-description" class="text-gray-500 tex-xs">Apply tax to this income</p>
        </div>
    </div>

    <div class="flex flex-col gap-2 mt-4 pl-1 border-t border-slate-200 pt-4 ">
      <div class="relative flex items-start">
          <div class="flex h-6 items-center">
            <input id="employer-pension" aria-describedby="employer-pension" name="employer-pension" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" v-model="dialogStore.selectedItem.employerPension.active"/>
          </div>
          <div class="ml-3 text-sm leading-6">
            <label for="employer-pension" class="font-medium text-gray-900">Employer Pension</label>
            <p id="employer-pension" class="text-gray-500 tex-xs">You receive a pension with this income as a defined contribution.</p>
          </div>
      </div>

      <div class="text-xs leading-6 ml-7" v-if="dialogStore.selectedItem.employerPension.active">
        <div class="flex flex-row">
          <div class="w-1/2">
            <label class="font-medium text-gray-900">Employer contribution</label>
            <div class="relative mb-2 w-32">
              <input type="number" min="0" max="100" step=".1" name="employer-pension-amount" id="employer-pension-amount" class="w-full block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="dialogStore.selectedItem.employerPension.amount" />
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span class="text-gray-500 sm:text-sm">%</span>
              </div>
            </div>
          </div>
        
          <div class="w-1/2">
            <label class="font-medium text-gray-900">Your contribution</label>
            <div class="relative w-32">
              <input type="number" min="0" max="100" step=".1" name="employer-pension-amount" id="employer-pension-amount" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="dialogStore.selectedItem.employerPension.yourContribution" />
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span class="text-gray-500 sm:text-sm">%</span>
              </div>
            </div>
          </div>        
        </div>      
        <div class="w-full flex flex-col">
          <label class="font-medium text-gray-900">Which account does this pay into?</label>
          <Menu as="div" class="relative inline-block text-left">
                <Float portal enter="transition duration-200 ease-out" enter-from="scale-95 opacity-0" enter-to="scale-100 opacity-100" leave="transition duration-150 ease-in" leave-from="scale-100 opacity-100" leave-to="scale-95 opacity-0" tailwindcss-origin-class>
                  <div>
                    <MenuButton class="inline-flex max-w-72 justify-between text-left gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      {{ dialogStore.selectedItem.employerPension.account?.name || "Select account" }}
                      <ChevronDownIcon class="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </MenuButton>
                  </div>

                  <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                    <MenuItems class="absolute left-0 z-10 mt-2 w-72 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div class="py-1">
                        <MenuItem as="div" v-for="option in pensionAccounts" :key="option.id" v-slot="{ active }">
                          <a href="#" @click="selectEmployerPensionAccount(option)" :class="['group flex items-center px-4 py-2', active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm', dialogStore.selectedItem.employerPension.account?.id === option.id ? 'text-indigo-600 group-hover:text-indigo-600' : 'text-gray-400']">
                            <PlusCircleIcon v-if="option.id ==='create'" class="h-5 w-5 text-indigo-500 group-hover:text-indigo-500 mr-2"/>
                            {{ option.name }}
                            <CheckIcon v-if="dialogStore.selectedItem.employerPension.account?.id === option.id" class="ml-auto h-5 w-5 text-indigo-500 group-hover:text-indigo-500" aria-hidden="true"/>
                          </a>
                        </MenuItem>
                      </div>
                    </MenuItems>
                  </transition>
                </Float>
            </Menu>
          </div>

      </div> <!--End employer pension-->
    </div>

    <div class="flex flex-col border-t border-slate-200 mt-4 py-4">
    <div class="relative flex items-start  pl-1">
        <div class="flex h-6 items-center">
          <input id="definedBenefitPension" aria-describedby="definedBenefitPension-description" name="definedBenefitPension" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" v-model="dialogStore.selectedItem.definedBenefitPension.active" @click="calculateDefinedBenefitPensionDetails()"/>
        </div>
        <div class="ml-3 text-sm leading-6">
          <label for="definedBenefitPension" class="font-medium text-gray-900">Defined Benefit Pension</label><InformationCircleIcon v-if="dialogStore.selectedItem.definedBenefitPension.active" class="inline text-gray-400 h-5 w-5 mx-1" v-tooltip="{content: 'We don\'t add this to net worth',  theme: 'floating-vue-theme'}"/>
          <p id="definedBenefitPension-description" class="text-gray-500 tex-xs">You receive a defined benefit pension as part of this income. <span v-if="dialogStore.selectedItem.definedBenefitPension.active">This is an estimation - check with your pension plan for the details, and for avoidance of doubt, use the set amount estimate that they provide.</span></p>
        </div>   
    </div>
    <div v-if="dialogStore.selectedItem.definedBenefitPension.active" class="flex flex-col gap-2 md:gap-4 ml-8 mt-2 ">
      <div class="flex ">
          <Menu as="div" class="relative inline-block text-left">
            <Float portal enter="transition duration-200 ease-out" enter-from="scale-95 opacity-0" enter-to="scale-100 opacity-100" leave="transition duration-150 ease-in" leave-from="scale-100 opacity-100" leave-to="scale-95 opacity-0" tailwindcss-origin-class>
              <div>
                <MenuButton class="inline-flex w-48 justify-between text-left gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  {{ dialogStore.selectedItem.definedBenefitPension.type?.label || "Select type" }}
                  <ChevronDownIcon class="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                </MenuButton>
              </div>

              <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div class="py-1">
                    <MenuItem as="div" v-for="option in definedBenefitMenuOptions" :key="option.value" v-slot="{ active }">
                      <a href="#" @click="selectDefinedBenefitMenuOption(option)" :class="['group flex items-center px-4 py-2', active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm', dialogStore.selectedItem.definedBenefitPension.type?.value === option.value ? 'text-indigo-600 group-hover:text-indigo-600' : 'text-gray-400']">
                        {{ option.label }}
                        <CheckIcon v-if="dialogStore.selectedItem.definedBenefitPension.type?.value === option.value" class="ml-auto h-5 w-5 text-indigo-500 group-hover:text-indigo-500" aria-hidden="true"/>
                      </a>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Float>
        </Menu>

        <div v-if="dialogStore.selectedItem.definedBenefitPension.type?.value === 'setAmount'">
          <div class="w-32 ml-2 relative">
            <input type="number" min="0" step=".01" name="setAmount" id="setAmount" :class="{ 'text-red-900 ring-1 ring-inset ring-red-300 focus-within:ring-2 focus-within:ring-red-500': !dialogStore.isFieldValid('growthRate'), 'block w-full px-3 pl-7 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6':true}" :placeholder="'Amount in ' + planStore.getCurrentPlan.currency" v-model="dialogStore.selectedItem.definedBenefitPension.totalAccrued" @blur="validateField(dialogStore.selectedItem.value, 'definedBenefitPensionSetAmount', dialogStore)"/>
            <div class="pointer-events-none absolute left-0 top-2 flex items-center pl-3">
                <span class="text-gray-500 sm:text-sm">{{planStore.getCurrentPlan.currency}}</span>
            </div>
          </div>
        </div>

        <div v-if="dialogStore.selectedItem.definedBenefitPension.type?.value === 'finalSalary' || dialogStore.selectedItem.definedBenefitPension.type?.value === 'careerAverage'" class="text-xs justify-center items-center text-gray-800 ml-2">
          <InformationCircleIcon class="inline text-gray-400 h-5 w-5 mx-1" v-tooltip="{content: 'Estimated from Salary (final or career average) x Years of<br/> Service x Accrual Rate.  The active index is the yearly growth rate', html: true , theme: 'floating-vue-theme'}"/>  {{ "Estimated to be: " + formatNumber(dialogStore.selectedItem.definedBenefitPension.actualAmount) + " per year, based on a " + duration + " year career." }} 
        </div>

      </div>
      <div class="flex flex-wrap gap-2 md:gap-4 text-xs">
            <div class="grid content-between">
              <label for="employerContribution" class="block  font-medium leading-4 text-gray-900 mt-2">Employer contribution</label>
              <div class="relative w-28 mt-1">
                <input name="yourContribution" type="number" step=".1" min="0" max="100" id="yourContribution" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="10" v-model="dialogStore.selectedItem.definedBenefitPension.employerContribution" />
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <span class="text-gray-500 sm:text-sm">%</span>
                </div>
              </div>
            </div>

            <div class="grid content-between">
              <label for="yourContribution" class="block font-medium leading-4 text-gray-900 mt-2">Your contribution</label>
              <div class="relative w-28">
                <input name="yourContribution" type="number" step=".1" min="0" max="100" id="yourContribution" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="10" v-model="dialogStore.selectedItem.definedBenefitPension.yourContribution" />
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <span class="text-gray-500 sm:text-sm">%</span>
                </div>
              </div>
            </div>

            <div class="grid content-between">
              <label for="age" class="block font-medium leading-4 text-gray-900 mt-2">Age you can take pension</label>
              <div class="w-28 mt-1">
                <input type="number" min="0" max="150" name="age" id="age" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" :placeholder="planStore.getCurrentPlan.settings.statePensionAge || taxStore.statePensionAge" v-model="dialogStore.selectedItem.definedBenefitPension.age"/>
              </div>
            </div>      
        
      </div>


      <div v-if="dialogStore.selectedItem.definedBenefitPension.type?.value !== 'setAmount'" class="flex flex-row gap-4 text-xs">
          <div>
            <label for="accrualRate" class="block font-medium leading-6 text-gray-900">Accrual rate</label>
            <div class="w-32">
              <input name="accrualRate" id="accrualRate" step=".1" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="1/57" v-model="dialogStore.selectedItem.definedBenefitPension.accrualRate" value="1/57" @blur="calculateDefinedBenefitPensionDetails()"/>
            </div>
          </div>
          
          <div>
            <label for="activeIndex" class="block font-medium leading-6 text-gray-900">Active index</label>
            <div class="relative w-32">
              <input name="activeIndex" type="number" step=".1" min="0" max="100" id="activeIndex" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="3.8" v-model="dialogStore.selectedItem.definedBenefitPension.activeIndex" @blur="calculateDefinedBenefitPensionDetails()" />
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span class="text-gray-500 sm:text-sm">%</span>
              </div>
            </div>
          </div>
        </div>

      

      <div class="relative flex items-start mt-4 ">
              <div class="flex h-6 items-center">
                <input id="take25" aria-describedby="take25-description" name="take25" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" v-model="dialogStore.selectedItem.definedBenefitPension.pensionTake25.active"/>
              </div>
              <div class="ml-2 text-sm leading-6">
                <label for="take25" class="font-medium text-gray-900">Take 25% tax free at normal pension age</label>
                <!-- <p id="taxable-description" class="text-gray-500 tex-xs"></p> -->
              </div>
          </div>
          
          <div v-if="dialogStore.selectedItem.definedBenefitPension?.pensionTake25?.active">
            <div class="w-full flex flex-col ml-6 ">
              <label class="text-xs font-normal text-gray-500">Which account does this pay into?</label>
              <Menu as="div" class="relative inline-block text-left">
                    <Float portal enter="transition duration-200 ease-out" enter-from="scale-95 opacity-0" enter-to="scale-100 opacity-100" leave="transition duration-150 ease-in" leave-from="scale-100 opacity-100" leave-to="scale-95 opacity-0" tailwindcss-origin-class>
                      <div>
                        <MenuButton class="inline-flex max-w-72 justify-between text-left gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                          {{ dialogStore.selectedItem?.definedBenefitPension?.pensionTake25?.account?.name || "Select account" }}
                          <ChevronDownIcon class="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                        </MenuButton>
                      </div>

                      <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                        <MenuItems class="absolute left-0 z-10 mt-2 w-72 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div class="py-1">
                            <MenuItem as="div" v-for="option in nonPensionAccounts" :key="option.id" v-slot="{ active }">
                              <a href="#" @click="selectPensionTake25Option(option)" :class="['group flex items-center px-4 py-2', active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm', dialogStore.selectedItem?.definedBenefitPension?.pensionTake25?.account?.id === option.id ? 'text-indigo-600 group-hover:text-indigo-600' : 'text-gray-400']">
                                <PlusCircleIcon v-if="option.id ==='create'" class="h-5 w-5 text-indigo-500 group-hover:text-indigo-500 mr-2"/>
                                {{ option.name }}
                                <CheckIcon v-if="dialogStore.selectedItem.definedBenefitPension.pensionTake25?.account?.id === option.id" class="ml-auto h-5 w-5 text-indigo-500 group-hover:text-indigo-500" aria-hidden="true"/>
                              </a>
                            </MenuItem>
                          </div>
                        </MenuItems>
                      </transition>
                    </Float>
                </Menu>
              </div>

          </div>

    </div>

  </div>    
  </div>
</template>

<script setup>
import { watch, onMounted, computed, ref } from 'vue';
import { useDialogStore } from '@/stores/DialogStore';
import { useIncomeStore } from '@/stores/IncomeStore';
import { usePlanStore } from '@/stores/PlanStore';
import { useTaxStore } from '@/stores/TaxStore';
import { useSavingsStore } from '@/stores/SavingsStore';
import { validateField } from '@/utils/validationUtils';
import { formatNumber } from '@/utils/formatNumber';

import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { Float } from '@headlessui-float/vue'
import { ChevronDownIcon, CheckIcon, PlusCircleIcon, ArrowPathIcon, } from '@heroicons/vue/20/solid'
import { InformationCircleIcon } from '@heroicons/vue/24/outline'

import PlanDropdownGrowth from '@/components/PlanDropdownGrowth.vue'
import PlanDropdownDate from '@/components/PlanDropdownDate.vue';

const incomeStore = useIncomeStore();
const dialogStore = useDialogStore();
const planStore = usePlanStore();
const taxStore = useTaxStore();
const savingsStore = useSavingsStore();

//register fields - not sure this is needed
// Register fields on component mount
onMounted(() => {
  registerFields();
  if (dialogStore.selectedItem.definedBenefitPension.active && dialogStore.selectedItem.definedBenefitPension.type) {
    calculateDefinedBenefitPensionDetails();
  } 
  getPensionAccounts();
  getNonPensionAccounts();
});

const registerFields = () => {
  // Call an action or mutation to register fields
  // For example, if you have a registerFields action in your store:
  dialogStore.registerFields(['name', 'value', 'startYear', 'endYear', 'growthRate']);
}

const frequencyOptions = [
    { value: 'once', label: 'Once' },
    { value: 'weekly', label: 'Weekly' },
    { value: '2weeks', label: 'Every 2 weeks' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'yearly', label: 'Yearly' },
  ];
  
  const updateFrequencySchedule = (value) => {
    dialogStore.selectedItem.frequency.schedule = value;
    if (value === 'once') {
        dialogStore.selectedItem.endYear = dialogStore.selectedItem.startYear;
    }
  };
  
  const getFrequencyLabel = (value) => {
    const option = frequencyOptions.find((opt) => opt.value === value);
    return option ? option.label : '';
  };


// Define the options for the dropdown
const definedBenefitMenuOptions = [
  // { label: 'Select', value: 'Select' },
  { label: 'Career average', value: 'careerAverage' },
  { label: 'Final salary', value: 'finalSalary' },
  { label: 'Set amount', value: 'setAmount' },
];

let nonPensionAccounts;

function getNonPensionAccounts() {
    const currentAccountId = dialogStore.selectedItem?.id; // Get the current account ID from dialogStore

    // Initialize the list with items from savingsStore
    let savingsCopy = savingsStore.items.map((item, index) => ({
      id: item.id,
      name: item.name,
      isPension: item.isPension,
      originalIndex: index // Step 1: Add the original index to each item
    }));

  // Step 2: Overwrite or add items from planStore.getCurrentPlan.savings
  const currentPlan = planStore.getCurrentPlan;

  currentPlan?.savings?.forEach(planSavingsItem => {
    const existingItemIndex = savingsCopy.findIndex(copyItem => copyItem.id === planSavingsItem.id);
    if (existingItemIndex !== -1) {
      // Overwrite the existing item if found, keeping the original index
      savingsCopy[existingItemIndex] = {
        id: planSavingsItem.id,
        name: planSavingsItem.name,
        isPension: planSavingsItem.isPension,
        originalIndex: savingsCopy[existingItemIndex].originalIndex
      };
    } else {
      // Add the new item if not found
      savingsCopy.push({
        id: planSavingsItem.id,
        name: planSavingsItem.name,
        isPension: planSavingsItem.isPension,
        originalIndex: savingsCopy.length
      });
    }
  });

  // Step 3: Filter out non-pension items and the currently viewed account
  nonPensionAccounts = savingsCopy
    .filter(item => !item.isPension && item.id !== currentAccountId)
    .map(({ id, name, isPension }) => ({ id, name, isPension }));

  // Step 4: Add the "Create a new account" option at the end
  nonPensionAccounts.push({
    id: 'create',
    name: "Create a new account",
    isPension: false,
    originalIndex: -1
  });

  return nonPensionAccounts;
}

// Function to handle option selection
const selectPensionTake25Option = (option) => {
  dialogStore.selectedItem.definedBenefitPension.pensionTake25.account = option;

};

const accrualRate = computed(() => {
  const rateString = dialogStore.selectedItem.definedBenefitPension.accrualRate;

  if (rateString && rateString.includes('/')) {
    const [numerator, denominator] = rateString.split('/').map(Number);
    return numerator / denominator;
  }

  return 1 / 57;  // Default value if the accrualRate is not properly formatted
});

const activeIndex = computed(() => {
  const index = dialogStore.selectedItem.definedBenefitPension.activeIndex;
  
  // If index is a valid number, return it; otherwise, return the default value of 3
  return (index !== undefined && index !== null && index !== "" && !isNaN(Number(index))) ? Number(index) : 3;
});

let pensionAccounts;

function getPensionAccounts() {
  // Initialize the list with items from savingsStore
  let savingsCopy = savingsStore.items.map((item, index) => ({
    id: item.id,
    name: item.name,
    isPension: item.isPension,
    originalIndex: index // Step 1: Add the original index to each item
  }));

  // Step 2: Overwrite or add items from planStore.getCurrentPlan.savings
  const currentPlan = planStore.getCurrentPlan;

  currentPlan?.savings?.forEach(planSavingsItem => {  
      
    const existingItemIndex = savingsCopy.findIndex(copyItem => copyItem.id === planSavingsItem.id);
    if (existingItemIndex !== -1) {
      // Overwrite the existing item if found, keeping the original index
      savingsCopy[existingItemIndex] = {
        id: planSavingsItem.id,
        name: planSavingsItem.name,
        isPension: planSavingsItem.isPension,
        originalIndex: savingsCopy[existingItemIndex].originalIndex,
        exclude: planSavingsItem.exclude,        
      };
    } else {
      // Add the new item if not found
      savingsCopy.push({
        id: planSavingsItem.id,
        name: planSavingsItem.name,
        isPension: planSavingsItem.isPension,
        originalIndex: savingsCopy.length,
        exclude: planSavingsItem.exclude
      });
    }
  });

  // Step 3: Filter out non-pension items and remove the originalIndex
   pensionAccounts = savingsCopy
    .filter(item => item.isPension && !item.exclude)    
    .map(({ id, name, isPension }) => ({ id, name, isPension }));

  pensionAccounts.push({
    id: 'create',
    name: "Create a new pension",
    isPension: true,
    originalIndex: -1
  })
  
  return pensionAccounts;
  // pensionAccounts now contains a non-reactive list of pension accounts with only id, name, and isPension
}

let careerAverage;
let finalSalary;
let duration = ref();
// let totalAccrued;
let actualAmount;

function calculateDefinedBenefitPensionDetails() {
  if (dialogStore.selectedItem.definedBenefitPension?.type?.value === 'careerAverage' || dialogStore.selectedItem.definedBenefitPension?.type?.value === 'finalSalary') {
    
    let startingSalary = dialogStore.selectedItem.value;
    let totalInvestmentPot = 0; // used for take25
    // totalAccrued = 0;
    finalSalary = startingSalary;
    const growthRate = planStore.getGrowthRate(planStore.currentPlanId, dialogStore.selectedItem);       
    const startYear = planStore.getYear(dialogStore.selectedItem, 'start');
    const endYear = planStore.getYear(dialogStore.selectedItem, 'end'); 
    duration.value = endYear - startYear + 1;  // +1 to include both start and end years
    const commutationFactor = dialogStore.selectedItem.definedBenefitPension.commutationFactor || 12; // Default commutation factor, adjust as needed
    
    let totalSalary = 0;
    careerAverage = 0;
    actualAmount = 0;
    
    // Calculate the cumulative salary and final salary
    for (let year = startYear; year <= endYear; year++) {
      // totalAccrued += (totalAccrued * (activeIndex.value / 100)); // index-linked growth
      // totalAccrued += finalSalary * accrualRate.value; // earned pension that year
      totalSalary += finalSalary; // calculating career average
      totalInvestmentPot *= (1 + (activeIndex.value / 100)); // investment pot growth
      totalInvestmentPot += ((finalSalary * (dialogStore.selectedItem.definedBenefitPension.yourContribution / 100)) + 
                             (finalSalary * (dialogStore.selectedItem.definedBenefitPension.employerContribution / 100))); // increase total amount
      finalSalary *= (1 + (growthRate / 100)); // apply growth of income item
    }
  
    // Calculate career average
    careerAverage = totalSalary / duration.value;

    // Store the values back in the dialogStore
    dialogStore.selectedItem.definedBenefitPension.careerAverage = careerAverage;
    dialogStore.selectedItem.definedBenefitPension.finalSalary = finalSalary;

    // Calculate the actual pension amount based on the pension type
    if (dialogStore.selectedItem.definedBenefitPension.type.value === 'careerAverage') {
      actualAmount = careerAverage * accrualRate.value * duration.value;
    } else {
      actualAmount = finalSalary * accrualRate.value * duration.value;
    }

    // Adjust the pension amount if the user has opted to take the 25% tax-free cash
    if (dialogStore.selectedItem.definedBenefitPension?.pensionTake25?.active) {
      const taxFreeAmount = totalInvestmentPot * 0.25; // 25% tax-free cash
      const reductionDueToTake25 = taxFreeAmount / commutationFactor; // Reduce yearly pension using commutation factor
      actualAmount -= reductionDueToTake25; // Adjust the yearly pension amount
    }

    // Store the final values back in the dialogStore
    dialogStore.selectedItem.definedBenefitPension.actualAmount = actualAmount;
    // dialogStore.selectedItem.definedBenefitPension.totalAccrued = totalAccrued;
    dialogStore.selectedItem.definedBenefitPension.totalInvestmentPot = totalInvestmentPot;
  }
}


// Function to handle option selection
const selectDefinedBenefitMenuOption = (option) => {
  dialogStore.selectedItem.definedBenefitPension.type = option;
  if (option.value === "setAmount") {
    dialogStore.selectedItem.definedBenefitPension.totalAccrued = Math.round(dialogStore.selectedItem.definedBenefitPension.totalAccrued * 100) / 100;
    dialogStore.registerFields(['definedBenefitPensionSetAmount']);
    

  }
  else {
    dialogStore.removeField('definedBenefitPensionSetAmount');
  }
  calculateDefinedBenefitPensionDetails()

};

// Function to handle option selection
const selectEmployerPensionAccount = (option) => {
  dialogStore.selectedItem.employerPension.account = option;

};

if (dialogStore.dialogMode === 'add') {
  // Assuming getSchema2 returns a reactive object
  const reactiveEmptyIncomeItem = incomeStore.getSchema2();
  // Convert the reactive object to a plain object
  const emptyIncomeItem = JSON.parse(JSON.stringify(reactiveEmptyIncomeItem));
  dialogStore.selectedItem = emptyIncomeItem;
}

if (dialogStore.dialogMode === 'edit') {
    // Watch for changes to the selected item ID
  watch(() => dialogStore.selectedItemId, (newId) => {
    
    // Check if the item ID exists in the IncomeStore
    const selectedItemInIncome = incomeStore.items.find(item => item.id === newId);

    // Check if the item ID exists in the individual plan's data
    const currentPlan = planStore.plans.find(plan => plan.id === planStore.currentPlanId);
    const selectedItemInPlan = currentPlan && currentPlan.income ? currentPlan.income.find(item => item.id === newId) : null;

    let newSelectedItem = null;

    // Use the correct store to edit the item
    if (selectedItemInPlan) {
      newSelectedItem = JSON.parse(JSON.stringify(selectedItemInPlan));
    } else if (selectedItemInIncome) {
      newSelectedItem = JSON.parse(JSON.stringify(selectedItemInIncome));
    }

    // Only assign if there's a change
    if (JSON.stringify(dialogStore.selectedItem) !== JSON.stringify(newSelectedItem)) {
      dialogStore.selectedItem = newSelectedItem;
    }
  }, { immediate: true });
}

watch(dialogStore.selectedItem, () => {
   calculateDefinedBenefitPensionDetails();
});

</script>
