import { usePlanStore } from '@/stores/PlanStore';
import { useUserProfileStore } from '@/stores/UserProfile';
import { useIncomeStore } from '@/stores/IncomeStore';
import { useExpensesStore } from '@/stores/ExpensesStore';
import { useTaxStore } from '@/stores/TaxStore';
import { usePhysicalAssetsStore } from '@/stores/PhysicalAssetsStore.js';
import { useSavingsStore } from '@/stores/SavingsStore.js';
import { useDebtStore } from '@/stores/DebtStore.js';
// import { computed } from 'vue';

export default function useFinancialProjections() {
    // console.log('updating projection data');
    const planStore = usePlanStore();
    const userProfileStore = useUserProfileStore();
    const taxStore = useTaxStore();
    const incomeStore = useIncomeStore();
    const expensesStore = useExpensesStore();
    const physicalAssetsStore = usePhysicalAssetsStore();
    const savingsStore = useSavingsStore();
    const debtStore = useDebtStore();

    const currentPlan = planStore.plans.find(plan => plan.id === planStore.currentPlanId);

    function projectFinancialPosition() {
        
        let labels = [];
        let currentNetWorth = 0;
        let definedBenefitPensionTotalPot = 0;
    
        // Modified copy to prioritize currentPlan items// Step 1: Initialize copy with items from store.items
        let incomeCopy = JSON.parse(JSON.stringify(incomeStore.items));

        // Step 2: Add or overwrite items from currentPlan.income
        currentPlan?.income?.forEach(planIncomeItem => {            
            const existingItemIndex = incomeCopy.findIndex(copyItem => copyItem.id === planIncomeItem.id);            
            if (existingItemIndex !== -1) {
                // Overwrite the existing item if found
                incomeCopy[existingItemIndex] = JSON.parse(JSON.stringify(planIncomeItem));
            } else {
                // Add the new item if not found
                incomeCopy.push({ ...planIncomeItem });                
            }
        });

        // Step 3: Remove items with the excluded flag
        incomeCopy = incomeCopy.filter(item => !item.exclude);

        // Expenses Step 1: Initialize copy with items from store.items
        let expensesCopy = expensesStore.items.map(item => ({ ...item }));
        // Step 2: Add or overwrite items from currentPlan.expenses
        currentPlan?.expenses?.forEach(planExpenseItem => {            
            const existingItemIndex = expensesCopy.findIndex(copyItem => copyItem.id === planExpenseItem.id);            
            if (existingItemIndex !== -1) {
                // Overwrite the existing item if found
                expensesCopy[existingItemIndex] = { ...planExpenseItem };
            } else {
                // Add the new item if not found
                expensesCopy.push({ ...planExpenseItem });                
            }
        });

        // Step 3: Remove items with the excluded flag
        expensesCopy = expensesCopy.filter(item => !item.exclude);
        
        //same for savings
        let savingsCopy = savingsStore.items.map((item, index) => ({
            ...item,
            originalIndex: index // Step 1: Add the original index to each item
          }));
          
          // Step 2: Add or overwrite items from currentPlan
          currentPlan?.savings?.forEach(planSavingsItem => {
            const existingItemIndex = savingsCopy.findIndex(copyItem => copyItem.id === planSavingsItem.id);
            if (existingItemIndex !== -1) {
              // Overwrite the existing item if found, keeping the original index
              savingsCopy[existingItemIndex] = { ...planSavingsItem, originalIndex: savingsCopy[existingItemIndex].originalIndex };
            } else {
              // Add the new item if not found, assigning the next original index
              savingsCopy.push({ ...planSavingsItem, originalIndex: savingsCopy.length });
            }
          });
          
          // Step 3: Remove items with the excluded flag
          savingsCopy = savingsCopy.filter(item => !item.exclude);
          
          // Step 4: Sort so pensions are done first
          savingsCopy.sort((a, b) => {
            if (a.isPension === b.isPension) {
              return 0; // No change if both are the same
            }
            return a.isPension ? -1 : 1; // Pension accounts come first
          });

        //same for physical Assets
        let physicalAssetsCopy = physicalAssetsStore.items.map(item => ({ ...item })); // Modified copy to prioritize currentPlan items// Step 1: Initialize copy with items from store.items
        currentPlan?.physicalAssets?.forEach(planAssetItem => {             // Step 2: Add or overwrite items from currentPlan
            const existingItemIndex = physicalAssetsCopy.findIndex(copyItem => copyItem.id === planAssetItem.id);            
            if (existingItemIndex !== -1) {
                // Overwrite the existing item if found
                physicalAssetsCopy[existingItemIndex] = { ...planAssetItem };
            } else {
                // Add the new item if not found
                physicalAssetsCopy.push({ ...planAssetItem });                
            }
        });
        // Step 3: Remove items with the excluded flag
        physicalAssetsCopy = physicalAssetsCopy.filter(item => !item.exclude);

        //same for debt
        let debtCopy = debtStore.items.map(item => ({ ...item })); // Modified copy to prioritize currentPlan items// Step 1: Initialize copy with items from store.items
        currentPlan?.debts?.forEach(planDebtItem => {             // Step 2: Add or overwrite items from currentPlan
            const existingItemIndex = debtCopy.findIndex(copyItem => copyItem.id === planDebtItem.id);            
            if (existingItemIndex !== -1) {
                // Overwrite the existing item if found
                debtCopy[existingItemIndex] = { ...planDebtItem };
            } else {
                // Add the new item if not found
                debtCopy.push({ ...planDebtItem });                
            }
        });
        // Step 3: Remove items with the excluded flag
        debtCopy = debtCopy.filter(item => !item.exclude);
       
        // Calculate initial totals
        let totalSavings = savingsCopy.reduce((total, account) => total + account.value, 0);
        let totalPhysicalAssets = physicalAssetsCopy.reduce((total, account) => total + account.value, 0);

        const includeStudentLoanInNetWorth = planStore.getCurrentPlan.includeStudentLoanInNetWorth;
        let totalDebt = debtCopy.reduce((total, debt) => {
          // If student loans are to be included or if the debt is not a student loan, add its value to the total
          if (includeStudentLoanInNetWorth || !debt.studentLoan) {
            return total + debt.value;
          }
          // Otherwise, just return the current total
          return total;
        }, 0);
        
        let savingsRate = 0;
        let yearlyProfit = 0

        const data = {
            physicalAssets: [], 
            savings: [],            
            assets: [],
            debt: [],
            netWorth: [],  
            expenses: [],     
            income: [],             
            savingsRate: [],
            profit: [],
            age :[],
        };
        for (const income of incomeCopy) {
            if (income.frequency.gross === 'monthly') {
                income.value *= 12;
            }
            if (income.frequency.gross === 'weekly') {
                income.value *= 52;
            }
            if (income.frequency.gross === '2weeks') {
                income.value *= 26;
            }
        }
        for (const expense of expensesCopy) {
            if (expense.frequency === 'monthly') {
                expense.value *= 12;
            }
        }      

        /////////////////////////THE MAIN LOOP//////////////////////////////
        for (let year = planStore.getCurrentPlan.startYear; year <= planStore.getCurrentPlan.startYear + planStore.getCurrentPlan.duration; year++) {               
            // Reset year-specific values
            let yearPhysicalAssets = 0;
            let yearDebt = 0;              
            let totalTaxableIncome = 0;
            let totalIncome = 0;
            let personalAllowance = 0;
            let yearlyPensionContribution = 0;
            let yearlyDebtPayments = 0;   
            let unallocatedIncome = 0;
            let unallocatedPension = 0;
            let definedBenefitPensionTake25ToAllocate = 0;
            let yearSavings = 0;            

           // Step 1: Accumulate total taxable income and total income for the year
            for (const income of incomeCopy) {
                // Retrieve the start and end years for the income item
                const startYear = planStore.getYear(income, 'start');
                const endYear = planStore.getYear(income, 'end');                                          

                // Check if the income is active for the current year
                if (startYear <= year && endYear >= year) {
                    let incomeValue = income.value;  // The base value of the income
                    let employerPensionAmount = 0;   // Amount contributed by the employer to the pension
                    let definedBenefitPensionAmount = 0; // Amount contributed to defined benefit pension
                    let employerPensionYourContributionAmount = 0; // Amount contributed by the user to the employer pension

                    // S Handle employer pension contributions
                    if (income.employerPension.active) {
                        // Calculate the user's contribution to the employer pension
                        employerPensionYourContributionAmount = (income.employerPension.yourContribution / 100) * incomeValue; 
                        // Calculate the employer's contribution to the pension
                        employerPensionAmount = (income.employerPension.amount / 100) * incomeValue; 
                        
                        // Find the target pension account in savings
                        const targetAccount = savingsCopy.find(a => a.id === income.employerPension.account.id);
                        
                        // If the target account exists and is a pension, allocate the contributions
                        //however interest will be applied before it is earnt
                        if (targetAccount && targetAccount.isPension) {
                            targetAccount.value += employerPensionAmount;
                            targetAccount.value += employerPensionYourContributionAmount;
                            yearSavings += employerPensionAmount;
                            yearSavings += employerPensionYourContributionAmount;
                            // reset
                            employerPensionAmount = 0;
                            employerPensionYourContributionAmount = 0;                            
                        } 
                        else {
                            // If the pension account is not found, mark the contributions as unallocated
                            unallocatedPension += (employerPensionYourContributionAmount + employerPensionAmount);
                        }
                    }

                    // Handle defined benefit pension contributions
                    if (income.definedBenefitPension.active && income.definedBenefitPension.yourContribution > 0) {                        
                        // Calculate the user's contribution to the defined benefit pension
                        definedBenefitPensionAmount = (income.definedBenefitPension.yourContribution / 100) * incomeValue;                        
                        definedBenefitPensionTotalPot += definedBenefitPensionAmount;   
                    } 
                    if (income.definedBenefitPension.active && income.definedBenefitPension.employerContribution > 0) {       
                        definedBenefitPensionTotalPot += ((income.definedBenefitPension.employerContribution / 100) * incomeValue);                        
                    }                    
                    
                    // Adjust the income value by subtracting pension contributions
                    incomeValue = incomeValue - definedBenefitPensionAmount - employerPensionYourContributionAmount;                   

                    // Add unallocated pension contributions (both employer and defined benefit) to the unallocated pool
                    //TODO create alert for this
                    unallocatedPension += (definedBenefitPensionAmount + employerPensionAmount);
                    if (unallocatedPension > 0) {
                        console.log('unallocated pension: ', year, 'defined: ', definedBenefitPensionAmount, 'employer: ', employerPensionAmount, employerPensionYourContributionAmount);
                    }

                    //  Accumulate total income for the year
                    totalIncome += incomeValue;

                    // If the income is taxable, accumulate total taxable income
                    if (income.taxable) {
                        totalTaxableIncome += incomeValue;
                    }                              
                }

                //  Handle defined benefit pension payouts
                if (income.definedBenefitPension.active && year >= (userProfileStore.getYearAtAge(income.definedBenefitPension.age) || userProfileStore.getYearAtAge(taxStore.statePensionAge))) {                    
                    console.log(income.definedBenefitPension.actualAmount)
                    // Add the total accrued defined benefit pension to both total taxable income and total income
                    totalTaxableIncome += income.definedBenefitPension.actualAmount;
                    totalIncome += income.definedBenefitPension.actualAmount;         
                    
                    if (income.definedBenefitPension?.pensionTake25?.active) {
                        definedBenefitPensionTake25ToAllocate = { amount: definedBenefitPensionTotalPot * 0.25, accountId: income.definedBenefitPension?.pensionTake25?.account?.id };
                        // console.log(year, "definedBenefitPensionTake25ToAllocate" , definedBenefitPensionTake25ToAllocate, "pot", definedBenefitPensionTotalPot)
                    }
                }
            }

            
            //Step 1.5 deduct student loans
            //for items in debt copy, get the studentLoan = true items. use the thresholds to work out if we should repay. use the yearly thresholds, or monthly threshold if income.frequency/12 is 'monthly'
            for (const loan of debtCopy) {
                if (loan.studentLoan) {                
                    // Convert the loan startYear into a plan. That plan has the rates.
                    const planName = taxStore.getStudentLoanPlanFromStartYear(loan);
                    const loanPlanDetails = taxStore.studentLoanRepaymentPlans[planName];
                    const startYear = loan.startYear;
                    const endYear = taxStore.getStudentLoanEndYear(loan);
                    
                    if (startYear <= year && endYear >= year) {
                        let amountOver = 0;
                        let amountToPay = 0;
                        
                        if (totalIncome > loanPlanDetails.annualThreshold) {
                            // Calculate amount over the threshold you earn.
                            amountOver = totalIncome - loanPlanDetails.annualThreshold;
                            // Use the plan repaymentRate to calculate what you repay.
                            amountToPay = amountOver * (loanPlanDetails.repaymentRate / 100); // Ensure repaymentRate is a percentage.
                        }
                        //ensure repayment doesn't go below zero
                        amountToPay = Math.min(amountToPay, loan.value);

                        // Deduct that from totalTaxableIncome and totalIncome.
                        totalTaxableIncome -= amountToPay;
                        totalIncome -= amountToPay;
                        
                        // Deduct that from the loan balance.
                        loan.value -= amountToPay;
                        
                        // Apply plan interest rate to the student loan value.
                        const interest = loan.value * (loanPlanDetails.interestRate / 100); // Ensure interestRate is a percentage.
                        loan.value += interest;
                        // console.log(interest, 'amount: ', amountToPay, 'loan value: ', loan.value);
                        if (planStore.getCurrentPlan.includeStudentLoanInNetWorth) {
                            yearDebt += amountToPay - interest;
                            yearlyDebtPayments += amountToPay;
                        }

                    }
              }
            }                                                

            // Pay tax on pension withdrawals
            currentPlan.savingsAllocation.forEach(account => {
                // Find the corresponding account in savingsCopy
                const foundAccount = savingsCopy.find(a => a.id === account.id);
            
                // Check if the account is a pension
                if (foundAccount && foundAccount.isPension) {
                // Find the allocation point for the given year
                let allocationPoint = account.allocation.find(a => a.year === parseInt(year));
            
                // Check if the allocation point is in drawdown
                if (allocationPoint && allocationPoint.isDrawdown) {
                    // Add to the total taxable income
                    // allocationPoint *= 0.75 //tax free lump sum each time
                    totalTaxableIncome += allocationPoint.absolute;
                }
                }
            });

            //step 2 calculate tax            
           let yearlyIncomeTax = taxStore.calculateTax(totalTaxableIncome, year);           
            
            // Step 3: Deduct taxes from total income and adjust individual incomes
            let postTaxIncome = totalIncome - yearlyIncomeTax;
            for (const incomeItem of incomeCopy) {

                const startYear = planStore.getYear(incomeItem, 'start');
                const endYear = planStore.getYear(incomeItem, 'end');    
                // console.log(year, incomeItem.name, endYear)
                
                // Apply growth rate and update income value for next year
                let growthRate = planStore.getGrowthRate(currentPlan.id, incomeItem);            
                                
                if (year >= startYear && year <= endYear) {
                    let growth = incomeItem.value * (growthRate / 100);                    
                    incomeItem.value += growth;
                }          
                
                //calculate defined benefit growth
                if (incomeItem.definedBenefitPension.active && year >= (userProfileStore.getYearAtAge(incomeItem.definedBenefitPension.age) || userProfileStore.getYearAtAge(taxStore.statePensionAge))) {                    
                    let growthOfPension = incomeItem.definedBenefitPension.totalAccrued * ((incomeItem.definedBenefitPension.activeIndex || 1.6) / 100) //hard coded treasury rate as fallback
                    incomeItem.definedBenefitPension.totalAccrued += growthOfPension;                                                          
                }
                  
            }             

            // Expenses calculations
            let yearExpenses = 0;
            for (const expense of expensesCopy) {
                let growthRate = planStore.getGrowthRate(currentPlan.id, expense);

                const startYear = planStore.getYear(expense, 'start');
                const endYear = planStore.getYear(expense, 'end');   

                if (startYear <= year && endYear >= year) {
                    let growth = expense.value * (growthRate / 100);
                    expense.value += growth;
                    yearExpenses += expense.value;
                }
            
            }

            // Debt calculations                                             
            for (const debt of debtCopy) {
                //exclude studentloans
                if (!debt.studentLoan) {
                    let growthRate = planStore.getGrowthRate(currentPlan.id, debt);         
                const startYear = planStore.getYear(debt, 'start');
                const endYear = planStore.getYear(debt, 'end');

                if (year >= startYear && debt.value > 0 && year <= endYear) {
                    // Calculate annual interest
                    let annualInterest = debt.value * (growthRate / 100);
                    
                    // Calculate total repayment based on contribution type
                    let totalRepayment = 0;
                    switch (debt.contribution) {
                        case "Monthly":
                            totalRepayment = debt.contributionAmount * 12;
                            break;
                        case "Yearly":
                            totalRepayment = debt.contributionAmount;
                            break;
                        case "none":
                        default:
                            // No contributions
                            break;
                    }                   
                    
                    // Ensure total repayment doesn't exceed debt value
                    totalRepayment = Math.min(totalRepayment, debt.value + annualInterest);

                    // lifeRepayments += totalRepayment;
                    
                    // Calculate debt change and update debt value
                    let debtChange = totalRepayment - annualInterest;
                    debt.value -= debtChange;
                    
                    yearDebt += debtChange;
                    yearlyDebtPayments += totalRepayment;
                    
                    // Ensure debt value doesn't go below 0
                    debt.value = Math.max(0, debt.value);
                }
              }                
            }

            // Savings calculations
            //how much to allocate and where
            // const disposableIncome = postTaxIncome - yearExpenses;
            yearlyProfit = postTaxIncome - yearExpenses - yearlyDebtPayments;

            for (const account of savingsCopy) {                        
                let growthRate = planStore.getGrowthRate(currentPlan.id, account);        
                const startYear = planStore.getYear(account, 'start');
                const endYear = planStore.getYear(account, 'end');                                   
                                
                if (startYear <= year && endYear >= year) {                    
                    // Retrieve the allocation for the current year
                    const allocationData = planStore.getCurrentPlan.savingsAllocation.find(a => a.id === account.id);
                    const allocationPoint = allocationData ? allocationData.allocation.find(a => a.year === year) : null;
                    const allocationPercent = allocationPoint ? allocationPoint.percent / 100 : 0;
            
                    // Calculate the contribution based on disposable income and allocation percentage
                    let contribution = yearlyProfit * allocationPercent;

                    if (definedBenefitPensionTake25ToAllocate.accountId === account.id && definedBenefitPensionTake25ToAllocate.amount > 0) {
                        contribution += definedBenefitPensionTake25ToAllocate.amount;
                        definedBenefitPensionTake25ToAllocate.amount = 0;
                    }

                    //check annual allowance
                    if (account.isPension) {        
                        //one off check for 25% drawdown
                        if (account.pensionTake25?.active && userProfileStore.getAgeAtYear(year) === (currentPlan.settings?.normalMinimumPensionAge || taxStore.normalMinimumPensionAge)) {
                            const take25 = account.value * 0.25;
                            account.value *= 0.75;
                            if (account.pensionTake25.account && account.pensionTake25.account.id) {                                
                                const targetAccount = savingsCopy.find(a => a.id === account.pensionTake25.account.id);
                                
                                if (targetAccount) {
                                    // If the account is found, update its value
                                    targetAccount.value += take25;
                                } else {
                                    // Fallback behavior if the account is not found
                                    console.warn(`Account with ID ${account.pensionTake25.account.id} not found in savingsCopy.`);
                                    unallocatedIncome += take25;
                                    // Optional: Handle the fallback, e.g., create a new entry, skip, or set a default value
                                    // Example: Skipping the operation
                                    // Or you could add a new account to savingsCopy with the desired value
                                }
                            }
                            
                        }
                        
                        yearlyPensionContribution += contribution;
                        if (yearlyPensionContribution > taxStore.annualAllowance) {
                            const surplus = yearlyPensionContribution - taxStore.annualAllowance;
                            // console.log('pension limit exceeded by', surplus);
                            unallocatedIncome += surplus;
                            contribution = taxStore.annualAllowance;
                        }
                    }
                    //need to do something with the unallocated income e.g. set alert here TODO
                    if (unallocatedIncome > 0 && !account.isPension) {
                        contribution += unallocatedIncome;                                                
                        unallocatedIncome = 0;
                    }

                     // Calculate interest on the updated account value
                    //could check any income that is paid monthly to then reduce the growth on that amount
                    let interest = account.value * (growthRate / 100);
                    account.value += interest;
                    
                    //apply account fees
                    if (account.fundFee > 0) {
                        let fundFees = account.value * (account.fundFee / 100)
                        account.value -= fundFees;
                    }
            
                    // Calculate taxable interest if account is taxable
                    if (account.taxableInterest) {
                        let taxableInterest = interest;
            
                        // Determine the starting rate for savings
                        let startingRateForSavings = 0;
                        if (totalIncome < 17570) {
                            startingRateForSavings = Math.max(0, 5000 - (totalIncome - 12570)); // Adjusted for Personal Allowance
                        }
            
                        // Determine the Personal Savings Allowance
                        let personalSavingsAllowance = 0;
                        if (totalTaxableIncome <= taxStore.incomeTaxThresholds.basicRateThreshold) {
                            personalSavingsAllowance = 1000;
                        } else if (totalTaxableIncome <= taxStore.incomeTaxThresholds.higherRateThreshold) {
                            personalSavingsAllowance = 500;
                        } else {
                            personalSavingsAllowance = 0;
                        }
            
                        // Calculate the total tax-free interest
                        let taxFreeInterest = Math.min(startingRateForSavings, taxableInterest);
                        taxableInterest -= taxFreeInterest;
                        taxFreeInterest = Math.min(personalSavingsAllowance, taxableInterest);
                        taxableInterest -= taxFreeInterest;
            
                        // Add taxable interest to total taxable income for the next tax calculation
                        totalTaxableIncome += taxableInterest;
                    }
            

                    // Handle tax-deductible savings accounts e.g. pension 
                    //TODO need to check that we don't claim tax relief on contributions from salary
                    if (account.taxRelief) {
                        // Calculate tax relief based on the current total taxable income and tax bands
                        let taxRelief = 0;
                        let remainingContribution = contribution;
            
                        const calculateTaxRelief = (income, rate) => {
                            return income * (rate / 100);
                        }
            
                        // Calculate tax relief based on the contribution amount and tax bands
                        if (totalTaxableIncome > taxStore.incomeTaxThresholds.additionalRateThreshold) {
                            const additionalRateIncome = Math.min(remainingContribution, totalTaxableIncome - taxStore.incomeTaxThresholds.additionalRateThreshold);                            
                            taxRelief += calculateTaxRelief(additionalRateIncome, taxStore.incomeTaxRates.additionalRate);
                            remainingContribution -= additionalRateIncome;
                        }                        

                        if (totalTaxableIncome > taxStore.incomeTaxThresholds.higherRateThreshold && remainingContribution > 0) {
                            const higherRateIncome = Math.min(remainingContribution, totalTaxableIncome - taxStore.incomeTaxThresholds.higherRateThreshold);
                            taxRelief += calculateTaxRelief(higherRateIncome, taxStore.incomeTaxRates.higherRate);
                            remainingContribution -= higherRateIncome;
                        }
                        if (remainingContribution > 0) {
                            const basicRateIncome = Math.min(remainingContribution, totalTaxableIncome - personalAllowance);
                            taxRelief += calculateTaxRelief(basicRateIncome, taxStore.incomeTaxRates.basicRate);
                            remainingContribution -= basicRateIncome;
                        }
            
                        // Subtract contribution from total taxable income
                        totalTaxableIncome -= contribution;
            
                        // Add the reclaimed tax to the account before interest
                        account.value += contribution + taxRelief;
                        

                    } 
                    //add the allocated contribution to the savings account
                    else {
                        account.value += contribution;
                    }
            
                    // Update yearly savings                              
                    yearSavings += contribution;
            
                    // No negative interest on savings
                    if (interest >= 0) {
                        yearSavings += interest;                            
                    }                    
                }
            }//end savings

            if (unallocatedIncome > 0) {
                console.log('unallocated:', unallocatedIncome)
            }                         
                
            // Physical assets calculations
            for (const physicalAsset of physicalAssetsCopy) {
                let growthRate = planStore.getGrowthRate(currentPlan.id, physicalAsset);
                
                const startYear = planStore.getYear(physicalAsset, 'start');
                const endYear = planStore.getYear(physicalAsset, 'end');   

                if (startYear <= year && endYear >= year) {
                    let appreciation = physicalAsset.value * (growthRate / 100);

                    // Update the value only if it's positive
                    if (physicalAsset.value >= 0) {
                        physicalAsset.value += appreciation;
                        yearPhysicalAssets += appreciation;
                    }
                }
            }
            
            savingsRate = ((yearlyProfit) / postTaxIncome) * 100;         

            // Update totals with the changes for this year
            totalPhysicalAssets += yearPhysicalAssets;
            // console.log(year, totalSavings, yearSavings)
            totalSavings += yearSavings;      
            totalDebt -= yearDebt;            
        
            // Update current net worth
            currentNetWorth = totalPhysicalAssets + totalSavings  - totalDebt;

            //adjust savingsRate
            if (isFinite(savingsRate)) {
                data.savingsRate.push(savingsRate.toFixed(1));            
            }
            else {
                savingsRate = 0;
                data.savingsRate.push(savingsRate.toFixed(1));                
            }

            // Push data for the current year
            data.physicalAssets.push(totalPhysicalAssets);
            data.savings.push({'yearTotal' : totalSavings, 'accounts' : JSON.parse(JSON.stringify(savingsCopy))});            
            data.assets.push(totalPhysicalAssets + totalSavings);
            data.debt.push(totalDebt);
            data.netWorth.push(currentNetWorth);
            data.income.push(postTaxIncome);
            data.expenses.push(yearExpenses);     
            data.profit.push(yearlyProfit);
            data.age.push(userProfileStore.getAgeAtYear(year));     

        }//end of yearly for loop
    
        // Populate labels with year values
        labels = Array.from({ length: data.income.length }, (_, index) => planStore.getCurrentPlan.startYear + index);

        // Step 5: Unsort the accounts back to their original order using the originalIndex
        data.savings.forEach(yearData => {
            yearData.accounts.sort((a, b) => a.originalIndex - b.originalIndex);
        });
        
        // Step 6: Optionally, remove the originalIndex property if it's no longer needed
        data.savings.forEach(yearData => {
            yearData.accounts.forEach(account => {
            delete account.originalIndex;
            });
        });
    
        return { labels, data };
    }
    
    return {
        projectFinancialPosition
    };
}