<template>
    <div class=" ">
      <div class="flex justify-end sm:items-center md:m-0 px-4">
        <div class="mt-2 sm:mt-0 sm:ml-16 sm:flex-none">
          <button type="button" @click="snapshotsStore.openDialog('add');" class="block rounded-md bg-violet-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Add snapshot</button>          
          <EditDialog @close="closeDialog" />
        </div>
      </div>
      <div class="mt-4 flow-root">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div class="overflow-hidden  ring-1 ring-black ring-opacity-5 ">
              <table class="min-w-full divide-y divide-gray-300">
                <thead class="bg-gray-50">
                  <tr>                    
                    <th scope="col" class="flex items-center px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Date
                        <span class="h-6 w-6 text-gray-600 cursor-pointer" @click="toggleSortOrder">
                          <ChevronUpDownIcon  aria-hidden="true" />
                        </span>
                    </th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Savings</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Physical assets</th>
                    
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Debt</th>    
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Net worth</th>
                    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6"><span class="sr-only">Edit</span></th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr v-for="snapshot in sortedSnapshots" :key="snapshot.id" @click="openRow('edit', snapshot, snapshot.id)" class="hover:bg-gray-50 cursor-pointer">                    
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ formatDate(snapshot.date) }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ $formatNumber(snapshot.savingsTotal) }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ $formatNumber(snapshot.assetsTotal) }}</td>
                    
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ $formatNumber(snapshot.debtsTotal) }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ $formatNumber(snapshot.netWorth) }}</td>            
                    
                    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <button href="#" @click="openRow('edit', snapshot, snapshot.id)" class="text-indigo-600 hover:text-indigo-900">Edit<span class="sr-only">, {{ snapshot.date }}</span></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
<script setup>
  import { useSnapshotsStore } from '../stores/SnapshotsStore.js'
  import { ref, computed } from 'vue';
  import EditDialog from '@/components/HomeSnapshotEditDialog'
  import { ChevronUpDownIcon } from '@heroicons/vue/24/solid';

  const sortOrder = ref('ascending');

  const snapshotsStore = useSnapshotsStore();  

  // Example function to format date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  return date.toLocaleDateString('en-GB', options); // 'en-GB' gives day-month-year
}

  //handle clicking edit on a row
  const openRow = (mode, snapshot, id) => {
    snapshotsStore.selectSnapshot(snapshot);
    snapshotsStore.openDialog(mode, 'snapshot', id);
  }

  const closeDialog = () => {
        snapshotsStore.closeDialog();
    }

  const sortedSnapshots = computed(() => {
    return [...snapshotsStore.snapshots].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return sortOrder.value === 'ascending' ? dateA - dateB : dateB - dateA;
  });
});

const toggleSortOrder = () => {
  sortOrder.value = sortOrder.value === 'ascending' ? 'descending' : 'ascending';
};


</script>