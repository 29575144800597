<template>
  <div class="flex flex-row gap-2">
    <div class="flex flex-col items-start">
      <div class="flex items-center align-middle">
        <ArrowPathIcon class="h-4 w-4 mr-1 text-slate-600 cursor-pointer hover:text-indigo-500" aria-hidden="true" />
        <label for="growthRate" class="block text-sm font-medium leading-6 text-gray-900"><span v-if="dialogStore.selectedItemType === 'income'">Pay </span>Frequency</label>
      </div>
      <Menu as="div" class="relative inline-block text-left w-40 mb-2">
        <Float portal :shift="8" enter="transition duration-200 ease-out" enter-from="scale-95 opacity-0" enter-to="scale-100 opacity-100" leave="transition duration-150 ease-in" leave-from="scale-100 opacity-100" leave-to="scale-95 opacity-0" tailwindcss-origin-class>
        <div>
          <MenuButton class="inline-flex justify-between w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            {{ getFrequencyLabel(dialogStore.selectedItem.frequency) || 'Frequency' }}
            <ChevronDownIcon class="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
          </MenuButton>
        </div>
          <MenuItems
            class="z-10 mt-2 w-64 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div class="py-1">
              <MenuItem v-for="option in frequencyOptions" :key="option.value">
                <a href="#"  @click="updateFrequency(option.value)" :class="['group flex items-center px-4 py-2 text-sm hover:bg-gray-50 hover:text-gray-900', frequencyActive(option.value) ? 'text-indigo-600' : 'text-gray-700' ]">
                  {{ option.label }}
                  <CheckIcon
                    v-if="frequencyActive(option.value)"
                    class="ml-auto h-5 w-5 text-indigo-500 group-hover:text-indigo-500"
                    aria-hidden="true"
                  />
                </a>
              </MenuItem>
            </div>
          </MenuItems>

      </Float>
      </Menu>
    </div>
  </div>
  </template>
  
  <script setup>
  import { ChevronDownIcon, ArrowPathIcon, CheckIcon } from '@heroicons/vue/20/solid';
  import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
  import { useDialogStore } from '@/stores/DialogStore';
  import { Float } from '@headlessui-float/vue'

  const dialogStore = useDialogStore();
  const frequencyOptions = [
    { value: 'once', label: 'Once' },
    { value: 'weekly', label: 'Weekly' },
    { value: '2weeks', label: 'Every 2 weeks' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'yearly', label: 'Yearly' },
  ];
  
  const frequencyActive = (menuItem) => {
    return dialogStore.selectedItem.frequency === menuItem;
  };
  
  const updateFrequency = (value) => {
    dialogStore.selectedItem.frequency = value;
    if (value === 'once') {
        dialogStore.selectedItem.endYear = dialogStore.selectedItem.startYear;
    }
  };
  
  const getFrequencyLabel = (value) => {
    const option = frequencyOptions.find((opt) => opt.value === value);
    return option ? option.label : '';
  };
  </script>