// store/dialogStore.js
import { defineStore } from 'pinia';
import { useUserProfileStore } from '@/stores/UserProfile';
import { usePlanStore } from '@/stores/PlanStore';


export const useDialogStore = defineStore('dialog', {
  state: () => ({
    allocationOpen: false,
    dialogMode: '', // 'add' or 'edit'
    isOpen: false, // Dialog visibility state
    profileOpen: false,
    selectedItemType: '',
    selectedItemId: -1,
    selectedItem: {},
    toDelete: false , 
    dirtyFields: {name: false,},   
    fields: {},
    fieldErrors: {},

  }),
  getters: {
    isDeleteButtonDisabled: (state) => {
      const planStore = usePlanStore();
      const activeSavingsCount = planStore?.getActiveSavingsCount ?? 0;
      const includeInAllocation = state.selectedItem?.includeInAllocation ?? false;
      return activeSavingsCount <= 1 && includeInAllocation === true && state.dialogMode !== 'add';
    },
    isValid() {
      const fields = Object.keys(this.fields);
      
      // Condition for edit mode
      if (this.dialogMode === 'edit' || this.dialogMode === 'settings') {
        // Check if all fields are valid or don't appear in fieldErrors
        return fields.every(field => !this.fieldErrors[field]);
      }
      
      // Condition for add mode
      if (this.dialogMode === 'add') {
        return fields.every(field => this.dirtyFields[field] && !this.fieldErrors[field]);
      }
      
      // Default return value if dialogMode is neither 'edit' nor 'add'
      return false;
    },

    isAnyFieldDirty() {
      return Object.values(this.dirtyFields).some(field => field === true);
    },
    allFieldsDirty() {
      const fields = Object.keys(this.fields);      
      // Check if all fields are marked as dirty in dirtyFields
      return fields.every(field => this.dirtyFields[field]);
    },
    
    isFieldValid: (state) => (fieldName) => {
      // Check if the field is in fieldErrors and its value is false or non-existent
      return !state.fieldErrors[fieldName];
    }
  },
  
  actions: {
    closeDialog() {      
      this.isOpen = false;
      setTimeout(() => { //timeout to allow transition to happen
        this.dialogMode = '';
        this.selectedItemType = '';
        this.selectedItem = {};
        this.selectedItemId = -1;      
        this.clearFields();    
    }, 200); // Adjust the delay time to match your transition duration  
    
    },        
    clearFields() {
      // Clear dirtyFields
      this.dirtyFields = {};

      this.fields = {};
    
      // Clear fieldErrors
      this.fieldErrors = {};
      // for (const field of Object.keys(this.fieldErrors)) {
      //   this.fieldErrors[field] = false;
      // }
    },
    openDialog(mode, type, selectedItemId) {
      if (type === 'profile') {
        const userProfileStore = useUserProfileStore();
        this.profileOpen = true;
          // Only assign the name if it's not "Profile"
        this.selectedItem = {
          birthYear: userProfileStore.birthYear,
          name: userProfileStore.name !== 'Profile' ? userProfileStore.name : '' // Fallback to an empty string or another default value
        };
    
      }      
      else if (type === 'allocation') {
        this.allocationOpen = true;
      }
      
      else { 
        this.isOpen = true; 
      } 
      this.dialogMode = mode;
      this.selectedItemType = type;
      this.selectedItemId = selectedItemId;
    },

    resetUpdate() {
      this.update = false;
    },
    registerFields(fieldNames) {
      fieldNames.forEach(field => {
        // Set initial state for each field
        this.fields[field] = true; // Or any initial value you want
        this.fieldErrors[field] = false;
        this.dirtyFields[field] = false;
      });
    },
    // Method to remove a field
    removeField(fieldName) {
      // Delete the field from all state objects
      delete this.fields[fieldName];
      delete this.fieldErrors[fieldName];
      delete this.dirtyFields[fieldName];
    },
    setDirtyField(field, value) {
     this.dirtyFields[field] = value;
    },
    setErrorField(field, value) {
      this.fieldErrors[field] = value;
    },

    updateChart() {
      // Trigger the chart update function provided as a parameter
        this.update = true;
      // console.log("Update chart from dialogstore");
      },
  },
});
