<template>
    <div>
      <div class="flex justify-between sm:items-center md:m-0 px-4">
      </div>
      <div class="mt-4 flow-root">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div class="overflow-hidden  ring-1 ring-black ring-opacity-5">
              <table class="min-w-full divide-y divide-gray-300">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Month</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Income</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Expenditure</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Disposable Income</th>
                    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6"><span class="sr-only">Edit</span></th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr v-for="month in snapshotsStore.monthlyData" :key="month.month" @click="openRow('edit', month)" class="hover:bg-gray-50 cursor-pointer">
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ month.month }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ $formatNumber(month.income) }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ $formatNumber(month.expenditure) }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ $formatNumber(month.disposableIncome) }}</td>
                    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <button @click="openRow('edit', month)" class="text-indigo-600 hover:text-indigo-900">Edit<span class="sr-only">, {{ month.month }}</span></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <EditDialog v-if="snapshotsStore.savingsOpen" @close="closeDialog" />
    </div>
  </template>
  
  <script setup>
  import { useSnapshotsStore } from '../stores/SnapshotsStore.js'
//   import { ref, computed } from 'vue';
  import EditDialog from '@/components/HomeMonthlyDataEditDialog';
  
  const snapshotsStore = useSnapshotsStore();
  
  const openRow = (mode, month) => {
    snapshotsStore.openDialog(mode, 'savings', month);
  };
  
  const closeDialog = () => {
    snapshotsStore.closeDialog();
  };
  </script>
  
  <style scoped>
  /* Add any styles needed for your table component */
  </style>
  