<template>
    <div>
        <main class="lg:pl-72 overflow-hidden pb-1">
            <div class="lg:pl-72 xl:pl-80 2xl:pl-96 ">
                <!-------- Plan info card for mobile here-->      
                <div v-if="planStore.currentPlanId" class="lg:hidden">
                    <div class="rounded-lg bg-white p-4 mt-2 mx-2 border border-gray-200">  
                        
                        <div class="flex justify-between items-center">
                    <!-- Editable name: Flex item for value or input -->
                            <div class="flex-1 cursor-pointer" @click="toggleEditMode(planNameMobile, 'click')">
                                <div v-if="!editState[planNameMobile]">
                                    <!-- Display mode -->
                                    <h1 class="pl-[1px]">{{ planStore.getCurrentPlan.name }}</h1>
                                </div>
                                <div  v-else>
                                    <!-- Edit mode -->
                                    <input v-model="planStore.getCurrentPlan.name" data-name="planName" :ref="setInputRef(planNameMobile)" @blur="toggleEditMode(planNameMobile, 'blur')" @keyup.enter="$event.target.blur()" class="px-0 py-0 mb-[1px] mt-[1px] font-bold text-2xl text-violet-600" type="text">
                                </div>
                            </div>
                            <CogIcon class="h-6 w-6 text-slate-600 cursor-pointer hover:text-violet-500" aria-hidden="true" @click="dialogStore.openDialog('settings', 'settings', -1)" v-tooltip="{content: 'Plan settings',  theme: 'floating-vue-theme'}"/>
                        </div>                    

                    <div class="flex space-x-5 items-center">
                        <div class="">
                            <p class="text-sm font-medium text-gray-500">Year </p>
                            <div class="flex items-center gap-2">
                                <button type="button" class="rounded-full bg-white p-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                    <ChevronLeftIcon class="h-4 w-4 text-slate-600 cursor-pointer hover:text-violet-500" aria-hidden="true" @click="decrementYear"/>
                                </button>
                                <p class="font-medium">{{  planStore.selectedYear }}</p>
                                <button type="button" class="rounded-full bg-white p-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                    <ChevronRightIcon class="h-4 w-4 text-slate-600 cursor-pointer hover:text-violet-500" aria-hidden="true" @click="incrementYear"/>
                                </button>
                            </div>                            
                        </div>
                        <div class="">
                            <p class="text-sm font-normal text-gray-500">Age </p>
                            <p class="font-medium">{{  userProfileStore.getAgeAtYear(planStore.selectedYear) }}</p>
                        </div>                    
                    </div>
                    
                    <!-- Mobile More card content goes here -->
                    <div class="flex flex-wrap gap-x-4 gap-y-1 items-center mt-2 border-t pt-2">
                        <div class="w-auto">
                            <!-- Column 3: Net Worth -->
                            <div class="text-sm font-normal text-violet-400">Net Worth</div>
                                <div v-if="planStore.selectedYear" class="text-sm font-medium text-slate-900">
                                    {{ formatNumber(detailsForSelectedYear.netWorth, 2 , true) }} 
                                </div>
                            </div>

                            <div class="w-auto">
                                <!-- Column 1: Assets -->
                                <div class="text-sm font-normal text-violet-400">Assets</div>
                                <div v-if="planStore.selectedYear" class="text-sm font-medium text-slate-900">
                                    {{ formatNumber(detailsForSelectedYear.assets, 2 , true) }} 
                                </div>
                            </div>
                            <div class="w-auto">
                                <!-- Column 2: Liabilities -->
                                <div class="text-sm font-normal text-violet-400">Debts</div>
                                <div v-if="planStore.selectedYear" class="text-sm font-medium text-slate-900">
                                    {{ formatNumber(detailsForSelectedYear.debt, 2 , true) }} 
                                </div>
                            </div>

                            <div v-if="detailsForSelectedYear.savingsRate" class="w-auto">                        
                                <div class="text-sm font-normal text-violet-400">Savings rate</div>
                                <div v-if="planStore.selectedYear" class="text-sm font-medium text-slate-900">
                                    {{ detailsForSelectedYear.savingsRate }}<span class="text-xs">%</span>
                                </div>
                            </div>

                            <div v-if="detailsForSelectedYear.profit" class="w-auto">                        
                                <div class="text-sm font-normal text-violet-400">Discretionary income</div>
                                <div v-if="planStore.selectedYear" class="text-sm font-medium text-slate-900">
                                    {{  formatNumber(detailsForSelectedYear.profit, 1 , true)}} <span class="text-xs">({{formatNumber((detailsForSelectedYear.profit / 12), 1 , true)}} pm)</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="rounded-lg  bg-white px-4 py-4 mx-2 md:mx-4 mt-2 md:mt-4 lg:px-4 lg:py-4 h-[48vh] lg:h-[54dvh] lg:inset-y-0 shadow ring-1 ring-black ring-opacity-5 rounded-lg min-h-80">
                    <!-- Main area content goes here -->
                    <PlanChart />
                </div>

                  

                <PlanCardRates card-title="Rates" :compact="true" />

                <div class="flex flex-wrap gap-x-2 md:gap-x-4 md:justify-evenly px-2 lg:px-4 md:pb-4 ">
                    <!-- Cards container content goes here -->
                    <PlanSavingsAllocation />
                    <PlanCardTargets />                   
                    
                </div>
            </div>
        </main>


        <aside class="bg-gray-100 border-r border-gray-200 px-2 md:py-4 overflow-y-scroll lg:fixed lg:inset-y-0 lg:left-72 lg:w-72 xl:w-80 2xl:w-96 lg:px-4">

<!---------- Desktop plan info card content here -->
            <div v-if="planStore.currentPlanId" class="hidden lg:block">
                <div class="rounded-lg bg-white p-3.5 sm:p-3.5 border border-gray-200">   
                    <div class="flex justify-between items-center">

                        <!-- Editable name: Flex item for value or input -->
                        <div class="flex-1 mr-2 px-0 py-0 cursor-pointer" @click="toggleEditMode(planName, 'click')">
                            <div v-if="!editState[planName]">
                                <!-- Display mode -->
                                <h1 class="w-full pl-[1px] mt-[1px] mb-[1px] hover:mt-0 hover:p-0 hover:border hover:border-gray-200 hover:border-solid hover:mb-0 rounded">{{ planStore.getCurrentPlan.name }}</h1>
                            </div>
                            <div  v-else>
                                <!-- Edit mode -->
                                <input v-model="planStore.getCurrentPlan.name" data-name="planName" :ref="setInputRef(planName)" @blur="toggleEditMode(planName, 'blur')" @keyup.enter="$event.target.blur()" class="w-full px-0 py-0 mb-[2px] mt-[2px] font-bold text-2xl text-violet-600 rounded" type="text">
                            </div>
                        </div>

                        <CogIcon class="h-6 w-6 text-slate-600 cursor-pointer hover:text-indigo-500" aria-hidden="true" @click="dialogStore.openDialog('settings', 'settings', -1   )" v-tooltip="{content: 'Plan settings',  theme: 'floating-vue-theme'}"/>
                    </div>                           

                <div class="flex space-x-5 items-center">
                    <div class="">
                        <p class="text-sm font-normal text-gray-500">Year </p>
                        <div class="flex items-center gap-2">
                                <button type="button" class="rounded-full bg-white p-1 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                    <ChevronLeftIcon class="h-3 w-3 text-slate-600 cursor-pointer hover:text-violet-500" aria-hidden="true" @click="decrementYear"/>
                                </button>
                                <p class="font-medium w-11 text-center">{{  planStore.selectedYear }}</p>
                                <button type="button" class="rounded-full bg-white p-1 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                    <ChevronRightIcon class="h-3 w-3 text-slate-600 cursor-pointer hover:text-violet-500" aria-hidden="true" @click="incrementYear"/>
                                </button>
                            </div>   
                    </div>
                    <div class="">
                        <p class="text-sm font-normal text-gray-500">Age </p>
                        <p class="font-medium">{{  userProfileStore.getAgeAtYear(planStore.selectedYear) }}</p>
                    </div>                    
                </div>
                
                <!-- Desktop More aside content goes here -->
                <div class="flex flex-wrap gap-x-4 gap-y-1 items-center mt-2 border-t pt-2">
                    <div class="w-auto">
                        <!-- Column 3: Net Worth -->
                        <div class="text-sm font-normal text-violet-400">Net Worth</div>
                        <div v-if="planStore.selectedYear" class="text-sm font-medium text-slate-900" v-tooltip="{content: formatNumber(detailsForSelectedYear.netWorth, 2 , false),  theme: 'floating-vue-theme'}">
                            {{ formatNumber(detailsForSelectedYear.netWorth, 1 , true) }} 
                        </div>
                    </div>

                    <div class="w-auto">
                        <!-- Column 1: Assets -->
                        <div class="text-sm font-normal text-violet-400">Assets</div>
                        <div v-if="planStore.selectedYear" class="text-sm font-medium text-slate-900" v-tooltip="{content: formatNumber(detailsForSelectedYear.assets, 2 , false),  theme: 'floating-vue-theme'}">
                            {{ formatNumber(detailsForSelectedYear.assets,  1 , true) }} 
                        </div>
                    </div>
                    <div class="w-auto">
                        <!-- Column 2: Liabilities -->
                        <div class="text-sm font-normal text-violet-400">Debts</div>
                        <div v-if="planStore.selectedYear" class="text-sm font-medium text-slate-900" v-tooltip="{content: formatNumber(detailsForSelectedYear.debt, 2 , false),  theme: 'floating-vue-theme'}">
                            {{ formatNumber(detailsForSelectedYear.debt, 1 , true) }} 
                        </div>
                    </div>

                    <div v-if="detailsForSelectedYear.savingsRate" class="w-auto">                        
                        <div class="text-sm font-normal text-violet-400">Savings rate</div>
                        <div v-if="planStore.selectedYear" class="text-sm font-medium text-slate-900">
                            {{ detailsForSelectedYear.savingsRate }}<span class="text-sm">%</span>
                        </div>
                    </div>

                    <div v-if="detailsForSelectedYear.profit" class="w-auto">                        
                                <div class="text-sm font-normal text-violet-400">Discretionary income</div>
                                <div v-if="planStore.selectedYear" class="text-sm font-medium text-slate-900" >
                                    <span v-tooltip="{content: formatNumber(detailsForSelectedYear.profit, 2 , false),  theme: 'floating-vue-theme'}">{{formatNumber(detailsForSelectedYear.profit, 1 , true)}}</span> <span class="text-xs" v-tooltip="{content: formatNumber((detailsForSelectedYear.profit / 12), 2 , false),  theme: 'floating-vue-theme'}">({{formatNumber((detailsForSelectedYear.profit / 12), 1 , true)}} pm)</span>
                                </div>
                    </div>


                </div>
                </div>
            </div>
            <div class="grid gap-2 md:gap-4 pb-4 py-1 md:py-4">
                <!-- Check if currentPlan is defined before accessing its properties -->
                <PlanCard v-if="incomeDetails" card-title="Income" :card-details="incomeDetails"/>
                <PlanCard v-if="expensesDetails" card-title="Expenses" :card-details="expensesDetails" />
                
                <PlanCard card-title="Savings & Investments" :card-details="savingsDetails"/>        
                <PlanCard card-title="Physical assets" :card-details="physicalAssetsDetails"/>
                <PlanCard card-title="Debt" :card-details="debtDetails"/>                
            </div>
            
            <PlanEditDialog />   
            <PlanEditAllocationDialog /> 
            
        </aside>
    </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { onMounted, onUnmounted, watchEffect, computed, reactive, nextTick } from 'vue';
import { usePhysicalAssetsStore } from '@/stores/PhysicalAssetsStore.js';
import { useSavingsStore } from '@/stores/SavingsStore.js';
import { useDebtStore } from '@/stores/DebtStore.js';
import { useIncomeStore } from '@/stores/IncomeStore';
import { useExpensesStore } from '@/stores/ExpensesStore';
import { formatNumber } from '@/utils/formatNumber';

import { useUserProfileStore } from '@/stores/UserProfile';
import { usePlanStore } from '@/stores/PlanStore';
import { useDialogStore } from '@/stores/DialogStore';

import { CreditCardIcon, CurrencyDollarIcon, KeyIcon, InboxArrowDownIcon, ShoppingCartIcon, CogIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/solid'   

import PlanChart from '@/components/PlanChart.vue';
import PlanCard from '@/components/PlanCard';
import PlanCardTargets from '@/components/PlanCardTargets';
import PlanCardRates from './PlanCardRates.vue';
import PlanEditDialog from './PlanEditDialog';
import PlanEditAllocationDialog from './PlanEditAllocationDialog.vue';
import PlanSavingsAllocation from '@/components/PlanSavingsAllocation.vue'

const planStore = usePlanStore();
const dialogStore = useDialogStore();
const route = useRoute();
const userProfileStore = useUserProfileStore();

const editState = reactive({});
const inputRefs = reactive({});

const planName = "planName";
const planNameMobile = "planNameMobile";

//hold which item is being edited
function setInputRef(name) {
  return (el) => {
    if (el) {
      inputRefs[name] = el;
    }
  };
}
//editable cell
function toggleEditMode(cellName, method) {
  // Check if the cell is already in edit mode
  
    if (editState[cellName] && method === "click" ) {
    // If the cell is already in edit mode, return early to ignore the click
    
    return;
  }
  // Toggle the edit mode
  editState[cellName] = !editState[cellName];  

  // Move focus to cell if edit mode is toggled on
  if (editState[cellName]) {
    nextTick(() => {
      if (inputRefs[cellName]) {
        inputRefs[cellName].focus();
      }
    });
  }
}

// Set current plan based on route params
watchEffect(() => {
  planStore.setCurrentPlan(Number(route.params.id));
});

// Use a computed property to get the selected year's details from the store
const detailsForSelectedYear = computed(() => {
  if (!planStore.selectedYear) {
    return { netWorth: 0 }; // Add other properties as needed
  }
  return planStore.getDetailsForSelectedYear || { netWorth: 0 }; // Provide default values if getDetailsForYear returns null
});


const savingsStore = useSavingsStore();
const savingsDetails = computed(() => {
    let combinedItems = [];

    // Add items from the store to combinedItems
    savingsStore.items.forEach(storeItem => {
        combinedItems.push(storeItem);
        
    });

    // Add savings items from the current plan, overwriting existing items in combinedItems
    if (planStore.getCurrentPlan && planStore.getCurrentPlan.savings) {
        planStore.getCurrentPlan.savings.forEach(planItem => {
            const index = combinedItems.findIndex(item => item.id === planItem.id);
            if (index !== -1) {
                // If the item exists in combinedItems, replace it with the one from the current plan
                combinedItems[index] = planItem;
            } else {
                // If the item doesn't exist in combinedItems, add it
                combinedItems.push(planItem);                
            }
        });
    }

    // Filter out items with the exclude flag from combinedItems
    combinedItems = combinedItems.filter(item => !item.exclude);

    return {
        type: 'savings',
        component: CreditCardIcon, // Assuming you have imported CreditCardIcon
        iconColor: 'text-violet-700',
        headerColor: 'bg-slate-100 hover:bg-slate-50',
        group: combinedItems,
        currency: true
    };
});

// Import necessary functions and icons

// For incomeDetails
const incomeStore = useIncomeStore();
const incomeDetails = computed(() => {
    let combinedItems = [];

    // Add items from the incomeStore to combinedItems
    combinedItems = combinedItems.concat(incomeStore.items);

    // Add income items from the current plan, overwriting existing items in combinedItems
    if (planStore.getCurrentPlan && planStore.getCurrentPlan.income) {
        planStore.getCurrentPlan.income.forEach(planItem => {
            const index = combinedItems.findIndex(item => item.id === planItem.id);
            if (index !== -1) {
                combinedItems[index] = planItem; // If the item exists in combinedItems, replace it with the one from the current plan
            } else {
                combinedItems.push(planItem); // If the item doesn't exist in combinedItems, add it
            }
        });
    }

    // Filter out items with the exclude flag from combinedItems
    combinedItems = combinedItems.filter(item => !item.exclude);

    return {
        type: 'income',
        component: InboxArrowDownIcon,
        iconColor: 'text-green-600',
        headerColor: 'bg-slate-100 hover:bg-slate-50',
        group: combinedItems,
        currency: true
    };
});

// For expensesDetails
const expensesStore = useExpensesStore();
const expensesDetails = computed(() => {
    let combinedItems = [];

    // Add items from the expensesStore to combinedItems
    combinedItems = combinedItems.concat(expensesStore.items);

    // Add expenses items from the current plan, overwriting existing items in combinedItems
    if (planStore.getCurrentPlan && planStore.getCurrentPlan.expenses) {
        planStore.getCurrentPlan.expenses.forEach(planItem => {
            const index = combinedItems.findIndex(item => item.id === planItem.id);
            if (index !== -1) {
                combinedItems[index] = planItem; // If the item exists in combinedItems, replace it with the one from the current plan
            } else {
                combinedItems.push(planItem); // If the item doesn't exist in combinedItems, add it
            }
        });
    }

    // Filter out items with the exclude flag from combinedItems
    combinedItems = combinedItems.filter(item => !item.exclude);

    return {
        type: 'expenses',
        component: ShoppingCartIcon,
        iconColor: 'text-red-600',
        headerColor: 'bg-slate-100 hover:bg-slate-50',
        group: combinedItems,
        currency: true
    };
});

// For physicalAssetsDetails
const physicalAssetsStore = usePhysicalAssetsStore();
const physicalAssetsDetails = computed(() => {
    let combinedItems = [];

    // Add items from the physicalAssetsStore to combinedItems
    combinedItems = combinedItems.concat(physicalAssetsStore.items);

    // Add physical assets items from the current plan, overwriting existing items in combinedItems
    if (planStore.getCurrentPlan && planStore.getCurrentPlan.physicalAssets) {
        planStore.getCurrentPlan.physicalAssets.forEach(planItem => {
            const index = combinedItems.findIndex(item => item.id === planItem.id);
            if (index !== -1) {
                combinedItems[index] = planItem; // If the item exists in combinedItems, replace it with the one from the current plan
            } else {
                combinedItems.push(planItem); // If the item doesn't exist in combinedItems, add it
            }
        });
    }

    // Filter out items with the exclude flag from combinedItems
    combinedItems = combinedItems.filter(item => !item.exclude);

    return {
        type: 'physicalAssets',
        component: KeyIcon,
        iconColor: 'text-yellow-500',
        headerColor: 'bg-slate-100 hover:bg-slate-50',
        group: combinedItems,
        currency: true
    };
});

// For debtDetails
const debtStore = useDebtStore();
const debtDetails = computed(() => {
    let combinedItems = [];

    // Add items from the debtStore to combinedItems
    combinedItems = combinedItems.concat(debtStore.items);

    // Add debt items from the current plan, overwriting existing items in combinedItems
    if (planStore.getCurrentPlan && planStore.getCurrentPlan.debts) {
        planStore.getCurrentPlan.debts.forEach(planItem => {
            const index = combinedItems.findIndex(item => item.id === planItem.id);
            if (index !== -1) {
                combinedItems[index] = planItem; // If the item exists in combinedItems, replace it with the one from the current plan
            } else {
                combinedItems.push(planItem); // If the item doesn't exist in combinedItems, add it
            }
        });
    }

    // Filter out items with the exclude flag from combinedItems
    combinedItems = combinedItems.filter(item => !item.exclude);

    return {
        type: 'debts',
        component: CurrencyDollarIcon,
        iconColor: 'text-blue-600',
        headerColor: 'bg-slate-100 hover:bg-slate-50',
        group: combinedItems,
        currency: true
    };
});

const handleKeyDown = (event) => {
    if (event.key === 'ArrowLeft') {             
        if (planStore.selectedYear > planStore.getCurrentPlan.startYear) {            
            planStore.selectedYear--;
        }
    } else if (event.key === 'ArrowRight') {
        if (planStore.selectedYear < planStore.getMaxYear) {
            planStore.selectedYear++;
        }
    }
};

function incrementYear() {
  if (planStore.selectedYear < planStore.getCurrentPlanEndYear) {
    planStore.selectedYear++;
  }
}

function decrementYear() {
  if (planStore.selectedYear > planStore.getCurrentPlan.startYear) {
    planStore.selectedYear--;
  }
}

// On mounted hook
onMounted(() => {
  planStore.updateProjectionData();
  // Initialize the selectedYear to the last year in the projection data
  planStore.initializeSelectedYear();    
  window.addEventListener('keydown', handleKeyDown);

});

// Remove event listener when component is unmounted
onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown);
});

</script>

<style>
html {
    background-color: white;
}
</style>