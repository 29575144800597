<template>
  <TransitionRoot appear :show="snapshotsStore.isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">

              
            <form @submit.prevent="saveChanges">
              <div class="mt-3 text-left sm:mt-5">
                <div class="flex justify-between mb-3 text-left">
                        <div>
                            <DialogTitle >
                                <div class="text-base font-normal leading-6 text-gray-500">{{ snapshotsStore.dialogMode === 'add' ? 'Add' : 'Edit' }}</div>
                                <h1>Snapshot</h1>                  
                            </DialogTitle>
                        </div>
                        <div class="flex items-center gap-x-4">
                            <button @click="deleteItem()" class="inline-flex items-center gap-x-1.5 h-8 rounded bg-white px-2 py-1 text-xs font-semibold text-red-700  hover:bg-red-50"> <!-- Use inline-flex here -->
                                    <TrashIcon class="h-4 w-4" aria-hidden="true" />
                                    <span>Delete</span>
                            </button>
                            <button @click="snapshotsStore.closeDialog()" class="inline-flex items-center gap-x-1.5 h-8 rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"> <!-- Use inline-flex here -->
                                    <XMarkIcon class="h-4 w-4" aria-hidden="true" />
                                    <span>Close</span>
                            </button>    
                        </div>
                    </div>  
                                
                  <label class="block mt-4 text-sm font-medium text-gray-700">Date</label>
                  <div class="relative rounded-md py-1.5 px-3 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">                    
                    <input id="date" type="date" v-model="selectedSnapshot['date']" placeholder="Date of snapshot" class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" />
                  </div>
                

                <div v-for="field in fields" :key="field.model" class="mt-4">
                  <label :for="field.model" class="block text-sm font-medium text-gray-700">{{ field.label }}</label>
                  <div class="relative rounded-md py-1.5 px-3 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                    <div class="pointer-events-none absolute top-2 left-0 flex items-center pl-3">
                      <span class="text-gray-500 sm:text-sm">{{userProfileStore.preferredCurrency}}</span>
                    </div>
                    <input :id="field.model" :type="field.type" v-model="selectedSnapshot[field.model]" :placeholder="field.placeholder" class="block w-full border-0 p-0 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" />
                  </div>
                </div>                

              </div>

              <div class="mt-5 sm:mt-6 flex flex-row gap-3">
                                
                <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white p-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0" @click="snapshotsStore.closeDialog()" ref="cancelButtonRef">Cancel</button>
                <button type="submit" class="inline-flex w-full justify-center rounded-md bg-indigo-600 p-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2">Save</button>
              </div>
            </form>
                    
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
  
<script setup>
  import { computed } from 'vue'
  import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
  import { XMarkIcon, TrashIcon } from '@heroicons/vue/24/solid';
  //get snapshots from snapshot store and dialog state from dialog store
  import { useSnapshotsStore } from '../stores/SnapshotsStore';
  import { useUserProfileStore } from '@/stores/UserProfile';
  
  const userProfileStore = useUserProfileStore();
  const snapshotsStore = useSnapshotsStore();
  const selectedSnapshot = computed(() => snapshotsStore.selectedSnapshot);

  const saveChanges = () => {
      snapshotsStore.updateSnapshot();  
      snapshotsStore.sortSnapshotsByDate();
      snapshotsStore.closeDialog();
  };

  const deleteItem = () => {
    const id = selectedSnapshot.value.id;
    snapshotsStore.deleteSnapshot(id);
    snapshotsStore.sortSnapshotsByDate();
    snapshotsStore.closeDialog();
  };

  const fields = [
  // { label: 'Date', model: 'date', type: 'date' },
  { label: 'Assets Total', model: 'assetsTotal', type: 'number', placeholder: 'Total Assets' },
  { label: 'Savings Total', model: 'savingsTotal', type: 'number', placeholder: 'Total Savings' },
  { label: 'Debt Total', model: 'debtsTotal', type: 'number', placeholder: 'Total Debt' },
  // Add more field definitions as necessary
  ];

  
</script>
  

