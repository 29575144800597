import { defineStore } from 'pinia';
import { usePlanStore } from '@/stores/PlanStore';
import { useUserProfileStore } from '@/stores/UserProfile';
export const useTaxStore = defineStore('tax', {
  state: () => ({
    // Income tax rates
    incomeTaxRates: {
      basicRate: 20,
      higherRate: 40,
      additionalRate: 45,
    },    
    // Income tax thresholds
    incomeTaxThresholds: {
      basicRateThreshold: 12570,
      higherRateThreshold: 50270,
      additionalRateThreshold: 125140,
      incomeLimitForPersonalAllowance: 100000,
    },
    // Capital gains tax rates
    capitalGainsTaxRates: {
      basicRate: 10,
      higherRate: 20,
    },
    // State pension age
    statePensionAge: 67,
    normalMinimumPensionAge :57,
    annualAllowance: 60000,
    // National Insurance rates
    nationalInsurance: [
      { band: 1, rate: 0, thresholdBottom: 0, threshold: 12570 },
      { band: 2, rate: 8, thresholdBottom: 12570, threshold: 50270 },
      { band: 3, rate: 2, thresholdBottom: 50270, threshold: null },
    ],
    // Student loan repayment plans
    studentLoanRepaymentPlans: {
      plan1: {
        annualThreshold: 24990,
        monthlyThreshold: 2082,
        weeklyThreshold: 480,
        repaymentRate: 9,
        interestRate: 6.25,
      },
      plan2: {
        annualThreshold: 27295,
        monthlyThreshold: 2274,
        weeklyThreshold: 524,
        repaymentRate: 9,
        interestRate: 7.9,
      },
      plan4: {
        annualThreshold: 31395,
        monthlyThreshold: 2616,
        weeklyThreshold: 603,
        repaymentRate: 9,
        interestRate: 6.25,
      },
      plan5: {
        annualThreshold: 25000,
        monthlyThreshold: 2083,
        weeklyThreshold: 480,
        repaymentRate: 9,
        interestRate: 7.9,
      },
      postgraduateLoan: {
        annualThreshold: 21000,
        monthlyThreshold: 1750,
        weeklyThreshold: 403,
        repaymentRate: 6,
        interestRate: 7.9,
      },
    },
  }),

  getters: {
    // Getters for income tax rates
    getIncomeTaxRates(state) {
      return state.incomeTaxRates;
    },
    // Getters for income tax thresholds
    getIncomeTaxThresholds() {
      return this.incomeTaxThresholds;
    },
    // Getters for capital gains tax rates
    getCapitalGainsTaxRates() {
      return this.capitalGainsTaxRates;
    },
    // Getter for National Insurance rates
    getNationalInsuranceRates() {
      return this.nationalInsurance;
    },
    // Getter for student loan repayment plans
    getStudentLoanRepaymentPlans() {
      return this.studentLoanRepaymentPlans;
    },
    // Getter for a specific student loan repayment plan
    getStudentLoanRepaymentPlan: (state) => (planType) => {
      return state.studentLoanRepaymentPlans[planType];
    },
    getStudentLoanPlanFromStartYear: () => (item) => {
      let startYear = item.startYear;
      if (!Number.isInteger(item.startYear)) {
        const planStore = usePlanStore();
        startYear = planStore.getYear(item, 'start');
      }      
      if (startYear >= 2023) {
        return 'plan5';
      } 
      else if (startYear >= 2012 && startYear < 2023) {
        return 'plan2';
      }
       else if (startYear < 2012) {
        return 'plan1';
      } 
      else {
        return 'unknown'; // handle cases where the start year does not match any known plans
      }
    },
    getStudentLoanEndYear() {
      return (loan) => {
        const startYear = loan.startYear;
        const planType = this.getStudentLoanPlanFromStartYear(loan);  // Referencing another getter
        const userProfile = useUserProfileStore();
        
        if (!Number.isInteger(startYear)) {
          return 'invalid start year';
        }
      
        let endYear;
      
        switch (planType) {
          case 'plan1':
            if (startYear >= 2006) {
              endYear = startYear + 25;
            } else {
              endYear = userProfile.getYearAtAge(65); // Assuming userProfile provides the user's birth year
            }
            break;
          case 'plan2':
            endYear = startYear + 30;
            break;
          case 'plan4':
            if (startYear >= 2007) {
              endYear = startYear + 30;
            } else {
              const age65Year = userProfile.getYearAtAge(65);
              endYear = Math.min(startYear + 30, age65Year);
            }
            break;
          case 'plan5':
            endYear = startYear + 40;
            break;
          case 'postgraduateLoan':
            endYear = startYear + 30;
            break;
          default:
            return 'unknown plan type';
        }
      
        return endYear;
      };
    },
    getStudentLoanEndYearFromStartYear() {
      return (startYear) => {
        const planType = this.getStudentLoanPlanFromStartYear({ startYear });
        return this.getStudentLoanEndYear({ startYear, planType });
      };
    },
    
  },

  actions: {
    calculateTax(totalTaxableIncome, year) {
      const userProfile = useUserProfileStore();
      const planStore = usePlanStore();
      let personalAllowance = 0;
      let yearlyIncomeTax = 0;
    
      if (totalTaxableIncome > 0) {
        const incomeTaxRates = this.incomeTaxRates;
        const incomeTaxThresholds = this.incomeTaxThresholds;
    
        // Calculate the reduced Personal Allowance based on income
        let excessIncome = totalTaxableIncome - incomeTaxThresholds.incomeLimitForPersonalAllowance;
        let allowanceReduction = Math.floor(excessIncome / 2);
    
        // Apply reduction to the initial personal allowance
        personalAllowance = Math.max(0, incomeTaxThresholds.basicRateThreshold - allowanceReduction);
        personalAllowance = Math.min(incomeTaxThresholds.basicRateThreshold, personalAllowance); 
    
        // Calculate tax on income over the personal allowance
        if (totalTaxableIncome > personalAllowance) {
          // Calculate basic rate tax
          let basicRateTax = (Math.min(totalTaxableIncome, incomeTaxThresholds.higherRateThreshold) - personalAllowance) * (incomeTaxRates.basicRate / 100);
          yearlyIncomeTax += basicRateTax;
    
          // Calculate higher rate tax if applicable
          if (totalTaxableIncome > incomeTaxThresholds.higherRateThreshold) {
            let higherRateTax = (Math.min(totalTaxableIncome, incomeTaxThresholds.additionalRateThreshold) - incomeTaxThresholds.higherRateThreshold) * (incomeTaxRates.higherRate / 100);
            yearlyIncomeTax += higherRateTax;
    
            // Calculate additional rate tax if applicable
            if (totalTaxableIncome > incomeTaxThresholds.additionalRateThreshold) {
              let additionalRateTax = (totalTaxableIncome - incomeTaxThresholds.additionalRateThreshold) * (incomeTaxRates.additionalRate / 100);
              yearlyIncomeTax += additionalRateTax;
            }
          }
        }
    
        // National Insurance Calculation
        if (planStore.getCurrentPlan.nationalInsurance && userProfile.getAgeAtYear(year) < (planStore.getCurrentPlan.settings?.statePensionAge || this.statePensionAge)) {
          let nationalInsuranceTax = 0;
    
          for (const band of this.nationalInsurance) {
            let taxableWithinBand = Math.max(0, Math.min(totalTaxableIncome - band.thresholdBottom, (band.threshold || totalTaxableIncome) - band.thresholdBottom));
            nationalInsuranceTax += taxableWithinBand * (band.rate / 100);
    
            if (totalTaxableIncome <= band.thresholdBottom || band.threshold === null) {
              break;
            }
          }
    
          yearlyIncomeTax += nationalInsuranceTax;                        
        }                                      
      }
    
      return yearlyIncomeTax;
    },

    calculateGrossIncomeForDesiredTakeHome(desiredTakeHome, year) {
      const tolerance = 0.01;
      let lowerBound = 0;
      let upperBound = desiredTakeHome * 2;
      let guessIncome = (upperBound + lowerBound) / 2;

      while (upperBound - lowerBound > tolerance) {
        const taxAmount = this.calculateTax(guessIncome, year);
        const takeHome = guessIncome - taxAmount;

        if (takeHome < desiredTakeHome) {
          lowerBound = guessIncome;
        } else {
          upperBound = guessIncome;
        }

        guessIncome = (upperBound + lowerBound) / 2;
      }
      
      return guessIncome;
    },
    
    
    // Action to update income tax rates
    updateIncomeTaxRates(newRates) {
      this.incomeTaxRates = newRates;
    },
    // Action to update income tax thresholds
    updateIncomeTaxThresholds(newThresholds) {
      this.incomeTaxThresholds = newThresholds;
    },
    // Action to update capital gains tax rates
    updateCapitalGainsTaxRates(newRates) {
      this.capitalGainsTaxRates = newRates;
    },
    // Action to update National Insurance rates
    updateNationalInsuranceRates(newRates) {
      this.nationalInsurance = newRates;
    },
    // Action to update student loan repayment plans
    updateStudentLoanRepaymentPlans(newPlans) {
      this.studentLoanRepaymentPlans = newPlans;
    },
  },
});
