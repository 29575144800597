<template>
  <div class="flex flex-col gap-2 h-full ">
    <div class="flex flex-row gap-2 justify-between w-full">

      <fieldset aria-label="Choose a chart option">
        <RadioGroup v-model="selectedOption" class="flex flex-row gap-2">
          <RadioGroupOption as="template" v-for="option in menuOptions" :key="option.value" :value="option" v-slot="{ active, checked }">
            <div 
              :class="[
                option.enabled ? 'cursor-pointer focus:outline-none border-transparent focus:border-transparent focus:ring-0 ' : 'cursor-not-allowed opacity-25',
                active ? 'ring-0' : '',
                checked ? 'bg-indigo-100 text-indigo-700 hover:bg-indigo-200' : 'bg-white text-gray-500 hover:bg-gray-50',
                !active && !checked ? 'ring-inset' : '',
                active && checked ? 'ring-2' : '',
                'flex items-center justify-center rounded-md px-3 py-2 text-sm font-semibold'
              ]" 
              @click="selectChartDataOption(option)">
              {{ option.label }}
            </div>
          </RadioGroupOption>
        </RadioGroup>
      </fieldset>

      <div class="flex items-center space-x-4">
        <span class="text-sm font-medium text-gray-900">{{ xAxisOptions[0].label }}</span>
        <SwitchGroup as="div" class="flex items-center">
          <Switch v-model="isAgeSelected" :class="[isAgeSelected ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
            <span aria-hidden="true" :class="[isAgeSelected ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
          </Switch>
        </SwitchGroup>
        <span class="text-sm font-medium text-gray-900">{{ xAxisOptions[1].label }}</span>
      </div>

    </div>
    <div ref="planChart" style="width: 100%; height: 100%;" class="overflow-hidden h-full"></div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch, nextTick } from 'vue';
import * as echarts from 'echarts';
import { usePlanStore } from '@/stores/PlanStore';
import { useUserProfileStore } from '@/stores/UserProfile';
import { formatNumber } from '@/utils/formatNumber';
import { Switch, SwitchGroup } from '@headlessui/vue'
import { RadioGroup, RadioGroupOption } from '@headlessui/vue'
import { chartColors } from '@/utils/colors';

const planStore = usePlanStore();
const userProfile = useUserProfileStore();
let instance;
let textOffset = 5; // Adjust the offset according to your preference

// Use a boolean to determine which option is selected
const isAgeSelected = ref(userProfile.selectedXAxis === 'age');

// Define x-axis options
const xAxisOptions = [
  { value: 'year', label: 'Year' },
  { value: 'age', label: 'Age' }
];

// Define the options for the dropdown
const menuOptions = [
  { label: 'Net Worth', value: 'netWorth', enabled: true },
  { label: 'Savings', value: 'savings', enabled: true }
];

if (!planStore.getCurrentPlan || !planStore.getCurrentPlan.projection || !planStore.getCurrentPlan.projection.data || !planStore.getCurrentPlan.projection.labels) {
  planStore.updateProjectionData();
  // console.log('chart data error:', planStore.getCurrentPlan?.projection);
}


const projectionData = computed(() => planStore.getCurrentPlan.projection);

// Ensure the selected option is set to a valid option on first load
if (!userProfile.planChartOptionSelected) {
  userProfile.planChartOptionSelected = menuOptions[0];
}

// Initialize selectedOption with the current selection or the first available option
const selectedOption = ref(menuOptions.find(item => item.value === userProfile.planChartOptionSelected.value) || menuOptions[0]);

// Function to handle option selection
const selectChartDataOption = (option) => {
  userProfile.planChartOptionSelected = option;
  selectedOption.value = option;
  updateChartData(option.value, true);
};

// Function to update chart data based on selected option
const updateChartData = (selectedData = userProfile.planChartOptionSelected.value, shouldReplace = false) => {  
  let seriesData = [];

  if (selectedData === 'netWorth') {
    // Single series for net worth
    seriesData.push({
      name: 'Net Worth',
      type: 'line', // or 'bar', depending on your chart type      
      data: projectionData.value.data.netWorth,
      showSymbol: false,
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            { offset: 0, color: 'rgba(151, 71, 255, 0.7)' },
            { offset: 1, color: 'rgba(204, 165, 255, 0.1)' }
          ],
          global: false
        },
        opacity: 0.8
      },
      triggerLineEvent: true,
      lineStyle: { color: '#4f46e5' },
      markline: {}
    });
  } 
  else if (selectedData === 'savings') {
    // Step 1: Check if savings data exists and has accounts
    if (Array.isArray(projectionData.value.data.savings) && projectionData.value.data.savings.length > 0 && projectionData.value.data.savings[0].accounts) {
      // Sort accounts based on `includeInAllocation` flag
      const sortedAccounts = [...projectionData.value.data.savings[0].accounts].sort((a, b) => {
        if (a.includeInAllocation === b.includeInAllocation) {
          return 0;
        }
        return a.includeInAllocation ? -1 : 1;
      });

      // Iterate over each sorted account and create a series for each
      sortedAccounts.forEach(account => {
        seriesData.push({
          name: account.name,
          id: account.id,
          type: 'line', // or 'bar', depending on your chart type
          showSymbol: false,
          data: projectionData.value.data.savings.map(yearData => {
            const acc = yearData.accounts.find(a => a.name === account.name);
            return acc ? acc.value : 0; // Handle missing data gracefully
          })
        });
      });

      // Add a series for the yearTotal
      seriesData.push({
        name: 'Total Savings',
        type: 'line', // or 'bar', depending on your chart type
        data: projectionData.value.data.savings.map(yearData => yearData.yearTotal),
        showSymbol: false,
      });

      chartOptions.value.color = chartColors;
    } 
  }

  // Update the chart options with the new series data
  const setOptionParams = shouldReplace ? { replaceMerge: ['series'] } : {};  
  chartOptions.value.series = seriesData;
  instance.setOption(chartOptions.value, setOptionParams);
};


const calculateXAxis = (target) => {
  // Calculate xAxis value based on target's value and userProfile.birthYear
  let position;
  if (target.type === 'age') {
    position = target.value + userProfile.birthYear;
    if (userProfile.getSelectedXAxis === 'age') {
      position = userProfile.getAgeAtYear(position);
    }
  } 
  else if (target.type === 'year') {
    position = String(target.value);
  }
  return String(position);
};

const calculateYAxis = (target) => {
  // Calculate yAxis value based on target's value
  return target.value;
};

//get targets
const targets = computed(() => {
  return planStore.getCurrentPlan.targets.map(target => {
    if (target.type === 'financial goal') {
      return {
        name: target.name,
        yAxis: calculateYAxis(target),
        label: target.name,
        id: target.id,
      };
    } else {
      return {
        name: target.name,
        xAxis: calculateXAxis(target),
        label: target.name,
        id: target.id,
      };
    }
  });
});

//swith xAxis between year and age
const updateChartXAxis = () => {
  if (instance) {
    const option = instance.getOption();
    if (userProfile.selectedXAxis === 'year') {
      option.xAxis[0].data = projectionData.value.labels;
    } else if (userProfile.selectedXAxis === 'age') {
      option.xAxis[0].data = projectionData.value.data.age;
    }
    instance.setOption(option);
  }
  updateTargetGraphics();
};

// Watch for changes in the boolean value and update selectedXAxis
watch(isAgeSelected, (newVal) => {
  userProfile.selectedXAxis = newVal ? xAxisOptions[1].value : xAxisOptions[0].value
  updateChartXAxis(userProfile.selectedXAxis)
})

     
// Tooltip content reactive reference
const tooltipContent = ref('');     

// Update the tooltip content for normal tooltip
const updateTooltipContent = (params) => {
  tooltipContent.value = formatTooltipContent(params);
};

function formatTooltipContent(params) {
   // Extract data index and base information
  const index = params[0].dataIndex;
  const year = projectionData.value.labels[index] 
  const age = userProfile.getAgeAtYear(year);
  tooltipContent.value = `<strong>Year: </strong>${year}<br/><strong>Age: </strong>${age}`;  

  if (userProfile.planChartOptionSelected.value === 'netWorth') {   
    // Adding financial details
    const details = {
      'Net Worth': params[0].value,
      'Income after tax': projectionData.value.data.income[index],
      'Expenses': projectionData.value.data.expenses[index],
      'Savings': projectionData.value.data.savings[index].yearTotal,
      'Physical assets': projectionData.value.data.physicalAssets[index],
      'Debt': projectionData.value.data.debt[index],
      'Discretionary income' : projectionData.value.data.profit[index],
    };

    // Append formatted financial details to the tooltip
    Object.keys(details).forEach(key => {
      tooltipContent.value += `<br/><strong>${key}: </strong>${formatNumber(details[key])}`;
    });
  }
  else if (userProfile.planChartOptionSelected.value === 'savings') {
    // Iterate over each series in the params array
    params.forEach(param => {
      tooltipContent.value += `<br/><span style="color:${param.color}; font-size: 1.2em; margin-right: 5px;">●</span><strong>${param.seriesName}: </strong>${formatNumber(param.data)}`;
    });
  }
  return tooltipContent.value;
}

// Configure the chart options/////////////////////////////////////////////////////
const chartOptions = computed(() => ({
    tooltip: {
      show: true,
      trigger: 'axis',      
      transitionDuration: 0,
      formatter: (params) => {
        updateTooltipContent(params);
        return tooltipContent.value;
      },
    },
    legend: { show: userProfile.planChartOptionSelected.value === 'netWorth' ? false : true },
    xAxis: {
      type: 'category',
      axisPointer: {
        value: planStore.getCurrentPlan.startYear,
        snap: true,
        handle: {
          show: true,
          color: '#7581BD'
        },
        lineStyle: {
        color: '#94a3b8',
        width: 1
      },
      },
      axisTick: {
        alignWithLabel: true,
      },
      data: userProfile.getSelectedXAxis === 'age' ? projectionData.value.data.age : projectionData.value.labels
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: function (value) {
          return formatNumber(value, 1, true);
        }
      }
    },
    series: [],
    grid: {
      left: '0%',
      right: '1%',
      bottom: '0%',
      top: '5%',
      containLabel: true
    },
    markLine: {},    
}));

const planChart = ref(null);

const updateTargetGraphics = () => {
  const option = instance.getOption();
  const bottomPixelY = instance.getHeight() - 20;

  const graphics = targets.value.flatMap((dataItem, dataIndex) => {
    
    let pixelPosition;

    // Calculate pixel positions
    if (dataItem.xAxis !== undefined) {
      // Convert x-axis value to pixel position
      pixelPosition = instance.convertToPixel('grid', [dataItem.xAxis, 0])[0];
    } else if (dataItem.yAxis !== undefined) {
      // Convert y-axis value to pixel position
      pixelPosition = instance.convertToPixel('grid', [0, dataItem.yAxis])[1];
      textOffset = 10; // Adjust the offset for yAxis labels

    }

    // Define label bounds for x and y axes
    const minLabelX = instance.convertToPixel('grid', [0, 0])[0];
    const maxLabelX = instance.convertToPixel('grid', [planStore.getCurrentPlan.duration, 0])[0];
    const yAxisExtent = instance.getModel().getComponent('yAxis').axis.getExtent()[1]; // Get the y-axis extent
    const minLabelY = instance.convertToPixel('grid', [0, yAxisExtent[0]])[1];
    const maxLabelY = instance.convertToPixel('grid', [0, yAxisExtent[1]])[1];
    
    // Ensure pixel position is within bounds
    if ((dataItem.xAxis !== undefined && (isNaN(pixelPosition) || !isFinite(pixelPosition) || pixelPosition < minLabelX || pixelPosition > maxLabelX)) ||
        (dataItem.yAxis !== undefined && (isNaN(pixelPosition) || !isFinite(pixelPosition) || pixelPosition < minLabelY || pixelPosition > maxLabelY))) {        
          return []; // Skip adding graphic elements if pixel position is outside label bounds

    }

    if (dataItem.xAxis !== undefined) {
      // Graphics for xAxis targets
      return [
        {
          // Line graphic for xAxis targets
          type: 'line',
          id: `line_${dataIndex}`,
          x: pixelPosition,
          y: 0,
          shape: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: bottomPixelY,
          },
          draggable: true,
          cursor: 'ew-resize',
          style: {
            lineDash: 'dotted',
            stroke: '#4c1d95',
            lineWidth: 1,
          },
          z: 100,          
          ondrag: echarts.util.curry(handleTargetDragging),
          ondragend : echarts.util.curry(handleTargetDragEnd),
        },
        {
          // Text label graphic for xAxis targets
          type: 'text',
          id: `label_${dataIndex}`,
          position: [pixelPosition - textOffset, 0],
          rotation: -Math.PI / 2, // Rotate by 90 degrees
          style: {
            text: dataItem.name, // Text content
            fill: '#4c1d95', // Text color
            fontSize: 12, // Text font size
            textAlign: 'left', // Text alignment
            stroke: '#fff', // White outline color
            strokeWidth: 2, // Width of the outline
          },
          z: 99,
        }
      ];
    } else if (dataItem.yAxis !== undefined) {
      // Graphics for yAxis targets
      const rightPixelX = instance.convertToPixel('grid', [projectionData.value.labels.length - 1, 0])[0];

      return [
        {
          // Line graphic for yAxis targets
          type: 'line',
          id: `line_${dataIndex}`,
          x: 0,
          y: pixelPosition,
          shape: {
            x1: 50,
            y1: 0,
            x2: rightPixelX,
            y2: 0,
          },
          draggable: true,
          cursor: 'ns-resize',
          style: {
            lineDash: 'dotted',
            stroke: '#4c1d95',
            lineWidth: 1,
          },
          z: 100,
          ondrag: echarts.util.curry(handleTargetDragging),
          ondragend : echarts.util.curry(handleTargetDragEnd),
        },
        {
          // Text label graphic for yAxis targets
          type: 'text',
          id: `label_${dataIndex}`,
          position: [rightPixelX, pixelPosition + textOffset],
          style: {
            text: dataItem.name, // Text content
            fill: '#4c1d95', // Text color
            fontSize: 12, // Text font size
            textAlign: 'right', // Text alignment
            stroke: '#fff', // White outline color
            strokeWidth: 1, // Width of the outline
          },
          z: 99,
        }
      ];
    }
  return [];
});

  option.graphic = graphics;
  // console.log(graphics)
  instance.setOption(option, { replaceMerge: ['graphic'] });  
};

const handleTargetDragging = (params) => {
  if (params) {
    const chartWidth = instance.getWidth();
    const chartHeight = instance.getHeight();
    
    const lastItemX = instance.convertToPixel({ seriesIndex: 0 }, [projectionData.value.labels.length - 1, 0])[0];
    const firstItemX = instance.convertToPixel({ seriesIndex: 0 }, [0, 0])[0];
    
    let xPoint = params.event.offsetX;
    let yPoint = params.event.offsetY;

    if (xPoint < firstItemX) {
      xPoint = firstItemX;
    } else if (!isFinite(xPoint) || xPoint === null || xPoint === '' || xPoint >= lastItemX) {
      xPoint = lastItemX;
    }
    xPoint = Math.max(0, Math.min(xPoint, chartWidth));
    yPoint = Math.max(0, Math.min(yPoint, chartHeight));

    const pointInPixel = [xPoint, yPoint];
    const pointInGrid = instance.convertFromPixel('grid', pointInPixel);
    
    let xIndex = pointInGrid[0];
    let yIndex = pointInGrid[1];

    if (xIndex < 0) {
      xIndex = 0;
    } else if (!isFinite(xIndex) || xIndex === null || xIndex === '' || xIndex >= projectionData.value.labels.length) {
      xIndex = projectionData.value.labels.length - 1;
    }

    if (params.target && params.target.type === 'line') {
      const targetIndex = parseInt(params.target.id.split('_')[1], 10);
      const target = planStore.getCurrentPlan.targets[targetIndex];

      if (target.type === 'age' || target.type === 'year') {
        if (xIndex >= 0 && xIndex < projectionData.value.labels.length) {
          const newX = projectionData.value.labels[xIndex];
          target.value = target.type === 'age' ? userProfile.getAgeAtYear(newX) : newX;
          
          // Update the position of the line and the associated label
          instance.setOption({
            graphic: [
              {
                id: `line_${targetIndex}`, // Assuming the line graphic has this ID
              },
              {
                id: `label_${targetIndex}`, // Assuming the label graphic has this ID
                position: [xPoint - textOffset, 0] // Adjust the y-position as needed
              }
            ]
          });
        }
        
      } else if (target.type === 'financial goal') {
        target.value = Math.round(yIndex); // Assuming financial goal values are integers
      }

    }
  }
};

const handleTargetDragEnd = () => {
   planStore.updateProjectionData();

};

const handleClick = (params) => {
    let year;
    if (params.name) {
      year = params.name;
    } 
    else {
      const pointInPixel = [params.event.offsetX, params.event.offsetY];
      const pointInGrid = instance.convertFromPixel('grid', pointInPixel);

      if (pointInGrid[0] >= 0 && pointInGrid[0] < projectionData.value.labels.length) {
        year = projectionData.value.labels[pointInGrid[0]];
        updateMarkLine(pointInGrid[0]);
      }
      else {
        year = planStore.selectedYear;
      }
      //year = parseInt(projectionData.value.labels[pointInGrid[0]]); // Convert to numeric value
    }    
    planStore.selectedYear = year;
  };

  const handleTouchEnd = (params) => {
    // Get the touch coordinates
    const touch = params.event.changedTouches[0];
    const pointInPixel = [touch.clientX, touch.clientY];
    const pointInGrid = instance.convertFromPixel('grid', pointInPixel);

    // Convert touch coordinates to year
    let year;
    if (pointInGrid[0] >= 0 && pointInGrid[0] < projectionData.value.labels.length) {
      year = projectionData.value.labels[pointInGrid[0]];
      updateMarkLine(pointInGrid[0]);
    } else {
      year = planStore.selectedYear;
    }

    planStore.selectedYear = year;
};

// Function to update the markline which displays the selected year
const updateMarkLine = (year) => {
  const option = instance.getOption();
  const markLineOpt = {
    animation: true,
    animationDuration: 200, // Set animation duration to 1000 milliseconds (1 second)
    animationEasing: 'cubicOut',
    animationDurationUpdate: 200,
    silent: true,
    lineStyle: {
      type: 'solid',
      color: '#94a3b8',        
    },
    label: {
      show: false
    },
    symbol: 'none',
    data: [{
      name: "Selected year",
      xAxis: year,
      symbol: 'none' 
    }]
  };
  option.series[0].markLine = { ...option.series[0].markLine, ...markLineOpt };
  instance.setOption(option);
};

onMounted(() => {

  // Initialize ECharts instance
  instance = echarts.init(planChart.value);     

  // Attach click event listener to the chart canvas
  instance.getZr().on('click', handleClick);
  // instance.getZr().on('touchstart', touchStart);
  instance.getZr().on('touchend', handleTouchEnd);

  // Set options to the chart instance
  updateChartData()
  instance.setOption(chartOptions.value);

  // Watch for changes in planStore.selectedYear
  watch(() => planStore.selectedYear, (newValue) => {
    // Find the index of newValue within projectionData.value.labels
    const selectedIndex = projectionData.value.labels.findIndex(label => label === newValue);
    // Update the markline with the selectedIndex
    updateMarkLine(selectedIndex);
  });
  
    // Manually trigger resize after mounting
  nextTick(() => {
    instance.resize();
    updateTargetGraphics();
  });

    // Resize the chart when the window resizes
  window.addEventListener('resize', () => {
    instance.resize();
    updateTargetGraphics();    
  });

});//end onmounted

watch(() => planStore.currentPlanId, () => {
  updateChartData(userProfile.planChartOptionSelected.value, true);
  updateTargetGraphics();

});

// Watch for changes in projectionData
watch(() => projectionData.value, (oldValue, newValue) => {
  
  // Update the xAxis and series data    
  instance.setOption({
    xAxis: {
      data: userProfile.selectedXAxis === 'year' ? newValue.labels : newValue.data.age
    },
  });

  if (oldValue.data.savings[0].accounts.length !== newValue.data.savings[0].accounts.length) {
    updateChartData(userProfile.planChartOptionSelected.value, true);
    
  }
  else {
    updateChartData(userProfile.planChartOptionSelected.value);
    
  }
  updateTargetGraphics();


}, { deep: true });

</script>