<template>
    <Listbox as="div" v-model="selected" @change="updateCountry">
      <ListboxLabel class="block text-sm font-medium leading-6 text-gray-900">Plan tax settings</ListboxLabel>
      <div class="relative mt-2">
        <ListboxButton class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
          <span class="block truncate">{{ selected.name }}</span>
          <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </ListboxButton>
  
        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
          <ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <ListboxOption as="template" v-for="country in countries" :key="country.id" :value="country" v-slot="{ active, selected }">
              <li :class="[active ? 'bg-gray-100' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                <span :class="[selected ? 'text-indigo-600' : 'font-normal', 'block truncate']">{{ country.name }}</span>
  
                <span v-if="selected" :class="[active ? 'text-indigo-600' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </template>
  
  <script setup>
  import { ref, watch } from 'vue'
  import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
  import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
  import { usePlanStore } from '@/stores/PlanStore' // Adjust the import path as necessary
  
  const planStore = usePlanStore()
  const countries = [
    { id: 1, name: 'UK' },
    { id: 2, name: 'US' },
    { id: 3, name: 'Canada' },
    // Add more countries as needed
  ]
  
  const selected = ref(null)
  const currentCountryName = planStore.getCurrentPlan.country
  selected.value = countries.find(country => country.name === currentCountryName) || countries[0]
  
  const updateCountry = (country) => {
    planStore.updateCurrentPlanCountry(country.name)
  }
  
  // Watch for changes in the selected country and update the plan's country
  watch(selected, (newSelected) => {
    if (newSelected) {
      planStore.updateCurrentPlanCountry(newSelected.name)
    }
  })
  </script>
  