<template>
    <div ref="chart" style="width: 100%; height: 100%;"></div>
</template>
  
  <script setup>
  import { onMounted, ref, watch } from 'vue';
  import * as echarts from 'echarts';
  import { useSnapshotsStore } from '../stores/SnapshotsStore.js'
  
  const chart = ref(null);
  const snapshotsStore = useSnapshotsStore();
  
  const createChart = () => {
    const chartInstance = echarts.init(chart.value);
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        data: ['Income', 'Expenditure', 'Discretionary Income'],
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: snapshotsStore.monthlyData.map(item => item.month),
      },
      yAxis: {
        type: 'value',
        name: 'Amount',
      },
      series: [
        {
          name: 'Income',
          type: 'bar',
          data: snapshotsStore.monthlyData.map(item => item.income),
        },
        {
          name: 'Expenditure',
          type: 'bar',
          data: snapshotsStore.monthlyData.map(item => item.expenditure),
        },
        {
          name: 'Discretionary Income',
          type: 'bar',
          data: snapshotsStore.monthlyData.map(item => item.disposableIncome),
        },
      ],
      color: [
        '#94A3B8',
        '#FB7185',
        '#4ADE80',
        '#9333EA',
        '#F59E0B',
        '#06B6D4',
    ],
    };
    chartInstance.setOption(option);
  };
  
  onMounted(() => {
    createChart();
  });
  
  watch(
    () => snapshotsStore.monthlyData,
    () => {
      createChart();
    },
    { deep: true }
  );
  </script>
  
  <style scoped>
  /* Add any styles needed for your chart component */
  </style>
  