import { createApp, h } from 'vue'
import App from './App.vue'
import './assets/styles/index.css'
import { createPinia } from 'pinia'
import router from './router';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import './assets/styles/tooltip.css';

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate);
const app  = createApp({
    render: ()=>h(App)
});

app.use(pinia);
app.use(router);
app.use(FloatingVue, {
  themes: {
    'floating-vue-theme': {
      triggers: [
        'hover',
      ],
      '$extend': 'tooltip',
      placement: 'top',
    },
  },
});


//global method for number formatting. Default is GBP, and 0 decimals
app.config.globalProperties.$formatNumber = (value, decimalPlaces = 0, shorthand = false,  currency = 'GBP',) => {
    if (shorthand) {
      // Ensure value is a number and not NaN
      const numValue = Number(value);
      if (isNaN(numValue)) {
        return 'Invalid number';
      }
  
      const suffixes = ["", "K", "M", "B", "T"];
      // Ensure we don't go beyond the suffixes array length
      let suffixNum = Math.floor((numValue.toFixed(0).length - 1) / 3);
      suffixNum = suffixNum >= suffixes.length ? suffixes.length - 1 : suffixNum;
  
      let shortValue = numValue / Math.pow(1000, suffixNum);
  
      // Format the short value to the 2 decimal places (my choice) or to 1 if it's not a whole number
      shortValue = shortValue % 1 !== 0 ? shortValue.toFixed(2 || 1) : shortValue;
      const options = {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
  
      const absoluteValue = Math.abs(shortValue);
      const formattedValue = new Intl.NumberFormat('en-US', options).format(absoluteValue) + suffixes[suffixNum];
  
      // Prepend a minus sign for negative numbers
      return numValue < 0 ? '-' + formattedValue : formattedValue;
    } 
    else {
      // Currency formatting
      const options = {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
      };
      const absoluteValue = Math.abs(value);
      const formattedValue = new Intl.NumberFormat('en-US', options).format(absoluteValue);
  
      // Prepend a minus sign for negative numbers
      return value < 0 ? '-' + formattedValue : formattedValue;
    }
  };
  
  

app.mount('#app')
//createApp(App).mount('#app')
