<template>
  <div ref="chartCanvas" style="width: 100%; height: 100%;"></div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import { useSnapshotsStore } from '../stores/SnapshotsStore';
import * as echarts from 'echarts';

const snapshotsStore = useSnapshotsStore();

const currencySymbol = snapshotsStore.currency;
const formatNumberToCurrency = (value, currencySymbol) => {
  if (Math.abs(value) >= 1.0e+9) {
    return `${currencySymbol}${(value / 1.0e+9).toFixed(0)}B`;
  } else if (Math.abs(value) >= 1.0e+6) {
    return `${currencySymbol}${(value / 1.0e+6).toFixed(0)}M`;
  } else if (Math.abs(value) >= 1.0e+3) {
    return `${currencySymbol}${(value / 1.0e+3).toFixed(0)}K`;
  } else {
    return `${currencySymbol}${value.toFixed(0)}`;
  }
};

const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const options = { day: '2-digit', month: 'short', year: '2-digit' }; //
  return new Intl.DateTimeFormat('en-UK', options).format(date);
};

const convertToDate = (dateString) => {
  // Ensure dateString is a string
  dateString = String(dateString);
  
  // Split the string and convert to date
  const [year, month, day] = dateString.split('-').map(Number);
  return new Date(year, month - 1, day); // Month in JavaScript Date object is 0-based
};

// Extract timestamps directly from snapshot dates
const snapShotTimes = snapshotsStore.snapshots.map(snapshot => convertToDate(snapshot.date).getTime());

const chartSeriesData = computed(() => {
  return snapshotsStore.snapshots.map(snapshot => {
    const date = convertToDate(snapshot.date);
    const time = date.getTime();
    const netWorth = snapshot.netWorth || 0;
    return [time, netWorth];
  });
});

const chartOptions = {
  xAxis: {
    type: 'time',
    data: snapShotTimes,
    boundaryGap:false,    
    minInterval: 1,
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      formatter: function (value) {
        return formatNumberToCurrency(value, currencySymbol);
      }
    }
  },
  tooltip: {
      trigger: 'axis',    
      formatter: function (params) {
        const xAxisValue = `<strong>${formatDate(params[0].axisValue)}</strong>`;
        const yAxisValue = params[0].value[1];
        const formattedValue = yAxisValue.toLocaleString('en-UK', {
          style: 'currency',
          currency: 'GBP',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        return `${xAxisValue}: ${formattedValue}`;
      }
    },
  series: [{
    type: 'line',
    data: chartSeriesData.value,
    label: {
      show: false,
      formatter: function (params) {
        return params.value; // You may need to format your labels here
      }
    },
    triggerLineEvent: true,
      lineStyle: {color:'#4f46e5'},
      markline: {},
      areaStyle: {        // Customize area gradient
            color: {
                type: 'linear',   // Linear gradient
                x: 0,             // Gradient start point
                y: 0,             // Gradient start point
                x2: 0,            // Gradient end point
                y2: 1,            // Gradient end point
                colorStops: [{    // Gradient stops
                    offset: 0,    // 0% stops
                    color: 'rgba(151, 71, 255, 0.7)'    
                }, {
                    offset: 1,    // 100% stops
                    color: 'rgba(204, 165, 255, 0.1)'      
                }],
                global: false   // Coordinates are relative to the chart
            },
            opacity: 0.8       // Gradient opacity
        },
  }],
  grid: {
      left: '4%',
      right: '5%',
      bottom: '5%',
      top: '10%',
      containLabel: true
    },
};

const chartCanvas = ref(null);
let chartInstance;

onMounted(() => {
  chartInstance = echarts.init(chartCanvas.value);
  chartInstance.setOption(chartOptions);
});

watch(chartSeriesData, (newValue) => {
  // Update the xAxis and series data
    chartInstance.setOption({
      xAxis: {        
        data: snapShotTimes,
      },
      series: [{
        data: newValue
      }]
    });
});

 // Resize the chart when the window resizes
 window.addEventListener('resize', () => {
  chartInstance.resize();
 });

</script>
