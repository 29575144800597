<template>
  <div class="" :class="{ 'bg-gray-100': isHomePage }">
    <SideBar/>
    <!-- Main Content Area -->
    <router-view></router-view>
  </div>
</template>

<script>
import SideBar from './components/SideBar.vue';
export default {
    name: 'App',
    components: { SideBar },
    computed: {
        isHomePage() {
            // Determine if it's the home page based on router information
            return this.$route.path === '/';
        }
    }
};
</script>

<style scoped>
/* Add styles for the empty div */
.empty {
  /* Set general background color */
  background-color: white;
  /* Add any other styles as needed */
}

</style>
