// userStore.js
import { defineStore } from 'pinia';
import { usePlanStore } from '@/stores/PlanStore';

export const useUserProfileStore = defineStore('user', {
  // State: Holds the reactive state of the store
  state: () => ({
    age: null,
    birthYear: 1992,
    email: '',
    isLoggedIn: false,
    nationalInsurance: true,
    preferredCurrency: '£',
    name: 'Profile',
    selectedXAxis: 'year',
    isAllocationAmountSelected: false,
    planChartOptionSelected:  {label: 'Net Worth', value: 'netWorth' },
    dataZoom: {start:0, end:100},
    isFirstTime: true,
    // Add other user-related properties here
  }),

  // Getters: Compute derived state based on the current store state
  getters: {
    // Example getter: Check if the user's age is available
    hasAge: (state) => state.age !== null,
    payNationalInsurance: (state) => state.nationalInsurance,
    getSelectedXAxis: (state) => state.selectedXAxis,

  },

  // Actions: Methods for modifying store state
  actions: {
    // Action to update the user's age
    updateAge(newAge) {
      this.age = newAge;
    },

    getAgeAtYear(targetYear) {
        if (this.birthYear === null) {
          console.error("User's birth year is not set.");
          return null; // or appropriate error handling
        }
  
        // Calculate the age
        const age = targetYear - this.birthYear;
        return age;
    },
    getYearAtAge(age) {
      if (this.birthYear === null) {
          console.error("User's birth year is not set.");
          return null; // or appropriate error handling
      }
  
      // Calculate the birth year
      const year = this.birthYear + age;
      return year;
    },

    getYearAtValue: (value) => {
      const planStore = usePlanStore();

      if (!planStore.getCurrentPlan || !planStore.getCurrentPlan.projection || !planStore.getCurrentPlan.projection.data) {
        console.error("Plan projection data not available.");
        return null;
      }
    
      const { data, labels } = planStore.getCurrentPlan.projection;
      
      for (let i = 0; i < data.netWorth.length; i++) {
        if (data.netWorth[i] >= value) {
          return labels[i]; // Return the corresponding year label when net worth exceeds the target value          
        }
      }
    
     
      return planStore.getCurrentPlanEndYear;
    },
    

    // Action to update the user's email
    updateEmail(newEmail) {
      this.email = newEmail;
    },

    // Action to log the user in
    logIn() {
      this.isLoggedIn = true;
    },

    // Action to log the user out
    logOut() {
      this.isLoggedIn = false;
    },

    // Add other actions as needed for updating user information
  },
  persist:true,
});
