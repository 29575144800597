<template>
    <div>
           <div class="flex items-top gap-2">                        
                <div class="flex-grow">
                    <div :class="{ 'text-red-900 ring-1 ring-inset ring-red-300 focus-within:ring-2 focus-within:ring-red-500': !dialogStore.isFieldValid('startYear')}" class="flex-grow rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                        <label  :class="{ 'text-red-900': !dialogStore.isFieldValid('startYear')}" class="block text-xs font-medium text-gray-500">Plan start year</label>
                        <input type="number" name="startYear" id="startYear-{{ index }}" v-model="dialogStore.selectedItem.startYear" class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 invalid:text-red-900 invalid:ring-1 invalid:ring-inset invalid:ring-red-300" @blur="validateField(dialogStore.selectedItem.startYear, 'startYear', dialogStore)" />
                    </div>
                    <div class="h-4 my-1"><p v-if="!dialogStore.isFieldValid('startYear')" class="text-xs text-red-600" id="email-error">Start year must be between 1900 and 2150</p></div>
                </div>
                <div class="flex-grow">
                    <div :class="{ 'text-red-900 ring-1 ring-inset ring-red-300 focus-within:ring-2 focus-within:ring-red-500': !dialogStore.isFieldValid('duration')}" class="flex-grow rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                        <label  :class="{ 'text-red-900': !dialogStore.isFieldValid('duration')}" class="block text-xs font-medium text-gray-500">Plan length (duration)</label>
                        <input type="number" name="duration" id="duration-{{ index }}" v-model="dialogStore.selectedItem.duration" class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 invalid:text-red-900 invalid:ring-1 invalid:ring-inset invalid:ring-red-300" @blur="validateField(dialogStore.selectedItem.duration, 'duration', dialogStore)" />
                    </div>
                    <div class="h-4 my-1"><p v-if="!dialogStore.isFieldValid('duration')" class="text-xs text-red-600" id="email-error">Plans must be between 1 and 100 years</p></div>
                </div>

            </div>   
            <div class="flex flex-row gap-2">                
                <div class="flex-1 ml-1"> 
                  <div class="flex gap-2 w-2/3 items-end">
                    <PlanEditSettingsCountryDropdown /> <span class="h-6 w-5 mb-1 text-slate-600 cursor-pointer hover:text-violet-500"><Cog6ToothIcon  @click="dialogStore.selectedItemType='tax'" v-tooltip="{content: 'View tax settings',  theme: 'floating-vue-theme'}"/></span>
                  </div>
                </div>                              

            <div class="flex-1 items-center">
                <div class="w-1/2">
                    <label for="state-pension-age" class="block text-sm font-medium leading-6 text-gray-900">Plan currency</label>
                    <div class="mt-2">
                      <input type="text" length="1" name="currency" id="currency-{{ index }}" :class="{ 'text-red-900 ring-1 ring-inset ring-red-300 focus-within:ring-2 focus-within:ring-red-500': !dialogStore.isFieldValid('currency')}" class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="dialogStore.selectedItem.currency" @blur="validateField(dialogStore.selectedItem.currency, 'currency', dialogStore)" />
                    </div>
                </div>
                
                <div class="h-4 my-1"><p v-if="!dialogStore.isFieldValid('startYear')" class="text-xs text-red-600" id="currency-error">Currency symbol only</p></div>
              </div>  
            </div>
            <div v-if="planStore.getCurrentPlan.country==='UK'">
              <div class="flex flex-row">                
                <div class="w-1/2 ml-1">
                  <label for="normal-minimum-pension-age" class="block text-sm font-medium leading-6 text-gray-900">Normal minimum pension age</label>
                  <p id="normal-minimum-pension-age-description" class="text-gray-500 text-sm">The age you can take your personal pension.</p> 
                  <div class="mt-2 w-1/2">
                    <input 
                      type="number" 
                      name="normal-minimum-pension-age" 
                      v-model="normalMinimumPensionAge" 
                      id="normal-minimum-pension-age" 
                      class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
                      placeholder="57" 
                      aria-describedby="normal-minimum-pension-age-description"
                    />
                  </div>
                </div>
                <div class="w-1/2 ml-1">
                  <label for="state-pension-age" class="block text-sm font-medium leading-6 text-gray-900">State pension age</label>
                  <p id="state-pension-age-description" class="text-gray-500 text-sm">The age you can collect a state pension.</p>
                  <div class="mt-2 w-1/2">
                    <input 
                      type="number" 
                      name="state-pension-age" 
                      v-model="statePensionAge" 
                      id="state-pension-age" 
                      class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
                      placeholder="67" 
                      aria-describedby="state-pension-age-description"
                    />
                  </div>
                </div>
              </div>
            </div>
            
    </div>
  </template>
  
  <script setup>
  import { computed, onMounted } from 'vue';
  import { Cog6ToothIcon } from '@heroicons/vue/24/solid';
  import { usePlanStore } from '@/stores/PlanStore';
  import { useDialogStore } from '@/stores/DialogStore';
  import { validateField } from '@/utils/validationUtils';
  import { useTaxStore } from '@/stores/TaxStore';
  import PlanEditSettingsCountryDropdown from '@/components/PlanEditSettingsCountryDropdown.vue'
  
  const taxStore = useTaxStore();
  const planStore = usePlanStore();
  const dialogStore = useDialogStore();
  
  // Register fields on component mount
  onMounted(() => {
    registerFields();
  });
  
  const registerFields = () => {
    // Call an action or mutation to register fields
    dialogStore.registerFields(['duration', 'startYear', 'currency']);
  }
  
  if (dialogStore.dialogMode === 'settings') {
    dialogStore.selectedItem = {
      duration: planStore.getCurrentPlan.duration,
      startYear: planStore.getCurrentPlan.startYear,
      currency: planStore.getCurrentPlan.currency,
      nationalInsurance: planStore.getCurrentPlan.nationalInsurance,
      includeStudentLoanInNetWorth: planStore.getCurrentPlan.includeStudentLoanInNetWorth,
    }
  }
  
  // Computed properties for state pension age and normal minimum pension age
  const statePensionAge = computed({
    get() {
      // Try to get from planStore, fallback to taxStore
      const target = planStore.getCurrentPlan.targets.find(target => target.name === 'State pension age');
      return target?.value ?? taxStore.statePensionAge;
    },
    set(value) {
      // Update planStore when edited
      const target = planStore.getCurrentPlan.targets.find(target => target.name === 'State pension age');
      if (target) {
        target.value = value;
      } else {
        // If it doesn't exist in planStore, create it
        planStore.getCurrentPlan.targets.push({
          name: 'State pension age',
          type: 'age',
          value,
          modifier: 'years old'
        });
      }
    }
  });
  
  const normalMinimumPensionAge = computed({
    get() {
      // Try to get from planStore, fallback to taxStore
      return planStore.getCurrentPlan.settings?.normalMinimumPensionAge ?? taxStore.normalMinimumPensionAge;
    },
    set(value) {
      // Update planStore when edited
      if (!planStore.getCurrentPlan.settings) {
        planStore.getCurrentPlan.settings = {};
      }
      planStore.getCurrentPlan.settings.normalMinimumPensionAge = value;
    }
  });
  </script>