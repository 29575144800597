import { useSavingsStore } from '@/stores/SavingsStore';
import { useTaxStore } from '@/stores/TaxStore';
import { useUserProfileStore } from '@/stores/UserProfile';

// Function to initialize allocations
export function initializeAllocations(plans, currentPlanId) {
  // Find the current plan by ID
  const currentPlan = plans.find(plan => plan.id === currentPlanId);

  if (!currentPlan) {
    console.error('Current plan not found');
    return;
  }

  // Retrieve the projection years from the plan, or generate them if they don't exist
  let years = currentPlan.projection?.labels || [];

  if (years.length === 0) {
    // Fallback logic to generate labels if the projection labels are not available
    const startYear = currentPlan.startYear || new Date().getFullYear();
    const duration = currentPlan.duration || 1; 
    years = Array.from({ length: duration + 1 }, (_, i) => startYear + i);
  }

  const savingsStore = useSavingsStore();
  const taxStore = useTaxStore();
  const userProfileStore = useUserProfileStore();

  // Merge savings accounts from the store and the current plan
  let savingsAccounts = savingsStore.items.map(item => ({ ...item }));
  currentPlan.savings?.forEach(planSavingsItem => {
    const existingItemIndex = savingsAccounts.findIndex(copyItem => copyItem.id === planSavingsItem.id);
    if (existingItemIndex !== -1) {
      savingsAccounts[existingItemIndex] = { ...planSavingsItem };
    } else {
      savingsAccounts.push({ ...planSavingsItem });
    }
  });

  // Filter out excluded accounts and those not included in allocation
  savingsAccounts = savingsAccounts.filter(item => !item.exclude && item.includeInAllocation);

  if (savingsAccounts.length === 0) {
    console.error('No valid savings accounts found');
    return;
  }

  // Calculate the initial allocation percentage
  const initialAllocation = Math.floor(100 / savingsAccounts.length);
  let remainingAllocation = 100 - (initialAllocation * savingsAccounts.length);

  // Initialize the savings allocation for each account
  const savingsAllocation = savingsAccounts.map(account => {
    let allocation = initialAllocation;
    if (remainingAllocation > 0) {
      allocation += 1;
      remainingAllocation -= 1;
    }
    return {
      id: account.id,
      name: account.name,
      isPension: account.isPension,
      allocation: years.map(year => ({
        year: parseInt(year),
        percent: allocation,
        absolute: 0,
        value: allocation
      }))
    };
  });

  // Determine the normal minimum pension age from the current plan or the tax store
  const normalMinimumPensionAge = (currentPlan?.settings?.normalMinimumPensionAge)
    ? parseInt(currentPlan.settings.normalMinimumPensionAge)
    : parseInt(taxStore.normalMinimumPensionAge);

  // Loop through each year to adjust allocations and handle pension rules
  years.forEach(year => {
    const currentAge = userProfileStore.getAgeAtYear(year);
    const yearIndex = years.indexOf(year);

    // First pass: distribute pension allocations that are ineligible (below pension age)
    let redistributedPercent = 0;

    savingsAllocation.forEach(account => {

      if (account.isPension) {
        const allocationPoint = account.allocation.find(a => a.year === parseInt(year));
        if (allocationPoint && currentAge < normalMinimumPensionAge && currentPlan.projection.data.profit[yearIndex] < 0) {          
          redistributedPercent += allocationPoint.percent;
          allocationPoint.percent = 0;
        }
      }
    });

    if (redistributedPercent > 0) {
      const nonPensionAccounts = savingsAllocation.filter(account => !account.isPension);
      const redistributionPerAccount = parseFloat((redistributedPercent / nonPensionAccounts.length).toFixed(2));

      nonPensionAccounts.forEach(account => {
        const allocationPoint = account.allocation.find(a => a.year === parseInt(year));
        if (allocationPoint) {
          allocationPoint.percent += redistributionPerAccount;
        }
      });
    }

    // Second pass: ensure total allocation adds up to 100% for the current year
    let totalAllocation = savingsAllocation.reduce((sum, account) => {
      const allocationPoint = account.allocation.find(a => a.year === parseInt(year));
      return sum + (allocationPoint ? allocationPoint.percent : 0);
    }, 0);

    if (totalAllocation !== 100) {
      const adjustment = 100 - totalAllocation;
      const topNonPensionAccount = savingsAllocation.find(account => {
        const allocationPoint = account.allocation.find(a => a.year === parseInt(year));
        return allocationPoint && !account.isPension;
      });

      if (topNonPensionAccount) {
        const allocationPoint = topNonPensionAccount.allocation.find(a => a.year === parseInt(year));
        if (allocationPoint) {
          allocationPoint.percent = parseFloat((allocationPoint.percent + adjustment).toFixed(2));
        }
      }
    }
  });

  // Step: calculate absolute values for each allocation point
  savingsAllocation.forEach(account => {
    account.allocation.forEach(allocation => {      
      const yearIndex = years.indexOf(allocation.year);            
      if (yearIndex !== -1 && currentPlan?.projection?.data?.profit) {
        allocation.absolute = (allocation.percent / 100) * currentPlan.projection.data.profit[yearIndex];
        allocation.isDrawdown = allocation.absolute < 0;
      } 
    });
  });

  // Find the index of the current plan and save the initialized allocation
  const planIndex = plans.findIndex(plan => plan.id === currentPlanId);
  if (planIndex !== -1) {
    plans[planIndex].savingsAllocation = savingsAllocation;
  }

  return savingsAllocation;
}

// Function to update allocations
export function useAllocations() {
  const savingsStore = useSavingsStore();
  const taxStore = useTaxStore();

  const updateAllocations = (plans, currentPlanId) => {
    const currentPlan = plans.find(plan => plan.id === currentPlanId);
    if (!currentPlan) {
      console.error('Current plan not found');
      return;
    }   

    // Retrieve the projection years from the plan, or generate them if they don't exist
    let years = currentPlan.projection?.labels || [];
    
    if (years.length === 0 || years.length !== currentPlan.duration) {
      // Fallback logic to generate labels if the projection labels are not available
      const startYear = currentPlan.startYear || new Date().getFullYear();
      const duration = currentPlan.duration || 1; 
      years = Array.from({ length: duration + 1 }, (_, i) => startYear + i);
    }

    const profitData = currentPlan.projection.data.profit; // Cache this to avoid repeated lookups

    // Cache settings for normal minimum pension age
    const normalMinimumPensionAge = currentPlan?.settings?.normalMinimumPensionAge || taxStore.normalMinimumPensionAge;

    // Clone and merge savings accounts from the store and current plan
    let savingsAccounts = savingsStore.items.map(item => ({ ...item }));
    currentPlan?.savings?.forEach(planSavingsItem => {
      const index = savingsAccounts.findIndex(acc => acc.id === planSavingsItem.id);
      if (index !== -1) {
        savingsAccounts[index] = { ...planSavingsItem };
      } else {
        savingsAccounts.push({ ...planSavingsItem });
      }
    });

    // Filter out excluded accounts
    savingsAccounts = savingsAccounts.filter(account => !account.exclude && account.includeInAllocation);

    // If all accounts are excluded, reset the allocation
    if (savingsAccounts.length === 0) {
      currentPlan.savingsAllocation = [];
      return;
    }

    let existingAllocation = currentPlan.savingsAllocation || [];

    // Handle removed accounts by recalculating allocation
    const removedAccounts = existingAllocation.filter(account =>
      !savingsAccounts.some(saving => saving.id === account.id)
    );

    const totalRemoved = removedAccounts.reduce((sum, account) => sum + account.allocation[0]?.percent || 0, 0);
    const redistribution = totalRemoved / (existingAllocation.length - removedAccounts.length || 1);

    // Filter out removed accounts and redistribute their allocations
    existingAllocation = existingAllocation.filter(account => !removedAccounts.includes(account));
    existingAllocation.forEach(account => {
      account.allocation.forEach(point => {
        point.percent = Math.max(0, Math.min(100, Math.round(point.percent + redistribution)));
        point.absolute = (point.percent / 100) * profitData[years.indexOf(point.year)];
      });
    });

    // Update names in existing allocation
    existingAllocation.forEach(allocation => {
      const matchingAccount = savingsAccounts.find(account => account.id === allocation.id);
      if (matchingAccount && matchingAccount.name !== allocation.name) {
        allocation.name = matchingAccount.name;
      }
    });

    // Handle new accounts
    const newAccounts = savingsAccounts.filter(account =>
      !existingAllocation.some(allocation => allocation.id === account.id)
    );

    if (newAccounts.length > 0) {
      const redistribution = 100 / (existingAllocation.length + newAccounts.length);

      newAccounts.forEach(account => {
        const allocation = Array(years.length).fill(redistribution);
        existingAllocation.push({
          id: account.id,
          name: account.name,
          allocation: years.map((year, index) => ({
            year: parseInt(year),
            percent: Math.round(allocation[index]),
            absolute: (allocation[index] / 100) * profitData[index],
          })),
        });
      });
    }

    // Adjust allocations based on the plan's duration
    existingAllocation.forEach(account => {
      const planDuration = currentPlan.duration + 1;
      const startYear = currentPlan.startYear;

      // Ensure the length of allocation matches the plan duration
      if (account.allocation.length > planDuration) {
        account.allocation.splice(planDuration);
      } else if (account.allocation.length < planDuration) {
        const lastPercent = account.allocation.length > 0 ? account.allocation[account.allocation.length - 1].percent : 100 / savingsAccounts.length;
        for (let i = account.allocation.length; i < planDuration; i++) {
          const year = startYear + i;
          account.allocation.push({
            year,
            percent: lastPercent,
            absolute: (lastPercent / 100) * profitData[i],
          });
        }
      }

      // Ensure correct start year and increment
      if (account.allocation[0].year !== startYear) {
        const lastPercent = account.allocation.length > 0 ? account.allocation[0].percent : 100 / savingsAccounts.length;
        account.allocation = Array.from({ length: planDuration }, (_, i) => {
          const year = startYear + i;
          return {
            year,
            percent: lastPercent,
            absolute: (lastPercent / 100) * profitData[i],
          };
        });
      }
    });

    years.forEach(year => {
      const userProfileStore = useUserProfileStore();
      const currentAge = userProfileStore.getAgeAtYear(year);

      let totalPensionAllocation = 0;
      let excessPensionAllocation = 0;

      // Step 1: Set drawdown marker based on negative profit
      existingAllocation.forEach(account => {
        account.allocation.forEach(allocation => {
          const yearIndex = years.indexOf(allocation.year);
          
          if (yearIndex !== -1 && currentPlan && currentPlan.projection) {
            // Check if the profit for the year is negative
            const profitForYear = currentPlan.projection.data.profit[yearIndex];
            
            if (profitForYear < 0) {
              allocation.isDrawdown = true;
            } else {
              allocation.isDrawdown = false;
            }

            allocation.absolute = (allocation.percent / 100) * profitForYear;
          }
        });
      });

      // Step 2: Calculate total pension allocation for the year
      existingAllocation.forEach(account => {
        if (savingsAccounts.find(a => a.id === account.id && a.isPension)) {
          const allocationPoint = account.allocation.find(a => a.year === parseInt(year));

          if (allocationPoint) {
            if (allocationPoint.isDrawdown && allocationPoint.absolute < 0 && currentAge < normalMinimumPensionAge) {
              // Handle early pension withdrawal case
              allocationPoint.absolute = 0;
              allocationPoint.percent = 0;
            } else {
              totalPensionAllocation += allocationPoint.absolute;
            }

            // Tax liabilities for pension drawdown
            if (allocationPoint.isDrawdown && allocationPoint.absolute < 0 && currentAge >= normalMinimumPensionAge) {
              const grossNeeded = taxStore.calculateGrossIncomeForDesiredTakeHome(Math.abs(allocationPoint.absolute), year);
              const taxLiability = grossNeeded - Math.abs(allocationPoint.absolute);
              allocationPoint.taxLiabilityForPensionWithdrawl = taxLiability;
              allocationPoint.absolute = -grossNeeded;
            }
          }
        }
      });

      // Step 3: Adjust allocations if the total pension allocation exceeds the annual allowance
      if (totalPensionAllocation > taxStore.annualAllowance) {
        excessPensionAllocation = totalPensionAllocation - taxStore.annualAllowance;
        existingAllocation.forEach(account => {
          if (savingsAccounts.find(a => a.id === account.id && a.isPension)) {
            const allocationPoint = account.allocation.find(a => a.year === parseInt(year));
            if (allocationPoint && excessPensionAllocation > 0) {
              const reduction = Math.min(allocationPoint.absolute, excessPensionAllocation);
              allocationPoint.absolute -= reduction;
              allocationPoint.percent = parseFloat(((allocationPoint.absolute / currentPlan.projection.data.profit[years.indexOf(year)]) * 100).toFixed(2));
              excessPensionAllocation -= reduction;
            }
          }
        });
      }

      // Step 4: Ensure total allocation adds up to 100%
      let totalAllocation = existingAllocation.reduce((sum, account) => {
        const allocationPoint = account.allocation.find(a => a.year === parseInt(year));
        return sum + (allocationPoint ? allocationPoint?.percent : 0);
      }, 0);

      if (totalAllocation !== 100) {
        const diff = 100 - totalAllocation;
        existingAllocation[0].allocation.find(a => a.year === parseInt(year)).percent += diff;
      }
    });

    // Set the updated allocations back to the plan
    currentPlan.savingsAllocation = existingAllocation;
    return currentPlan.savingsAllocation;
  };

  return {
    updateAllocations,
  };
}



