<template>
        <h4 class="block leading-6 text-gray-900">Years active</h4>
        <div class="flex flex-row gap-2 items-center "> 
            <div class="flex flex-col md:flex-row gap-2 max-w-36 md:max-w-56">
                <Menu as="div" class="inline-block text-left ">
                    <Float portal  placement="bottom-start" enter="transition duration-200 ease-out" enter-from="scale-95 opacity-0" enter-to="scale-100 opacity-100" leave="transition duration-150 ease-in" leave-from="scale-100 opacity-100" leave-to="scale-95 opacity-0" tailwindcss-origin-class>
                    <MenuButton class="flex w-full justify-center gap-x-1.5 align-middle rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    <component :is="selectedStartDate.icon" class="mt-0.5 h-4 w-4"></component>
                    <span class="truncate"> {{ selectedStartDate.label }}</span> 
                    <ChevronDownIcon class=" h-5 w-5 text-gray-400" aria-hidden="true" />
                    </MenuButton>
            
                        <MenuItems class="mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div class="py-1">
                                <MenuItem v-for="option in startOptions" :key="option.label" as="template">
                                    <a href="#" class='group flex items-center px-4 py-2 text-sm hover:bg-gray-100' @click="selectOption(option.value, option.label, option.icon, 'startYear')" :class="[startYearActive(option.label) ? 'text-indigo-600' : 'text-gray-700']">
                                        <component :is="option.icon" aria-hidden="true" class="mr-3 h-5 w-5 group-hover:text-gray-500" :class="[startYearActive(option.label) ? 'text-indigo-600 group-hover:text-indigo-600' : 'text-gray-400']"/>
                                        {{ option.label }}
                                        <CheckIcon v-if="startYearActive(option.label)" class="ml-auto h-5 w-5 text-indigo-500 group-hover:text-indigo-500" aria-hidden="true"/>
                                    </a>
                                </MenuItem>
                            </div>
                        </MenuItems>
             
                </Float>
                </Menu>
                <div v-if="selectedStartDate.label === 'Year'" class="relative w-24">
                    <input type="number" min="0" max="1000000" v-model="dialogStore.selectedItem.startYear" placeholder="Year" :class="{ 'text-red-900 ring-1 ring-inset ring-red-300 focus-within:ring-2 focus-within:ring-red-500': !dialogStore.isFieldValid('startYear'), 'block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6': true }" @blur="validateField(dialogStore.selectedItem.startYear, 'startYear', dialogStore, planStore)" />
                </div>
            </div>

            <span v-if="dialogStore.selectedItem.frequency !== 'once'" >→</span>

            <div v-if="dialogStore.selectedItem.frequency !== 'once'" class="flex flex-col md:flex-row gap-2 max-w-36 md:max-w-56">
                <Menu as="div" class="inline-block text-left">
                    <Float portal :shift="8" enter="transition duration-200 ease-out" enter-from="scale-95 opacity-0" enter-to="scale-100 opacity-100" leave="transition duration-150 ease-in" leave-from="scale-100 opacity-100" leave-to="scale-95 opacity-0" tailwindcss-origin-class>
                        <MenuButton class="flex w-full justify-center gap-x-1.5 align-middle rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 ">
                        <component :is="selectedEndDate.icon" class="mt-0.5 h-4 w-4"></component>
                        <span class="truncate">{{ selectedEndDate.label }}</span>
                        <ChevronDownIcon class=" h-5 w-5 text-gray-400" aria-hidden="true" />
                        </MenuButton>
                            <MenuItems class="mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div class="py-1">
                                    <MenuItem v-for="option in endOptions" :key="option.label" :value="option.value" as="template">
                                        <a href="#" class='group flex items-center px-4 py-2 text-sm hover:bg-gray-100' @click="selectOption(option.value, option.label, option.icon, 'endYear')" :class="[endYearActive(option.label) ? 'text-indigo-600' : 'text-gray-700']">
                                        <component :is="option.icon" aria-hidden="true" class="mr-3 h-5 w-5  group-hover:text-gray-500" :class="[endYearActive(option.label) ? 'text-indigo-600 group-hover:text-indigo-600' : 'text-gray-400']"/>
                                        {{ option.label }}
                                        <CheckIcon v-if="endYearActive(option.label)" class="ml-auto h-5 w-5 text-indigo-500 group-hover:text-indigo-500" aria-hidden="true"/>
                                    </a>
                                    </MenuItem>
                                </div>
                            </MenuItems>
                    </Float>
                </Menu>
                <div v-if="selectedEndDate.label === 'Year'" class="relative w-24">
                    <input type="number" min="0" max="1000000" v-model="dialogStore.selectedItem.endYear" placeholder="Year" :class="{ 'text-red-900 ring-1 ring-inset ring-red-300 focus-within:ring-2 focus-within:ring-red-500': !dialogStore.isFieldValid('endYear'), 'block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6': true }" @blur="validateField(dialogStore.selectedItem.endYear, 'endYear', dialogStore, planStore)" />
                </div>
            </div>

        </div>
    
</template>
    
<script setup>
import {  ref } from 'vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon, CalendarIcon, ArrowRightStartOnRectangleIcon, ArrowRightEndOnRectangleIcon, FlagIcon, CheckIcon } from '@heroicons/vue/20/solid';
import { useDialogStore } from '@/stores/DialogStore';
import { validateField } from '@/utils/validationUtils';
import { usePlanStore } from '@/stores/PlanStore';

import { Float } from '@headlessui-float/vue';

const planStore = usePlanStore();
const dialogStore = useDialogStore();

const startYearActive = (menuItem) => {
    if (typeof dialogStore.selectedItem.startYear === "number" && menuItem === "Year") {
        return true;
    }
    else return dialogStore.selectedItem.startYear === menuItem;
};

const endYearActive = (menuItem) => {
    if (typeof dialogStore.selectedItem.endYear === "number" && menuItem === "Year") {
        return true;
    }
    else return dialogStore.selectedItem.endYear === menuItem;
};

// Define the options for the dropdown
const startOptions = ref([
    { label: 'Plan start', value: 'Plan start', icon: ArrowRightStartOnRectangleIcon },   
    { label: 'Year', value: planStore.getCurrentPlan.startYear, icon: CalendarIcon },
]);

const endOptions = ref([
    { label: 'Plan end', value: 'Plan end', icon: ArrowRightEndOnRectangleIcon },
    { label: 'Year', value: planStore.getCurrentPlan.startYear + planStore.getCurrentPlan.duration, icon: CalendarIcon },
]);

// Function to select an option
const selectOption = (value, label, icon, field) => {
    dialogStore.setDirtyField(field, true);
    if (value === 0) {
        dialogStore.setErrorField(field, false);
    } 
    // Update selectedStartDate for UI if needed
    if (field === 'startYear') {
        dialogStore.selectedItem.startYear = value; // Update dialogStore directly
        if (!dialogStore.selectedItem.startYear) { 
            dialogStore.selectedItem.startYear = value;
        }   
        selectedStartDate.value = { value, label, icon };   
    }    
    else if (field === 'endYear')  {
        dialogStore.selectedItem.endYear = value; // Update dialogStore directly
        if (!dialogStore.selectedItem.endYear) { 
            dialogStore.selectedItem.endYear = value;
        }   
        selectedEndDate.value = { value, label, icon };   
    }

};

// Fetch target years and concatenate them to the startOptions array
const targetYears = [];

// Check if currentPlan exists and has targets without triggering reactivity
const currentPlan = planStore.getCurrentPlan;
if (currentPlan && currentPlan.targets) {
    // Iterate through targets and add them to targetYears
    currentPlan.targets.forEach(target => {
        targetYears.push({ label: target.name, value: target.name, icon: FlagIcon });
    });
}

// Concatenate targetYears to the options arrays
startOptions.value = startOptions.value.concat(targetYears);
endOptions.value = endOptions.value.concat(targetYears);

// Define the selected option
let selectedStartDateIndex = startOptions.value.findIndex(option => {
    if (typeof dialogStore.selectedItem.startYear === 'number') {
        return option.label === 'Year';
    } else {
        return option.label === dialogStore.selectedItem.startYear;
    }
});
selectedStartDateIndex = selectedStartDateIndex !== -1 ? selectedStartDateIndex : 0;
const selectedStartDate = ref(startOptions.value[selectedStartDateIndex]);

let selectedEndDateIndex = endOptions.value.findIndex(option => {
    if (typeof dialogStore.selectedItem.endYear === 'number') {
        return option.label === 'Year';
    } else {
        return option.label === dialogStore.selectedItem.endYear;
    }
});
selectedEndDateIndex = selectedEndDateIndex !== -1 ? selectedEndDateIndex : 0;
const selectedEndDate = ref(endOptions.value[selectedEndDateIndex]);

</script>
  
<style>
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
</style>