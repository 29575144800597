<template>
  <div>
    <div class="flex-grow md:w-1/2">
          <div :class="{ 'text-red-900 ring-1 ring-inset ring-red-300 focus-within:ring-2 focus-within:ring-red-500': !dialogStore.isFieldValid('name')}" class="flex-grow rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
              <label  :class="{ 'text-red-900': !dialogStore.isFieldValid('name')}" class="block text-xs font-medium text-gray-500 leading-3">Name</label>
              <input type="text" name="name" id="name-{{ index }}" v-model="dialogStore.selectedItem.name" class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 invalid:text-red-900 invalid:ring-1 invalid:ring-inset invalid:ring-red-300" @blur="validateField(dialogStore.selectedItem.name, 'name', dialogStore)" />
          </div>
          <div class="h-4 my-1"><p v-if="!dialogStore.isFieldValid('name')" class="text-xs text-red-600" id="email-error">Not a valid name</p></div>
        </div>

    <div class="block text-sm font-medium leading-6 text-gray-900">
          Target type
    </div>
    <div class="flex md:flex-row gap-2 items-top  mb-4">
   
        <Menu as="div" class="relative inline-block text-left mt-2">
          <Float portal enter="transition duration-200 ease-out" enter-from="scale-95 opacity-0" enter-to="scale-100 opacity-100" leave="transition duration-150 ease-in" leave-from="scale-100 opacity-100" leave-to="scale-95 opacity-0" tailwindcss-origin-class>
            <MenuButton class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              {{ capitalizeFirstLetter(selectedTargetType) }}
              <ChevronDownIcon class="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
            </MenuButton>

            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems class="absolute left-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div class="py-1">
                  <MenuItem v-for="type in targetTypes" :key="type" v-slot="{ active }">
                    <a href="#" @click="selectTargetType(type)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm']">
                      <span :class="[type === selectedTargetType ? 'text-indigo-500' : 'text-gray-700' ]" class="mr-3 h-5" aria-hidden="true">{{ capitalizeFirstLetter(type) }}</span>
                      <CheckIcon v-if="type === selectedTargetType" class="ml-auto h-5 w-5 text-indigo-500 group-hover:text-indigo-500" aria-hidden="true"/>
                    </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Float>
        </Menu>

 
      <div class="w-32">       
        <!-- <label for="value" :class="{ 'text-red-900': !dialogStore.isFieldValid('value')}" class="block text-sm font-medium leading-6 text-gray-900">{{ capitalizeFirstLetter(selectedTargetType) }}</label> -->
        <div class="relative mt-2">
          <div v-if="selectedTargetType === 'financial goal'" class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span class="text-gray-500 sm:text-sm">{{planStore.getCurrentPlan.currency}}</span>
          </div>
          <input v-if="selectedTargetType === 'age'" type="number" name="value" id="value" v-model="dialogStore.selectedItem.value" :class="{ 'text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500': !dialogStore.isFieldValid('age')}" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="" @blur="validateField(dialogStore.selectedItem.value, selectedTargetType, dialogStore)"/>

          <input v-if="selectedTargetType === 'year'" type="number" name="value" id="value" v-model="dialogStore.selectedItem.value" :class="{ 'text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500': !dialogStore.isFieldValid('year')}" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="" @blur="validateField(dialogStore.selectedItem.value, selectedTargetType, dialogStore)"/>

          <input v-if="selectedTargetType === 'financial goal'" type="number" name="value" id="value" v-model="dialogStore.selectedItem.value" :class="{'text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500': !dialogStore.isFieldValid('financial goal')}" class="block w-full rounded-md border-0 py-1.5 pl-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="" @blur="validateField(dialogStore.selectedItem.value, selectedTargetType, dialogStore)"/>


        </div>
      </div>

      <div v-if="selectedTargetType === 'age'" class="text-sm text-gray-700 items-center h-full mt-4">
        {{ dialogStore.selectedItem.modifier }}
      </div>

    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { useDialogStore } from '@/stores/DialogStore'; // adjust path as needed
import { usePlanStore } from '@/stores/PlanStore'; // adjust path as needed
import { Float } from '@headlessui-float/vue'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import {
  ChevronDownIcon, CheckIcon,
} from '@heroicons/vue/20/solid'
import { validateField } from '@/utils/validationUtils';


const dialogStore = useDialogStore();
const planStore = usePlanStore();   

// Register fields on component mount
onMounted(() => {
  registerFields();

  watch(() => dialogStore.selectedItem.type, (newType, oldType) => {
    if (oldType) {
      dialogStore.removeField(oldType);
    }
    if (newType) {
      dialogStore.registerFields([newType]);
    }
  }, { immediate: true });

});

const registerFields = () => {
  // Call an action or mutation to register fields
  dialogStore.registerFields(['name', 'value']);
}


if (dialogStore.dialogMode === 'add') {  
  dialogStore.selectedItem = {
    name: '',
    type: '',
    value: '',
    id: '',
    modifier: 'years old',
  };
}

// Computed property to get the current target based on selectedItem.id
if (dialogStore.dialogMode === 'edit') {  
  watch(() => dialogStore.selectedItemId, (newId) => {     

    const target = planStore.getCurrentPlan && planStore.getCurrentPlan.targets ? planStore.getCurrentPlan.targets.find(item => item.id === newId) : null;
    
    dialogStore.selectedItem = {
      name: target.name,
      type: target.type,
      value: target.value,
      id: newId,
      modifier: target.modifier,
    };
  }, { immediate: true });
}

// Define target types and selected type
const targetTypes = ['age', 'year', 'financial goal'];
const selectedTargetType = ref(dialogStore.selectedItem.type || targetTypes[0]); // Default to 'age' if type is not set


// Function to select a target type
const selectTargetType = (type) => {
  selectedTargetType.value = type;
  dialogStore.selectedItem.type = type;
}

// Function to capitalize the first letter
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
</script>
