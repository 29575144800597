
<template>
  <div ref="allocationChart" class="min-h-40 h-full" style="width: 100%; height:100%" :style="{ height: props.chartHeight + 'px' }"></div>    
</template>

<script setup>
import * as echarts from 'echarts';
import { ref, computed, onMounted, watch, defineProps, nextTick} from 'vue';
import { usePlanStore } from '@/stores/PlanStore';
import { useDialogStore } from '@/stores/DialogStore'
import { useUserProfileStore } from '@/stores/UserProfile';
import { formatNumber } from '@/utils/formatNumber';
import { debounce } from 'lodash';
import { chartColors } from '@/utils/colors';

const userProfile = useUserProfileStore();
const planStore = usePlanStore();
const dialogStore = useDialogStore();
// const currency = planStore.getCurrentPlan.currency ?? '';
const isDragging = ref(false);

//used in the dialog popup
const props = defineProps({
chartHeight: {
  type: Number,    
}
});

const symbolSize = 8;

const projectionData = computed(() => planStore.getCurrentPlan.projection);

const calculateXAxis = (target) => {
  // Calculate xAxis value based on target's value and userProfile.birthYear
  let position;
  if (target.type === 'age') {
    position = target.value + userProfile.birthYear;
    if (userProfile.getSelectedXAxis === 'age') {
      position = userProfile.getAgeAtYear(position);
    }
  } 
  else if (target.type === 'year') {
    position = String(target.value);
  }
  return String(position);
};

//get targets
const targets = computed(() => {
  return planStore.getCurrentPlan.targets.map(target => {    
      return {
        name: target.name,
        xAxis: calculateXAxis(target),
        label: target.name,
        id: target.id,
      };
    })
});

const initializeAllocations = () => {
  if (!planStore.getCurrentPlan.savingsAllocation || planStore.getCurrentPlan.savingsAllocation.length === 0) {
    planStore.initializeAllocations();
  }
  return planStore.getCurrentPlan.savingsAllocation;
};

//local copy
let savingsAllocation = ref(JSON.parse(JSON.stringify(planStore.getCurrentPlan.savingsAllocation)));

const updateSavingsAllocationValues = () => {
  // Determine the key to use based on the user profile
  const key = userProfile.isAllocationAmountSelected ? 'absolute' : 'percent';

  // Loop through each account and update the value
  savingsAllocation.value.forEach(account => {
    account.allocation.forEach((allocationItem) => {
      allocationItem.value = allocationItem?.[key];
    });
  });
  
};

//setup allocation chart data
const chartData = computed(() => {
  const labels = projectionData.value?.labels ?? [];
  const years = labels.map(label => `${label}`);

  // Calculate age for each year
  const ages = years.map(year => `${userProfile.getAgeAtYear(parseInt(year))}`);
  
  const allocation = savingsAllocation.value ?? [];
  const data = allocation.map(account => {
    return {
      name: account.name,
      id: account.id,
      data: account.allocation.map(a => {
        const age = userProfile.getAgeAtYear(a.year); // Calculate age for each year
        return {
          year: a.year,
          value: userProfile.isAllocationAmountSelected ? a.absolute : a.percent,
          // value: a.value,
          name: account.name,         
          id: account.id, 
          absolute: a.absolute,
          percent: a.percent,
          age: age // Include age in each data point
        };
      })
    };
  });
  
  return { years, ages, data };
});

const cumulativeChartData = computed(() => {
  return chartData.value.data.map((accountData, seriesIndex) => {
    return accountData.data.map(({ year, percent, absolute, name, id, age }, dataIndex) => {
      let cumulativePercent = percent;
      let cumulativeAbsolute = absolute;
      // Sum the values from all previous series at the same data point to get the cumulative value
      for (let i = 0; i < seriesIndex; i++) {
        cumulativePercent += chartData.value.data[i].data[dataIndex].percent;
        cumulativeAbsolute += chartData.value.data[i].data[dataIndex].absolute;
      }
      // Ensure the cumulative value does not exceed 100
      cumulativePercent = Math.min(100, cumulativePercent);

      // Return an object with year (as string), cumulative value, name, and age (as string)
      return {
        year: `${year}`,       // Ensure year is a string
        percent: cumulativePercent,       // Keep cumulative value as a number
        value: userProfile.isAllocationAmountSelected ? cumulativeAbsolute : cumulativePercent,
        id: id,
        name: name,                  // Name remains unchanged
        age: `${age}`,
        absolute: cumulativeAbsolute,          // Ensure age is a string
      };
    });
  });  // Flatten the array to ensure a single-level array for the graphics
});

const updateTargetGraphics = () => {
    const option = instance.getOption();
    // Safely retrieve pixel position and handle potential errors
    let pixelPosition = instance.convertToPixel('grid', [0, 0]);

    // Check if pixelPosition is an array and has at least two elements
    const bottomPixelY = instance.getHeight() - 80;
    // Provide a default fallback value, such as 0, if the value is not valid

    const graphics = targets.value.flatMap((dataItem, dataIndex) => {          
    let textOffset = 5; // Adjust the offset according to your preference

    // Calculate pixel positions
    if (dataItem.xAxis !== undefined) {
      pixelPosition = instance.convertToPixel('grid', [dataItem.xAxis, 0])[0];
    } else if (dataItem.yAxis !== undefined) {
      pixelPosition = instance.convertToPixel('grid', [0, dataItem.yAxis])[1];
      textOffset = 10; // Adjust the offset for yAxis labels
    }        

    // Define label bounds for x and y axes
    const minLabelX = instance.convertToPixel('grid', [0, 0])[0];
    const maxLabelX = instance.convertToPixel('grid', [chartData.value.years.length - 1, 0])[0];

    // Ensure pixel position is within bounds
    if ((dataItem.xAxis !== undefined && (isNaN(pixelPosition) || !isFinite(pixelPosition) || pixelPosition < minLabelX || pixelPosition > maxLabelX))) {
      return []; // Skip adding graphic elements if pixel position is outside label bounds
    }

    if (dataItem.xAxis !== undefined) {
      // Graphics for xAxis targets

      return [
        {
          // Line graphic for xAxis targets
          type: 'line',
          id: `line_${dataIndex}`,
          x: pixelPosition,
          y: 0,
          shape: {
            x1: 0,
            y1: 16,
            x2: 0,
            y2: bottomPixelY,
          },                    
          style: {
            lineDash: 'dotted',
            stroke: '#4c1d95',
            lineWidth: 1,
          },
          z: 10,          
        },
        {
          // Text label graphic for xAxis targets
          type: 'text',
          id: `label_${dataIndex}`,
          position: [pixelPosition - textOffset, 20],
          rotation: -Math.PI / 2, // Rotate by 90 degrees
          style: {
            text: dataItem.name, // Text content
            fill: '#4c1d95', // Text color
            fontSize: 12, // Text font size
            textAlign: 'left', // Text alignment
            stroke: '#fff', // White outline color
            strokeWidth: 2, // Width of the outline
          },
          z: 99,
        }
      ];
    } 
    return [];
  });

  option.graphic = graphics;
  instance.setOption(option);
};

// Tooltip content reactive reference
const tooltipContent = ref('');

// Reverse both savingsAllocation.value and colors array
let reversedAllocation = computed(() => savingsAllocation.value.slice().reverse());

// Get the first X colors where X is the length of savingsAllocation.value, then reverse it
let colorSubset = computed(() => chartColors.slice(0, savingsAllocation.value.length).reverse()); 

/// Function to format tooltip content
const formatTooltipContent = (params) => {  
  if (!params || params.length === 0) return ' ';

  const dataIndex = params.dataIndex || (Array.isArray(params) && params[0]?.dataIndex) || 0;
  const year = chartData.value.years[dataIndex];
   
  // Get the corresponding age for the year
  const age = chartData.value.ages[dataIndex];
  const discretionaryIncome = projectionData.value.data.profit[dataIndex];

  const details = `
    <strong>Year: </strong>${year}<br />
    <strong>Age: </strong>${age}<br />
    <strong>Discretionary income: </strong>${formatNumber(discretionaryIncome, 0, false)}`;

  const tooltipItems = reversedAllocation.value.map((account, seriesIndex) => {
    const allocation = account.allocation?.[dataIndex];
    const absoluteAllocation = allocation?.absolute;
    let taxLiabilityContent = '';
    
    // Check if there is a tax liability for the pension withdrawal
    if (account.isPension && allocation?.isDrawdown && allocation.taxLiabilityForPensionWithdrawl > 0) {
      taxLiabilityContent = `
        <div style="display: flex; justify-content: space-between; align-items: center;">
          <span style="display: flex; align-items: center; margin-left:3px;">(Incl. tax of:</span>
          <span style="margin-left: 3px;">${formatNumber(allocation.taxLiabilityForPensionWithdrawl)})</span>
        </div>`;
    }
    
    // Return the formatted allocation information along with tax liability if it exists
    return isNaN(allocation?.value) ? '' : `
      <div style="display: flex; ;">
        <div style="display: flex;">
          <span style="display: flex; align-items: center;">
            <span style="color:${colorSubset.value[seriesIndex]}; font-size: 1.2em; margin-right: 5px;">●</span>
            <strong>${account.name}:</strong>
          </span>
          <span style="margin-left: 2px;">${allocation.percent}%</span>
          <span style="margin-left: 10px;">${formatAbsoluteAllocation(absoluteAllocation)}</span>
        </div>
        ${taxLiabilityContent}
      </div>`;
  }).join('');

  return details + tooltipItems;
};


// Update the tooltip content for normal tooltip
const updateTooltipContent = (params) => {
  tooltipContent.value = formatTooltipContent(params);
};

let xAxisData = userProfile.getSelectedXAxis === 'age' ? chartData.value.ages : chartData.value.years

const yAxisForPercent =  {
      type: 'value',
      min: 0,  // Set the minimum value of the y-axis
      max: 100, // Set the maximum value of the y-axis
      axisLabel: {
        formatter: '{value}%'
      }
    };

const yAxisForAmount = {
      type: 'value',
      axisLabel: {
        formatter: function (value) {
          return `${formatNumber(value, 0, true)}`;
        }
      }
    };

let yAxisDetails = userProfile.isAllocationAmountSelected ? yAxisForAmount : yAxisForPercent;

//////////////////////////////////main chart options ///////////////////////////////////////////////////////////////
const chartOptions = computed(() => ({
  legend: {
    // data: chartData.value.data.map(d => d.name),
    selectedMode: false
  },
  dataZoom: [
        { // The first dataZoom component
            type: 'slider', 
            xAxisIndex: [0] // Indicates that this dataZoom component
                                     
        },],
  animation: false,
  xAxis: {
    type: 'category',
    data: userProfile.getSelectedXAxis === 'age' ? chartData.value.ages : chartData.value.years,
    axisTick: {
      alignWithLabel: true,
    },    
  },
  yAxis: yAxisDetails,
  series: 
    chartData.value.data.map((d, index, array) => ({    
      showSymbol: userProfile.isAllocationAmountSelected ? true : index !== array.length - 1 ,  // Conditionally set showSymbol - don't show for top row of percentages
      name: d.name,
      id: d.id,
      type: 'line',
      stack: 'total',
      areaStyle: {},
      data: d.data,
      emphasis: { disabled: true },
      showAllSymbol: true,
      step: userProfile.isAllocationAmountSelected ? false : 'end',
      symbolSize: symbolSize,
      
    })),
  grid: {    
    left: '0',
    right: '1%',
    bottom: 60,
    top: '7%',
    containLabel: true,
  },
  tooltip: {    
    show: true,
    trigger: 'axis',   
    transitionDuration: 0, 
    // confine: false,
    formatter: (params) => {
      updateTooltipContent(params);
      return tooltipContent.value;
    },
  },
  color: chartColors,
}));

const allocationChart = ref(null);
let instance;

onMounted(() => {   
  instance = echarts.init(allocationChart.value);
  // instance.setOption(chartOptions.value);

  initializeAllocations();
  // Call the function to update the values
  updateSavingsAllocationValues();

  // Resize the chart when the window resizes
  window.addEventListener('resize', () => {
    instance.resize();   
    nextTick(() => {
      addDraggablePoints();
    updateTargetGraphics();
    }) 
  });
  
  // addDraggablePoints();

  // Listen for the dataZoom event
  instance.on('dataZoom', function () {
    // Check if the event type is 'end' to ensure it's finished
      // Call your function when data zooming is finished
      addDraggablePoints();
      updateTargetGraphics();
  });

  instance.on('dataZoom', function (params) {
    userProfile.dataZoom = {start : params.start, end : params.end};
});

});

watch(planStore.getCurrentPlan.targets, () => {
    updateTargetGraphics();
    instance.setOption(chartOptions.value)
});

// Watch for changes in projectionData and savingsAllocation
watch(
  () => [
    planStore.getCurrentPlan.projection,
    planStore.getCurrentPlan.savingsAllocation,
  ],
  () => {
    if (!isDragging.value && !dialogStore.isOpen) {
            
      // Clone the savingsAllocation to avoid direct mutation
      savingsAllocation.value = JSON.parse(JSON.stringify(planStore.getCurrentPlan.savingsAllocation));
      
      // Update allocation values and other properties
      updateSavingsAllocationValues();
      reversedAllocation = computed(() => savingsAllocation.value.slice().reverse());

      // Update chart options
      chartOptions.value.xAxis.data = userProfile.getSelectedXAxis === 'age' ? chartData.value.ages : chartData.value.years;

      instance.setOption(chartOptions.value, true);
  
      instance.setOption({dataZoom: [{type: 'slider', start: userProfile.dataZoom.start, end: userProfile.dataZoom.end}]});

      // Add draggable points and update target graphics
      addDraggablePoints(true);
      updateTargetGraphics();
    }
  },
  { deep: true }
);


//swith xAxis between year and age
const updateXAxis = (axis) => {     
  if(chartOptions, instance) {
    if (axis === 'year') {
      xAxisData = chartData.value.years;
      // console.log('years set')
    }
    else if (axis === 'age') {            
      xAxisData = chartData.value.ages;
    }
    chartOptions.value.xAxis.data = xAxisData;
    instance.setOption(chartOptions.value, true);  
  }
};

//swith yAxis between year and age
const updateYAxis = (isAllocationAmount) => { 
  if (instance, chartOptions) {        
    if (isAllocationAmount) {
      yAxisDetails = yAxisForAmount;
    }
    else {            
      yAxisDetails = yAxisForPercent;
    }
    updateSavingsAllocationValues();

    chartOptions.value.yAxis = yAxisDetails;
    instance.setOption(chartOptions.value, true);
    instance.setOption({dataZoom: [{type: 'slider', start: userProfile.dataZoom.start, end: userProfile.dataZoom.end}]});

  }
};

// Watch for changes in the selectedXAxis and run updateXAxis when it changes
watch(() => userProfile.getSelectedXAxis, (newValue) => {
  updateXAxis(newValue);
  addDraggablePoints(true);
  updateTargetGraphics();
});

// Watch for changes in the selectedXAxis and run updateChartOptions when it changes
watch(() => userProfile.isAllocationAmountSelected, (newValue) => {
  updateYAxis(newValue);
  addDraggablePoints(true);
  updateTargetGraphics();
});

const createGraphic = (data, seriesIndex, dataIndex, year, value, age, draggable) => {
  const reversedZIndex = (data.length - seriesIndex) * 10; // Calculate reversed z-index               
  const pos = instance.convertToPixel({ xAxisIndex: 0, yAxisIndex: 0 }, [userProfile.getSelectedXAxis === 'age' ? age : year, value]);

  return {
    type: 'circle',
    position: pos,
    shape: {
      r: symbolSize // symbol size
    },
    invisible: true,
    draggable: draggable,
    z: 50 + reversedZIndex, // Lower z-index for top rows
    id: `${seriesIndex}-${dataIndex}`, // Add an ID to each graphic element for updating      
    tooltip: {
      show: true,  
      formatter: () => {              
        // Collect data for all series at the current dataIndex                            
        const seriesData = cumulativeChartData.value.map((accountData, seriesIndex) => {
          const seriesName = savingsAllocation.value[seriesIndex].name;
          const percent = savingsAllocation.value[seriesIndex].allocation[dataIndex].percent; // Get the y-value
          const absolute = savingsAllocation.value[seriesIndex].allocation[dataIndex].absolute; // Get the y-value
          const color = chartOptions.value.color[seriesIndex]; // Get the color for the series

          return {
            seriesIndex,
            dataIndex,
            percent: [year, percent],
            absolute: [year, absolute],
            color,
            seriesName
          };
        });

        // Pass the collected series data to formatTooltipContent
        return formatTooltipContent(seriesData);
      },   
    },
    onclick: echarts.util.curry(onGraphicClick, seriesIndex, dataIndex),
    ondragstart: echarts.util.curry(onDragStart, seriesIndex, dataIndex),
    ondrag: echarts.util.curry(onPointDragging, seriesIndex, dataIndex),
    ondragend: echarts.util.curry(onDragEnd, seriesIndex, dataIndex, year, age),  
  };
};

const addDraggablePoints = (merge) => {  
  const graphicData = echarts.util.map(cumulativeChartData.value, (accountData, seriesIndex) => 
    echarts.util.map(accountData, ({ year, absolute, percent, age }, dataIndex) => {      
      if (userProfile.isAllocationAmountSelected) {
        return createGraphic(cumulativeChartData.value, seriesIndex, dataIndex, year, absolute, age, true);
      } else {
        const isTopRow = seriesIndex === cumulativeChartData.value.length - 1;
        if (isTopRow) return null;  // Skip adding this graphic if it's the top row
        return createGraphic(cumulativeChartData.value, seriesIndex, dataIndex, year, percent, age, !isTopRow);
      }
    })
  ).flat().filter(item => item !== null); // Flatten the array and filter out nulls

  const option = { graphic: graphicData };

  const setOptionParams = merge ? { replaceMerge: ['graphic'] } : {};
  instance.setOption(option, setOptionParams);
};
const key = computed(() => userProfile.isAllocationAmountSelected ? 'absolute' : 'percent');

function onDragStart() {
}

function onGraphicClick(seriesIndex, dataIndex) {
  dialogStore.openDialog('edit', 'savingsallocationpoint', {'seriesIndex' : seriesIndex, 'dataIndex' : dataIndex})
}

function onPointDragging(seriesIndex, dataIndex, params) {
  isDragging.value = true; 

  // console.log(savingsAllocation.value[seriesIndex])
  // Validate the dataIndex to ensure it is within the bounds of the data array
  if (dataIndex == null || dataIndex < 0 || dataIndex >= chartData.value.years.length) return;

  // Retrieve the year and age corresponding to the current dataIndex
  const year = chartData.value.years[dataIndex];
  const age = chartData.value.ages[dataIndex];

  // Get the x and y positions from the dragged point
  const xpos = params.target.x;
  const ypos = params.target.y;
  
  // Convert pixel positions to data coordinates
  const pos = instance.convertFromPixel('grid', [xpos, ypos]);
  let constrainedY = pos[1];
  
  // If the key is 'percent', constrain the y position between 0 and 100
  if (key.value === 'percent') {
    constrainedY = Math.min(100, Math.max(0, pos[1] ?? 0));
    constrainedY = Math.round(constrainedY * 10) / 10; // Round to one decimal place
  }
  
  // Convert data coordinates back to pixel positions for updating the graphic
  const pixelPosition = instance.convertToPixel( 'grid' , [year, constrainedY]);
  params.target.attr({ x: xpos, y: pixelPosition[1] }); // Lock x-axis movement and update y position

  // Calculate the cumulative value of all previous series for the same data index
  const cumulativePreviousValues = savingsAllocation.value.slice(0, seriesIndex).reduce(
    (sum, account) => sum + (account.allocation[dataIndex]?.value ?? 0),
    0
  );

  // Calculate the new value for the current series
  let newValue = constrainedY - cumulativePreviousValues;

  // If the key is 'percent', ensure the new value is constrained between 0 and 100
  if (key.value === 'percent') {
    newValue = Math.max(0, Math.min(100, newValue));
  }

  // Retrieve the allocation array for the current series
  const allocation = savingsAllocation.value[seriesIndex].allocation;
  
  // Get the existing allocation point for the specified year, or initialize it
  let allocationPoint = allocation[dataIndex];
  const oldValue = allocationPoint ? allocationPoint.value : 0;

  if (allocationPoint) {
    // Update the existing allocation point with the new value
    allocationPoint.value = newValue;
    allocationPoint[key.value] = newValue; // Update based on the key ('percent' or 'absolute')
  } else {
    // Create a new allocation point if it doesn't exist
    allocationPoint = { year: parseInt(year), value: newValue, age: age };
    allocation[dataIndex] = allocationPoint;
  }

  // Calculate the difference between the new and old values
  const diff = newValue - oldValue;

  // Determine the index of the adjacent series (next or previous)
  const adjacentIndex = seriesIndex < savingsAllocation.value.length - 1 ? seriesIndex + 1 : seriesIndex - 1;
  const adjacentAccount = savingsAllocation.value[adjacentIndex];
  let adjacentAllocationPoint = adjacentAccount.allocation[dataIndex];

  if (adjacentAllocationPoint) {
    // Adjust the value for the adjacent allocation point
    if (key.value === 'percent') {
      adjacentAllocationPoint.value = Math.max(0, Math.min(100, adjacentAllocationPoint.value - diff));
    } else {
      adjacentAllocationPoint.value -= diff;
    }
    adjacentAllocationPoint[key.value] = adjacentAllocationPoint.value;
  } else {
    // Create a new allocation point for the adjacent series if it doesn't exist
    let newValue = -diff;
    if (key.value === 'percent') {
      newValue = Math.max(0, Math.min(100, newValue));
    }
    
    adjacentAllocationPoint = { year: parseInt(year), value: newValue, age: age };
    adjacentAllocationPoint[key.value] = adjacentAllocationPoint.value;
    adjacentAccount.allocation[dataIndex] = adjacentAllocationPoint;
  }

  // If the key is 'percent', ensure that the total allocation sums to 100%
  if (key.value === 'percent') {
    const totalAllocation = savingsAllocation.value.reduce(
      (sum, account) => sum + (account.allocation[dataIndex]?.value ?? 0),
      0
    );
    
    // Adjust each allocation to maintain the total percentage
    savingsAllocation.value.forEach(account => {
      const allocationPoint = account.allocation[dataIndex];
      if (allocationPoint) {
        allocationPoint.value = Math.round((allocationPoint.value / totalAllocation) * 100);
        allocationPoint.percent = allocationPoint.value;
        allocationPoint.absolute = planStore.getCurrentPlan.projection.data.profit[dataIndex] * (allocationPoint.value / 100);
      }
    });
  } else {
    // If the key is 'absolute', adjust the percentage based on the absolute values
    const maxProfit = planStore.getCurrentPlan.projection.data.profit[dataIndex];
    savingsAllocation.value.forEach(account => {
      const allocationPoint = account.allocation[dataIndex];
      if (allocationPoint) {
        allocationPoint.absolute = allocationPoint.value;
        allocationPoint.percent = Math.round((allocationPoint.absolute / maxProfit) * 100);
      }
    });
  }

  // Update the chart with the new options
  instance.setOption(chartOptions.value, { replaceMerge: ['series'] });
}

//////////////
function onDragEnd (seriesIndex, dataIndex, year, age, params) {
  if (isDragging.value) {
    
    let xRaw;
   if (userProfile.getSelectedXAxis === 'age') {
      xRaw = age;
   }
   else {
    xRaw = year;
   }  

  const yValue = instance.convertFromPixel('grid', [xRaw, params.target.y])[1];
  const snappedY = Math.round(yValue); // Snap to whole numbers

  // Update the position of the draggable circle
  const pixelPosition = instance.convertToPixel('grid', [xRaw, snappedY]);
  
  params.target.attr({ x: pixelPosition[0] });
  params.target.attr({ y: pixelPosition[1] });

  if (key.value === 'percent') {
    // Ensure the total allocations add up to 100 for the given year
    const totalAllocation = savingsAllocation.value.reduce((sum, account) => {
        const allocationPoint = account.allocation.find(a => a.year === parseInt(year));
        return sum + (allocationPoint ? allocationPoint.percent : 0);
      }, 0);
      
      savingsAllocation.value.forEach(account => {
        const allocationPoint = account.allocation.find(a => a.year === parseInt(year));
        if (allocationPoint) {
          allocationPoint.value = Math.round((allocationPoint.percent / totalAllocation) * 100);
        }
      });

      // Calculate the total allocation after normalization
      let normalizedTotal = savingsAllocation.value.reduce((sum, account) => {
        const allocationPoint = account.allocation.find(a => a.year === parseInt(year));
        return sum + (allocationPoint ? allocationPoint.percent : 0);
      }, 0);

      if (normalizedTotal > 100) {
        const excess = normalizedTotal - 100;
        const topMostAccount = savingsAllocation.value[savingsAllocation.value.length - 1];
        const topMostAllocationPoint = topMostAccount.allocation.find(a => a.year === parseInt(year));
        if (topMostAllocationPoint) {
          topMostAllocationPoint.percent -= excess;
          topMostAllocationPoint.percent = Math.max(0, topMostAllocationPoint.percent); // Ensure it doesn't go below 0
        }
      }
  }
   
  // Call the debounced function
  debouncedUpdate();

  // addDraggablePoints(false);
  // updateTargetGraphics();

  // planStore.updateAllocations();
  
  }

  nextTick(() => {    
    isDragging.value = false;
  });
}

const debouncedUpdate = debounce(() => {
  planStore.getCurrentPlan.savingsAllocation = JSON.parse(JSON.stringify(savingsAllocation.value));
  planStore.updateProjectionData();

}, 100); // Adjust the delay as needed

const formatAbsoluteAllocation = (param) => {
  // Ensure the allocation is a number before formatting
  const number = Number(param);

  // Format the number with commas
  const formattedNumber = formatNumber(number)

  return `${formattedNumber}`;
};

</script>
