<template>
  <div>
    <div class="flex items-top gap-2">
        <div class="flex-grow">
          <div :class="{ 'text-red-900 ring-1 ring-inset ring-red-300 focus-within:ring-2 focus-within:ring-red-500': !dialogStore.isFieldValid('name')}" class="flex-grow rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
              <label  :class="{ 'text-red-900': !dialogStore.isFieldValid('name')}" class="block text-xs font-medium text-gray-500">Name</label>
              <input type="text" name="name" id="name-{{ index }}" v-model="dialogStore.selectedItem.name" class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 invalid:text-red-900 invalid:ring-1 invalid:ring-inset invalid:ring-red-300" @blur="validateField(dialogStore.selectedItem.name, 'name', dialogStore)" />
          </div>
          <div class="h-4 my-1"><p v-if="!dialogStore.isFieldValid('name')" class="text-xs text-red-600" id="email-error">Not a valid name</p></div>
        </div>
        <div class="flex-grow">
          <div :class="{ 'text-red-900 ring-1 ring-inset ring-red-300 focus-within:ring-2 focus-within:ring-red-500': !dialogStore.isFieldValid('value')}" class="relative flex-grow rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
              <label :class="{ 'text-red-900': !dialogStore.isFieldValid('value')}" class="block text-xs font-medium text-gray-500">Value</label>
              <div class="pointer-events-none absolute top-7 left-0 flex items-center pl-3">
                <span class="text-gray-500 sm:text-sm">{{dialogStore.selectedItem.currency}}</span>
              </div>
              <input type="number" step=".01" name="value" id="value-{{ index }}" v-model.number="dialogStore.selectedItem.value" class="block w-full border-0 p-0 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" @blur="validateField(dialogStore.selectedItem.value, 'value', dialogStore)"/>
          </div>
          <div class="h-4 my-1"><p v-if="!dialogStore.isFieldValid('value')" class="text-xs text-red-600" id="email-error">Not a valid value</p></div>
        </div>
    </div>  
    <div class="flex-col items-center gap-2 p-2 bg-gray-100 rounded-md">
        <PlanDropdownFrequency />
        <PlanDropdownDate />
    </div>
    <div class="mt-2">
      <div>
          <PlanDropdownGrowth />
      </div>

      <div>
        <div class="relative flex flex-col items-start mt-4 pl-1">
          <div class=" text-sm leading-6">
            <label for="definedBenefitPension" class="font-medium text-gray-900">Funding</label>
            <p id="definedBenefitPension-description" class="text-gray-500 tex-xs">Where should this expense come from?</p>
          </div>

          <Menu as="div" class="relative inline-block text-left">
            <Float  portal enter="transition duration-200 ease-out" enter-from="scale-95 opacity-0" enter-to="scale-100 opacity-100" leave="transition duration-150 ease-in" leave-from="scale-100 opacity-100" leave-to="scale-95 opacity-0" tailwindcss-origin-class>
              <MenuButton class="flex w-full justify-center gap-x-1.5 align-middle rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
               {{ selectedOption.value.label }}
                <ChevronDownIcon class=" h-5 w-5 text-gray-400" aria-hidden="true" />
              </MenuButton>

              <MenuItems class=" z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div class="py-1">
                  <MenuItem v-for="option in options.value" :key="option.value">
                    <a href="#" class='group flex items-center px-4 py-2 text-sm hover:bg-gray-100' @click="selectOption(option.value, option.label)" :class="[fundingActive(option.value) ? 'text-indigo-600' : 'text-gray-700']">
                           {{ option.label }}
                      <CheckIcon v-if="fundingActive(option.value)" class="ml-auto h-5 w-5 text-indigo-500 group-hover:text-indigo-500" aria-hidden="true"/>
                    </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </Float>
          </Menu>

        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { watch, onMounted, ref, computed } from 'vue';
import { useDialogStore } from '@/stores/DialogStore';
import { useExpensesStore } from '@/stores/ExpensesStore';
import { useSavingsStore } from '@/stores/SavingsStore';
import { usePlanStore } from '@/stores/PlanStore';
import { validateField } from '@/utils/validationUtils';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon, CheckIcon } from '@heroicons/vue/20/solid';
import PlanDropdownDate from '@/components/PlanDropdownDate.vue';
import PlanDropdownGrowth from '@/components/PlanDropdownGrowth.vue';
import PlanDropdownFrequency from '@/components/PlanDropdownFrequency.vue';
import { Float } from '@headlessui-float/vue';

const expensesStore = useExpensesStore();
const dialogStore = useDialogStore();
const savingsStore = useSavingsStore();
const planStore = usePlanStore();

// Register fields on component mount
onMounted(() => {
  registerFields();
});

// Define the initial options for the dropdown
const initialOptions = [
  { label: 'Disposable income', value: 'Disposable income' },
  { label: 'Account name', value: 'Account name' }
];

// Fetch accounts from the Pinia stores
const savingsAccounts = computed(() => savingsStore.items); // Assuming accounts is the array of savings accounts in SavingsStore
const planAccounts = computed(() => planStore.getCurrentPlan.savings); // Assuming savingsAccounts is part of getCurrentPlan

// Merge and prioritize accounts from planStore
const mergedAccounts = computed(() => {
  const accountMap = new Map();

  planAccounts.value.forEach(account => {
    accountMap.set(account.id, account);
  });

  savingsAccounts.value.forEach(account => {
    if (!accountMap.has(account.id)) {
      accountMap.set(account.id, account);
    }
  });

  return Array.from(accountMap.values());
});

// Define the options for the dropdown
const options = computed(() => {
  const accountOptions = mergedAccounts.value.map(account => ({
    label: account.name, // Adjust the property name if it's different
    value: account.id    // Adjust the property name if it's different
  }));

  return [...initialOptions, ...accountOptions];
});

const fundingActive = (menuItem) => {
  return dialogStore.selectedItem.fundingAccount === menuItem;
};

// Define the selected option
const selectedOption = ref(
  options.value.find(option => option.value === dialogStore.selectedItem.fundingAccount) || options.value[0]
);

// Watch for changes in options to update the selectedOption
watch(options, (newOptions) => {
  selectedOption.value = newOptions.find(option => option.value === dialogStore.selectedItem.fundingAccount) || newOptions[0];
});

// Function to select an option
const selectOption = (value, label) => {
  selectedOption.value = { value, label };
  dialogStore.selectedItem.fundingAccount = value;
};

const registerFields = () => {
  // Call an action or mutation to register fields
  dialogStore.registerFields(['name', 'value', 'startYear', 'endYear', 'growthRate']);
};

if (dialogStore.dialogMode === 'add') {
  // Assuming getSchema2 returns a reactive object
  const reactiveEmptyExpenseItem = expensesStore.getSchema2();
  // Convert the reactive object to a plain object
  const emptyExpenseItem = JSON.parse(JSON.stringify(reactiveEmptyExpenseItem));
  dialogStore.selectedItem = emptyExpenseItem;
}

if (dialogStore.dialogMode === 'edit') {
  // Watch for changes to the selected item ID
  watch(() => dialogStore.selectedItemId, (newId) => {    
    // Check if the item ID exists in the ExpenseStore
    const selectedItemInExpense = expensesStore.items.find(item => item.id === newId);

    // Check if the item ID exists in the individual plan's data
    const currentPlan = planStore.plans.find(plan => plan.id === planStore.currentPlanId);
    const selectedItemInPlan = currentPlan && currentPlan.expenses ? currentPlan.expenses.find(item => item.id === newId) : null;

    // Use the correct store to edit the item
    if (selectedItemInPlan) {
      dialogStore.selectedItem = { ...selectedItemInPlan };
    } else if (selectedItemInExpense) {
      dialogStore.selectedItem = { ...selectedItemInExpense };
    } 
  }, { immediate: true });
}
</script>
