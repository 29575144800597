<template>
    <div>
        <div class="flex items-center gap-2">
            <div class="flex items-center self-end align-middle">            
                <label for="growthRate" class="block text-sm font-medium leading-6 text-gray-900">Loan start year</label>
            </div>
        </div>
        <div class="flex flex-row items-center gap-2">
            <div class="flex items-center relative w-32">
                <input type="number" min="1998" max="2100" placeholder="Enter year" v-model="dialogStore.selectedItem.startYear" :class="{ 'text-red-900 ring-1 ring-inset ring-red-300 focus-within:ring-2 focus-within:ring-red-500': !dialogStore.isFieldValid('startYear'), 'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6': true }" @blur="validateField(dialogStore.selectedItem.startYear, 'startYear', dialogStore), updateLoanItem()" />
                <!-- <span>Put plan details here</span> -->
            </div>
            <span v-if="dialogStore.selectedItem.frequency !== 'once'" >→</span>
            <div class="text-sm leading-6 text-gray-500" v-tooltip="{content: readablePlanType + '. Calculated to be written off in ' + dialogStore.selectedItem.endYear + ' based on your start year.',  theme: 'floating-vue-theme'}">{{ dialogStore.selectedItem.endYear }}</div>
        </div>
    </div>

    <div class="mt-3">
        <div class="flex items-center gap-2">
            <div class="flex items-center self-end align-middle">            
                <label for="growthRate" class="block text-sm font-medium leading-6 text-gray-900">Interest Rate</label>
            </div>        
        </div>

        <div class="relative w-32">
            <input v-model="dialogStore.selectedItem.growthRate" type="number" min="-20" max="100" step=".01" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"> 
                <span class="text-gray-500 sm:text-sm">%</span>
            </div>
        </div>
    </div>
    

  </template>
    
  <script setup>
    import { computed } from 'vue';

  import { useDialogStore } from '@/stores/DialogStore';
  import { validateField } from '@/utils/validationUtils';
  import { useTaxStore } from '@/stores/TaxStore';
//   import { CalendarIcon } from '@heroicons/vue/20/solid';
//   import { Float } from '@headlessui-float/vue';
  
  const dialogStore = useDialogStore();
  const taxStore = useTaxStore();  
  
  const updateLoanItem = () => {
    dialogStore.selectedItem.endYear = taxStore.getStudentLoanEndYearFromStartYear(dialogStore.selectedItem.startYear);
    dialogStore.selectedItem.planType = taxStore.getStudentLoanPlanFromStartYear(dialogStore.selectedItem);
    dialogStore.selectedItem.growthRate = taxStore.studentLoanRepaymentPlans[dialogStore.selectedItem.planType].interestRate;
  }

  const readablePlanType = computed(() => {
  const planType = dialogStore.selectedItem.planType;

  if (typeof planType !== 'string') {
    return 'Unknown Plan';
  }

  const match = planType.match(/^plan(\d+)$/);
  if (match) {
    return `Plan ${match[1]}`;
  } else {
    return 'Unknown Plan';
  }
});
  </script>
  
  <style>
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0; 
    }
  </style>
    