<template>
    <div>
      <div class="flex items-top gap-2">
          <div class="flex-grow">
            <div :class="{ 'text-red-900 ring-1 ring-inset ring-red-300 focus-within:ring-2 focus-within:ring-red-500': !dialogStore.isFieldValid('name')}" class="flex-grow rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                <label  :class="{ 'text-red-900': !dialogStore.isFieldValid('name')}" class="block text-xs font-medium text-gray-500">Name</label>
                <input type="text" name="name" id="name-{{ index }}" v-model="dialogStore.selectedItem.name" class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 invalid:text-red-900 invalid:ring-1 invalid:ring-inset invalid:ring-red-300" @blur="validateField(dialogStore.selectedItem.name, 'name', dialogStore)" />
            </div>
            <div class="h-4 mt-1"><p v-if="!dialogStore.isFieldValid('name')" class="text-xs text-red-600" id="email-error">Not a valid name</p></div>
          </div>
          
          <div class="flex-grow">
            <div :class="{ 'text-red-900 ring-1 ring-inset ring-red-300 focus-within:ring-2 focus-within:ring-red-500': !dialogStore.isFieldValid('value')}" class="relative flex-grow rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                <label :class="{ 'text-red-900': !dialogStore.isFieldValid('value')}" class="block text-xs font-medium text-gray-500">Value</label>
                <div class="pointer-events-none absolute top-7 left-0 flex items-center pl-3">
                  <span class="text-gray-500 sm:text-sm">{{dialogStore.selectedItem.currency}}</span>
                </div>
                <input type="number" step=".01" name="value" id="value-{{ index }}" v-model.number="dialogStore.selectedItem.value" class="block w-full border-0 p-0 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" @blur="validateField(dialogStore.selectedItem.value, 'value', dialogStore)"/>
            </div>
            <div class="h-4 mt-1"><p v-if="!dialogStore.isFieldValid('value')" class="text-xs text-red-600" id="email-error">Not a valid value</p></div>
          </div>
      </div>  

      <div v-if="planStore.getCurrentPlan.country==='UK'" class="relative flex items-start pl-1  mb-4">
              <div class="flex h-6 items-center">
                <input id="student-loan" aria-describedby="student-loan-description" name="student-loan" type="checkbox" v-model="dialogStore.selectedItem.studentLoan" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
              </div>
              <div class="ml-3 text-sm leading-6">
                <label for="student-loan" class="font-medium text-gray-900">This is a student loan</label>
                <p id="student-loan-description" class="text-gray-500">A student loan isn't a normal debt, so we treat it differently.</p>
              </div>
      </div>
      <div v-if="dialogStore.selectedItem.studentLoan" class="mb-2">                
              <PlanEditDebtStudentLoan />
      </div>

      <div v-if="!dialogStore.selectedItem.studentLoan">
        <div class="flex-col items-center gap-2 p-2 bg-gray-100 rounded-md">
          <PlanDropdownDate />
        </div>
        <div class="mt-3">    
          <div><PlanDropdownGrowth /></div>
        </div>

        <div class="mt-3">
          <div class="flex items-center self-end align-middle">
              <PlusCircleIcon class="h-4 w-4 mr-1 text-slate-600 " aria-hidden="true"/>
              <label for="repayment" class="block text-sm font-medium leading-6 text-gray-900">Repayment Amount</label>
          </div>        
          <div>
            <div class="flex gap-2">
              <PlanDropdownContributionFrequency />
            </div>

          </div>
        </div>
      </div>
  
    </div>
  </template>
  
  <script setup>
  import { watch, onMounted } from 'vue';
  import { useDialogStore } from '@/stores/DialogStore';
  import { useDebtStore } from '@/stores/DebtStore';
  import { usePlanStore } from '@/stores/PlanStore';
  import { validateField } from '@/utils/validationUtils';
  import PlanDropdownDate from '@/components/PlanDropdownDate.vue'
  import PlanDropdownGrowth from '@/components/PlanDropdownGrowth.vue';
  import PlanDropdownContributionFrequency from '@/components/PlanDropdownContributionFrequency.vue';
  import PlanEditDebtStudentLoan from '@/components/PlanEditDebtStudentLoan.vue';

  import { PlusCircleIcon } from '@heroicons/vue/20/solid';
  
  const debtStore = useDebtStore();
  const dialogStore = useDialogStore();
  const planStore = usePlanStore();
  
  //register fields - not sure this is needed
  // Register fields on component mount
  onMounted(() => {
    registerFields();
  });
  
  const registerFields = () => {
    // Call an action or mutation to register fields
    // For example, if you have a registerFields action in your store:
    //dialogStore.registerFields(['name', 'value', 'startYear', 'endYear', 'growthRate', 'contributions']);
    dialogStore.registerFields(['name', 'value', 'startYear', 'endYear', 'growthRate', ]);
  }
  
  if (dialogStore.dialogMode === 'add') {
    // Assuming getSchema2 returns a reactive object
    const reactiveEmptyItem = debtStore.getSchema2();
    // Convert the reactive object to a plain object
    const emptyItem = JSON.parse(JSON.stringify(reactiveEmptyItem));
    dialogStore.selectedItem = emptyItem;
  }
  
  if (dialogStore.dialogMode === 'edit') {
    // Watch for changes to the selected item ID
    watch(() => dialogStore.selectedItemId, (newId) => {
      
      // Check if the item ID exists in the Store
      const selectedItemInStore = debtStore.items.find(item => item.id === newId);
  
      // Check if the item ID exists in the individual plan's data
      const currentPlan = planStore.plans.find(plan => plan.id === planStore.currentPlanId);
      const selectedItemInPlan = currentPlan && currentPlan.debts ? currentPlan.debts.find(item => item.id === newId) : null;
  
      // Use the correct store to edit the item
      if (selectedItemInPlan) {
        dialogStore.selectedItem = { ...selectedItemInPlan };
      }
      else if (selectedItemInStore) {
        dialogStore.selectedItem = { ...selectedItemInStore };
      } 
    }, { immediate: true });
  }
  
  </script>
