<template>
    <div>
      <TransitionRoot as="template" :show="sidebarOpen">
        <Dialog as="div" class="relative z-50 lg:hidden" @close="sidebarOpen = false">
          <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-900/80" />
          </TransitionChild>
  
          <div class="fixed inset-0 flex">
            <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
              <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
                <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                  <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                      <span class="sr-only">Close sidebar</span>
                      <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </TransitionChild>
                <!-- Sidebar component, swap this element with another sidebar if you like -->
                <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2">
                  <div class="flex h-16 shrink-0 items-center">
                    <img class="h-8 w-auto" src="/logo-white.svg" alt="Your Company" />
                  </div>
                  <nav class="flex flex-1 flex-col">
                    <ul role="list" class="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" class="-mx-2 space-y-1">
                          <li v-for="item in navigation" :key="item.name">
                            <a :href="item.href" :class="[item.current ? 'bg-violet-700 text-white' : 'text-violet-100 hover:text-white hover:bg-violet-700', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                              <component :is="item.icon" :class="[item.current ? 'text-white' : 'text-violet-100 group-hover:text-white', 'h-6 w-6 shrink-0']" aria-hidden="true" />
                              {{ item.name }}
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <div class="flex justify-between">
                          <div class="text-xs font-semibold leading-6 text-violet-300">Your plans</div>
                          <button type="button" @click="planStore.createPlan" class="inline-flex items-center gap-x-1.5 rounded px-2 py-1 bg-transparent text-xs font-semibold text-violet-200 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-violet-50 hover:text-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600">
                            <PlusCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />New plan
                          </button>                     
                        </div>
                        <ul role="list" class="-mx-2 mt-2 space-y-1">
                          <li v-if="plans.length === 0" class="text-gray-400 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">No plans available</li>
                          <li v-for="plan in plans" :key="plan.id">
                              <router-link :to="`/plan/${plan.id}`" class="group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold" :class="{ 'bg-violet-700 text-white': plan.current, 'text-violet-100 hover:text-white hover:bg-violet-700': !plan.current }"  active-class="bg-violet-700 text-white">
                              {{ plan.name }}
                            </router-link>                            
                          </li>
                        </ul>
                      </li>
                      <div>                      
                    </div> 
                    </ul>
                  </nav>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>
  
<!------- Static sidebar for desktop -------------->
      <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6">
          <div class="flex h-16 shrink-0 items-center">
            <img class="h-8 w-auto" src="/logo-white.svg" alt="Your Company" />
          </div>
          <nav class="flex flex-1 flex-col">
            <ul role="list" class="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" class="-mx-2 space-y-1">
                  <li v-for="item in navigation" :key="item.name">
                    <router-link :to="item.href" class="group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold" :class="{ 'bg-violet-700 text-white': item.current, 'text-violet-100 hover:text-white hover:bg-violet-700': !item.current }" active-class="bg-violet-700 text-white">
                    <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" :class="{ 'text-white': item.current, 'text-violet-100 group-hover:text-white': !item.current }" />
                    {{ item.name }}
                    </router-link>
                  </li>
                </ul>
              </li>
              <li>
                <div class="flex justify-between">
                          <div class="text-xs font-semibold leading-6 text-violet-300">Your plans</div>
                          <button type="button" @click="planStore.createPlan" class="inline-flex items-center gap-x-1.5 rounded px-2 py-1 bg-transparent text-xs font-semibold text-violet-200 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-violet-50 hover:text-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600">
                            <PlusCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />New plan
                          </button>                     
                  </div>
                <ul role="list" class="-mx-2 mt-2 space-y-1">
                  <li v-if="plans.length === 0" class="text-gray-400 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">No plans available</li>
                  <li v-for="plan in plans" :key="plan.id">
                    <router-link :to="`/plan/${plan.id}`" class="group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold" :class="{ 'bg-violet-700 text-white': plan.current, 'text-violet-200 hover:text-white hover:bg-violet-700': !plan.current }"  active-class="bg-violet-700 text-white">
                      {{ plan.name }}
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="-mx-6 mt-auto">
                <a href="#"  @click="dialogStore.openDialog('edit','profile')" class="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-violet-700 cursor-pointer">
                  <img class="h-8 w-8 rounded-full bg-violet-700" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
                  <span class="sr-only">Your profile</span>
                  <span aria-hidden="true">{{ userProfileStore.name }}</span>
                  

                </a>
              </li>
            </ul>
          </nav>          
        </div>
      </div>
  
      <!--mobile-->
      <div class="sticky top-0 z-40 flex items-center gap-x-6 bg-gray-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <button type="button" class="-m-2.5 p-2.5 text-violet-200 lg:hidden" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>
        <div class="flex-1 text-sm font-semibold leading-6 text-white">Dashboard</div>
        <a href="#" @click="dialogStore.openDialog('edit','profile')">
          <span class="sr-only">Your profile</span>
          <span aria-hidden="true">{{ userProfileStore.name }}</span>
          <!-- <img class="h-8 w-8 rounded-full bg-violet-700" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" /> -->
        </a>
      </div>
    </div>

    <ProfileDialog />
    <WelcomeDialog />
  </template>

<script setup>
import { ref, computed, watch } from 'vue';
import { usePlanStore } from '../stores/PlanStore.js';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { HomeIcon, Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline';
import { PlusCircleIcon } from '@heroicons/vue/24/solid';
import ProfileDialog from '@/components/ProfileDialog';
import WelcomeDialog from '@/components/WelcomeDialog'

import { useDialogStore } from '@/stores/DialogStore';
import { useUserProfileStore } from '@/stores/UserProfile';

import { useRouter } from 'vue-router';

const userProfileStore = useUserProfileStore();
const dialogStore = useDialogStore();
const sidebarOpen = ref(false);

const planStore = usePlanStore();
// Use a computed property to reactively update the plans in the sidebar
const plans = computed(() => planStore.plans);

const navigation = [
  { name: 'Home', href: '/', icon: HomeIcon },
  // Add other routes here as needed
];

// Watch for route changes to close the sidebar
const router = useRouter();

watch(router.currentRoute, () => {
  sidebarOpen.value = false;  // Close sidebar when the route changes
});



</script>
