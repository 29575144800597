<template>
    <div class="flex flex-wrap md:flex-row gap-2 card-outer px-4 mt-4">

        <div class="flex justify-between items-center py-1 text-left text-sm font-semibold text-gray-900">
                <div class="flex items-center w-24" >
                    <ScaleIcon class="h-6 w-6 mr-2 text-indigo-600"></ScaleIcon>
                    <span>Rates</span>
                </div>              
        </div>

        <!-- Flex container for rows -->
        <div v-for="item in rateDetails.group" :key="item.id" @click="toggleEditMode(item.name, 'click')" class="flex items-center bg-white hover:bg-gray-100 cursor-pointer gap-4 shadow ring-1 ring-black ring-opacity-5 rounded-lg w-auto transition-all duration-300 ease-in-out" ref="itemRefs">
            
            <!-- Flex item for name -->
            <div class="flex flex-grow pl-3 py-2 text-sm font-medium text-gray-900">
                {{ item.name }}
            </div>
            <div class="flex items-center"> 
            <!-- Flex item for value or input -->
            <div class="flex-1 py-1 text-sm text-gray-900 text-right w-10" :class="{ 'pr-3': !item.modifier }">
                <div v-if="!editState[item.name]" class="pr-[4px]">
                    <!-- Display mode -->
                    {{ rateDetails.currency ? $formatNumber(item.value) : item.value }}
                </div>
                <div class="relative " v-else>
                    <!-- Edit mode -->
                    <input v-model="item.value" data-name="item.name" :ref="setInputRef(item.name)" @blur="toggleEditMode(item.name)" @keyup.enter="$event.target.blur()" class="text-right text-sm p-0.5 pr-[3px] w-auto" type="number" :min="minValue" :max="maxValue">
                </div>
            </div>
            <!-- Flex item for modifier, if present -->
            <div v-if="item.modifier" class="w-auto py-2 text-gray-500 text-xs text-right pr-3 align-bottom">
                {{ item.modifier }}
            </div>
          </div>
        </div>
          
      
  </div>
</template>

<script setup>
import { nextTick, reactive, computed, onMounted, ref } from 'vue';
import { usePlanStore } from '@/stores/PlanStore';
import { ScaleIcon } from '@heroicons/vue/20/solid'

const planStore = usePlanStore();
const editState = reactive({});
const inputRefs = reactive({});

const itemWidths = ref({});
const itemRefs = ref([]);

//hold which item is being edited
function setInputRef(name) {
  return (el) => {
    if (el) {
      inputRefs[name] = el;
    }
  };
}

// Capture the initial width when the component is mounted
onMounted(() => {
  itemRefs.value.forEach((itemRef, index) => {
    if (itemRef) {
      itemWidths.value[rateDetails.value.group[index].name] = itemRef.offsetWidth;
    }
  });
});

function toggleEditMode(cellName, method) {
  // Check if the cell is already in edit mode
  if (editState[cellName] && method === "click" ) {
    // If the cell is already in edit mode, return early to ignore the click
    return;
  }

  // Toggle the edit mode
  editState[cellName] = !editState[cellName];  

  // Move focus to cell if edit mode is toggled on
  if (editState[cellName]) {
    nextTick(() => {
      if (inputRefs[cellName]) {
        inputRefs[cellName].focus();
      }
    });
  }

  else {
    planStore.updateProjectionData();
  }
}

const rateDetails  = computed(() => {    
    return { type: 'rates', group: planStore.getCurrentPlan.rates, currency: false, }; 
});

const maxValue = computed(() => { 
  return rateDetails.value.type === "target" ? 120 : 100;
});

const minValue = computed(() => {
  return rateDetails.value.type === "target" ? 25 : -25;
});

</script>

<style scoped>
.card-outer {
    /* transition: max-height 0.5s linear; */
    transition: height 0.2s ease;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;  
  transform: translateY(-30%);
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
</style>